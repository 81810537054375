import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const Container = styled.div`

    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #161C24;
        margin-top: 15px;
        margin-left: 8px;
    }

    .field{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 570;
        font-size: 14px;
        line-height: 125%;
        color: #000000;
        margin-top: 15px;
        margin-left: 20px;
    }
    
    .div-list{
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .name:{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
        }
    }

    .div-form{
        margin-left: 20px;
        margin-right: 20px;


        .button-add{
            background-color: #0c8662;
            border-radius: 8px;
            margin-top: 10px;
            margin-left: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 12px 5px 8px;
            width: auto;
            height: 44px;
            color: #FFFFFF;
        }



        input{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 100%;
        }

        select{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 100%;
        }

        


    }

    .div-form-button{
        margin-left: 20px;
        margin-right: 20px;
        justify-content: space-between;


        .button-add{
            background-color: #0c8662;
            border-radius: 8px;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 12px 5px 8px;
            width: 25%;
            height: 44px;
            color: #FFFFFF;
        }



        input{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 70%;
        }

        select{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 70%;
        }


    }

    

`;

export const Footer = styled.div`
    display: flex;
    flex: 1;
    width: auto;
    justify-content: space-between;
    align-items: center;

    .div-footer{
        display:flex;
        justify-content: space-around;
        align-items: start;
        flex: 1;
        width: auto;
        margin-left: 7px;
        margin-right: 7px;
    
        .button-cancel{
            background-color: #ec632c;
            border-radius: 8px;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 44px;
            color: #FFFFFF;
            padding: 5px 12px 5px 8px;
        }

        .button-confirm{
            background-color: #0c8662;
            border-radius: 8px;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 12px 5px 8px;
            width: 200px;
            height: 44px;
            color: #FFFFFF;
        }

        .input{
            marginTop: 2px;
            height: 40px;
            width: "20%";
            borderRadius: 8px;
            border: 1px solid #ACB4BA;
            outline: none;
            padding: 10px;
        }
}
`
export const useStyles = makeStyles({
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    divlabel:{
      marginBottom: "25px",
      marginTop: "25px",
      alignItems: "end",
      width: "60%",
      display: "flex",
      justifyContent: "flex-end",
    },
    block1: {
      display: "flex",
      justifyContent: "space-between",
    },
    block2: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "60%",
      marginTop: 30
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      marginTop: 30
    },
    tableRows: {
      display: "flex",
      height: 44,
      background: "#F2F5F8",
      marginTop: 10,
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: 8,
      cursor: "pointer"
    },
    boxStatus: {
      display: "flex",
      borderRadius: 500,
      background: "rgba(32, 139, 206, 0.4)",
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 6,
      paddingBottom: 6,
      justifyContent: "center",
      alignItems: "center"
    },
    tableLabelsHeaderFirst: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: 14,
      color: "#161C24"
    },
    tableLabelsHeader: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: 12,
      color: "#919EAB"
    },
    tableLabels: {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: 14,
      color: "#161C24",
      cursor: "pointer"
    },
    search: {
      border: "1px solid rgba(145, 158, 171, 0.32)",
      borderRadius: 8,
      height: 44,
      marginLeft: "5%",
      alignItems: "center",
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 14,
      width: "25%"
    },
    button: {
      backgroundColor: "#0c8662",
      display: "flex",
      flexDirection: "row",
      borderRadius: 8,
      width: 162,
      height: 44,
      paddingTop: 20,
      paddingBottom: 20,
      fontFamily: "Montserrat",
      color: "white",
      fontWeight: 700,
      fontSize: 14,
      textTransform: "none"
    },
    buttonWhite: {
      marginTop: "10px",
      width: "30%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: 20,
      paddingRight: 20,
      height: "35px",
      background: "#F2F5F8",
      borderRadius: "8px",
    },
    labelBlack: {
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "10px",
      alignItems: "center",
      marginLeft: 10,
      color: "#000000",
    },
    input: {
      marginTop: "2px",
      height: "40px",
      width: "20%",
      borderRadius: "8px",
      border: "1px solid #ACB4BA",
      outline: "none",
      padding: "10px"
    },
  });