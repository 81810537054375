import React from "react";
import { makeStyles } from '@material-ui/styles';


export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  tableLabels: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 14,
    color: "#737D86",
    cursor: "pointer"
  },
  search: {
    border: "1px solid rgba(145, 158, 171, 0.32)",
    borderRadius: 5,
    height: 36,
    alignItems: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 14,
  },
  input: {
    marginTop: "2px",
    height: "40px",
    width: "20%",
    borderRadius: "8px",
    border: "1px solid #ACB4BA",
    outline: "none",
    padding: "10px"
  },
  buttonWeight: {
    marginTop: "10px",
    width: "10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    height: "35px",
    background: "#0c8662",
    borderRadius: "8px",
  },
  labelweight: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    alignItems: "center",
    marginLeft: 10,
    color: "#FFFFFF",
  },
});
