import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const RecommendationForm = () => {
    const [UnitOfMeasures, setUnitOfMeasures] = useState([]);
    const [TenantOptions, setTenantOptions] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    
    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];
    
    const fields = [
        {
            label: "Descrição",
            name: "description",
            type: "input",
        },
        {
            label: "Empresa",
            name: "tenantID",
            type: "select",
            options: TenantOptions,
            visible: isVisible,
            required: false,
        },
        {
            label: "Tipo",
            name: "type",
            type: "input",
        },
        {
            label: "Unidade de medida",
            name: "unitOfMeasureID",
            type: "select",
            options: UnitOfMeasures
        },
        {
            label: "Ativo",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ]

    const loadData = async () => {
        Api.get('Tenant/isTenant').then((result) => {
            if (result.data.response) {
                setIsVisible(true)

                Api.get('Tenant/getAllItems').then((resultTenant) => {
                    if (resultTenant && resultTenant.data) {
                        const options = resultTenant.data.response.map(item => ({ value: item.tenantID, text: item.name }));
        
                        setTenantOptions(options);
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
            else{
                setIsVisible(false)
                var options = [{ value: "", text: "Selecione" }];
                setTenantOptions(options);            
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
        Api.get('UnitOfMeasure/getAllItems').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.unitOfMeasureID, text: item.description }));

                setUnitOfMeasures(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
       UnitOfMeasures.length > 0 ? <FormCrud formName={"recommendation"} title={"Tipo de Recomendação"} fields={fields} /> : <></>
    );
}

export default RecommendationForm;
