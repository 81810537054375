import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";

export default function ModalFormConfirmClone({ newModalConfirmClone, setShow, ...props }) {
  const [description, setDescription] = useState("");

  useEffect(() => {
  });

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Informe uma descrição para o template</label>
        </Container>
      </ModalHeader>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
          <input style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold', border: "1px solid #CDD5DF", height: "35px" }} onChange={(t) => { setDescription(t.target.value) }}></input>
          <button className="btn button-cancel" onClick={() => {
                  setShow(false)
              }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => { newModalConfirmClone(true, description) }} >Confirmar</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}