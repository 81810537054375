import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { useStyles } from './styles';
import { ModalFooter } from "react-bootstrap";
import { Box, InputBase, InputAdornment, Button } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import api, { exceptionNotificationAPI } from '../../services/api';
import TableLoading from '../TableLoading';
import Select from 'react-select';
import closeGray from '../../assets/icons/closeGray.png';

export default function ModalFormCheckIn({ newModal, setShow, _lstConsultAnalyseSamples, ...props }) {
  const [searchText, setSearchText] = useState("");
  const styles = useStyles();
  const [lstConsultAnalyseSamples, setLstConsultAnalyseSamples] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tenant, setTenant] = useState();
  const [tenants, setTenants] = useState();
  const [selectedTenant, setSelectedTenant] = useState();
  const [selectedFarmer, setSelectedFarmer] = useState();
  const [farmers, SetFarmers] = useState([]);
  const [farmer, setFarmer] = useState();
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    let cancel = false;
    setIsLoading(true)

    loadTenants((dataTenant) => {
      setTenants(dataTenant)
      setSelectedTenant(dataTenant[0].tenantID)
      setTenant(dataTenant.tenantID)
      setIsLoading(false)
    })


    if (_lstConsultAnalyseSamples && _lstConsultAnalyseSamples[0]) {
      setLstConsultAnalyseSamples(_lstConsultAnalyseSamples)
      setSearchText("")
    }

    return () => {
      cancel = true;
      setIsLoading(false)
    }
  }, [_lstConsultAnalyseSamples]);

  const ConsultAnalyseSampleApi = async () => {
    setIsLoading(true)
    setLstConsultAnalyseSamples([])
    api.get(`AnalyseSample/getConsultAnalyseSampleApi?SearchText=${searchText}
    &&id=${selectedTenant != undefined ? selectedTenant : ""}&&idAux=${selectedFarmer != undefined ? selectedFarmer : ""}`).then((result) => {
      if (result && result.data && result.data.response) {
        setLstConsultAnalyseSamples(result.data.response.data);
        setIsLoading(false)
      }
    }).catch((error) => {
      exceptionNotificationAPI(error);
      setIsLoading(false)

    });
  }

  function funcCheckList(checked) {
    // Define o estado de todos os outros checkboxes com base no estado do primeiro checkbox
    lstConsultAnalyseSamples.forEach(function (analyseSample) {
      var checkbox = document.getElementById("CheckboxBox" + analyseSample.analyseSampleID);
      checkbox.checked = checked;
      analyseSample.checked = checked;
    });
  }

  function funcCheck(id) {
    lstConsultAnalyseSamples.forEach(function (analyseSample) {
      if (analyseSample.analyseSampleID == id.analyseSampleID) {
        var checked = document.getElementById("CheckboxBox" + id.analyseSampleID).checked;
        id.checked = checked;
      }
    });
  }

  const loadTenants = async (callback) => {
    api.get(`Tenant/getAllItemsSupplier`).then((result) => {
      if (result && result.data) {
        const options = result.data.response;

        callback(options);
      }
    }).catch((error) => {
      exceptionNotificationAPI(error);
    });
  }

  const searchFarmers = async (event) => {
    if (event.ctrlKey || event.altKey || event.shiftKey) {
      return false;
    }
    else if (event.key == 'ArrowDown' || event.key == 'ArrowUp') {
      return false;
    }
    else {
      var text = '';
      if (event.key == 'Backspace') {
        text = event.nativeEvent.target.defaultValue.slice(0, -1)
      }
      else {
        var text = event.nativeEvent.target.defaultValue + event.key;
      }

      api.get(`Farmer/GetAllFarmerCultureRegionItems?SearchText=` + text).then((result) => {
        if (result && result.data && result.data.response.length > 0) {
          SetFarmers(result.data.response.map(item => ({ value: item.farmerCultureRegionID, text: `${item.farmer.externalCode + " - "} ${item.farmer.name + " -  "} ${(item.branch != undefined ? item.branch.description : "")}` })));
        }
      }).catch((error) => {
        exceptionNotificationAPI(error);
      });
    }
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll); // Alterna o estado de seleção de todos os checkboxes
  };

  const handleCheckboxClick = (e) => {
    // Se o checkbox "Selecionar Todos" estiver marcado, atualize o estado de todos os checkboxes
    if (selectAll) {
      // Aqui você deve implementar a lógica para alterar o estado de todos os checkboxes
    }
  };

  const onClear = () => {
    SetFarmers([]);
    setFarmer([]);
    setSelectedFarmer([]);
  };

  return (
    <Modal
      {...props}
      scrollable={true}
      size="xl"
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Entradas Pendentes</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <Box className={styles.block1} style={{ marginTop: "20px", marginLeft: "5%", justifyContent: "flex-start" }}>
            <label className={styles.tableLabelsHeaderFirst} style={{ width: "23%", marginRight: "5.5%" }}>Código/Amostra</label>
            <label className={styles.tableLabelsHeaderFirst} style={{ width: "25%", marginRight: "3.5%" }}>Empresa</label>
            <label className={styles.tableLabelsHeaderFirst} style={{ width: "35%" }}>Produtor</label>
          </Box>
          <Box className={styles.block1}>
            <InputBase className={styles.search} value={searchText} placeholder={"Buscar..."} style={{ background: "white" }} onChange={(e) => {
              setSearchText(e.target.value)
            }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon htmlColor='#5A646E' />
                </InputAdornment>
              }
            />
            <select name='tenants' value={tenant} className={styles.search} onChange={(e) => { setSelectedTenant(e.target.value) }}>
              {
                tenants ?
                  tenants.map(tenant => {
                    return <option key={tenant.tenantID} value={tenant.tenantID}>{tenant.name}</option>
                  })
                  :
                  <></>
              }
            </select>
            <Box style={{ width: "25%", marginLeft: "5%" }}>
              <Select value={{ label: farmer, value: selectedFarmer }}
                options={farmers.map((o) => {
                  var data = {
                    value: o.value,
                    label: o.text
                  }
                  return data;
                })}
                onKeyDown={(e) => { searchFarmers(e) }}
                name='farmers' onChange={(e) => { setFarmer(e.label); setSelectedFarmer(e.value) }} className={styles.fieldResponsible}>
              </Select>
            </Box>
            <Box style={{ marginTop: "1px", marginRight: "2%" }}>
              <button className='btn' onClick={onClear}>
                <img src={closeGray} className='icon' />
              </button>
            </Box>
            <Button className={styles.button} style={{ marginLeft: "auto" }}
              onClick={() => {
                ConsultAnalyseSampleApi()
              }}>
              Buscar
            </Button>
          </Box>
          <Box>
            <Box className={styles.block} style={{ marginTop: "20px", justifyContent: "flex-start" }}>
              <input
                type="checkbox"
                className={styles.tableLabels}
                style={{ width: "5%", color: "#004F9F" }}
                onClick={(e) => funcCheckList(e.target.checked)}
              />
              <label className={styles.tableLabelsHeader} style={{ width: "25%" }}>Código</label>
              <label className={styles.tableLabelsHeader} style={{ width: "10%" }}>Amostra</label>
              <label className={styles.tableLabelsHeader} style={{ width: "20%" }}>Empresa</label>
              <label className={styles.tableLabelsHeader} style={{ width: "35%" }}>produtor</label>
            </Box>
            {isLoading && <TableLoading />}
            {
              lstConsultAnalyseSamples ? lstConsultAnalyseSamples.map(e => {
                return (
                  <Box key={e.analyseSampleID} className={styles.tableRows} style={{ marginTop: "1%" }}>
                    <input id={"CheckboxBox" + e.analyseSampleID} type="checkbox" className={styles.tableLabels} style={{ width: "5%", color: "#004F9F" }} onClick={() => funcCheck(e)}></input>
                    <label className={styles.tableLabels} style={{ width: "25%" }}>{e.externalCode}</label>
                    <label className={styles.tableLabels} style={{ width: "10%" }}>{e.sampleNumber}</label>
                    <label className={styles.tableLabels} style={{ width: "20%" }}>{e.tenant.name}</label>
                    <label className={styles.tableLabels} style={{ width: "35%" }}>{e.farmerCultureRegion.farmer.name}</label>
                  </Box>
                )
              })
                :
                <></>
            }
          </Box>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => {
              newModal(lstConsultAnalyseSamples)
              setShow(false)
            }} >Adicionar Selecionadas</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}