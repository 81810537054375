export const INITIAL_STATE = {
    data: {
        analyseSampleParameterTemplates: {},
        lstAnalyseSampleResultInterpretation: [],
        analyseSampleReturn: {},
        agronomistName: '',
        observationList: [],
        supplierEssaysList: []
    }
};

const reportRecomendationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_RECOMENDATION_REPORT_DATA':
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export default reportRecomendationReducer;



