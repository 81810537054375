import styled from 'styled-components';

export const Container = styled.button`
  background: #ec632c;
  border: 0;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  transition: background-color 0.2s;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 0.5rem!important;
  margin-left: auto!important;

  &:hover {
    background: #96969c;
  }

  &:focus{
    background: #96969c;
  }

  &:active{
    background: #96969c;
  }
`;
