import React, { useEffect, useState, forwardRef, useRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import TableLoading from '../TableLoading';
import { useLocation, useHistory } from 'react-router-dom';
import jsPDF from 'jspdf';
import ArrowPrint from '../../assets/icons/arrowPrint.png';
import levelEveryHight from '../../assets/images/1levelEveryHight.jpg';
import levelHigh from '../../assets/images/2levelHigh.jpg';
import levelNormal from '../../assets/images/3levelNormal.jpg';
import levelLow from '../../assets/images/4levelLow.jpg';
import levelEveryLow from '../../assets/images/5levelEveryLow.jpg';
import data_results_logo from '../../assets/images/data_results_logo.png';

import { Card } from './styles';
import { EightKPlusRounded } from '@mui/icons-material';

const AnalyseSampleViewGenerate = () => {
	const history = useHistory();
	const styles = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const [analyseSampleReturn, setAnalyseSampleReturn] = useState([]);
	const [list, setList] = useState([]);
	const [listInterpretation, setListInterpretation] = useState([]);
	const [listRecommendation, setListRecommendation] = useState([]);
	const doc = new jsPDF();
	const [fontSizeSampleDescription, setFontSizeSampleDescription] = useState(70);
	const [fontSizeSummaryLength, setFontSizeSummaryLength] = useState(40);
	const [fontSizeParameterDescription, setFontSizeParameterDescription] = useState(20);
	const [fontSizeRecommendedParameterTemplateItem, setFontSizeRecommendedParameterTemplateItem] = useState(15);
	const [fontSizeLevelRecommended, setFontSizeLevelRecommended] = useState(30);


	useEffect(() => {
		let cancel = false;
		setIsLoading(true)
		getAnalyseSample((options) => {
			if (cancel) return
			if (options) {
				setAnalyseSampleReturn(options)
				setList(options.listItemAnalyseSampleResultDto)
				setListInterpretation(options.listItemAnalyseSampleResultInterpretationDto)
				setListRecommendation(options.listItemAnalyseSampleResultRecommendationDto)
				setIsLoading(false)
			}
			getFontSize();
		});
		return () => {
			cancel = true;
			setIsLoading(false)
		}
	}, []);



	const getAnalyseSample = async (callback) => {
		api.get(`AnalyseSample/GetById?id=${(location.state.analyseSampleID)}&&idAux=${(location.state.cultureID)}&&idAux1=${(location.state.agronomistID ? location.state.agronomistID : null)}&&type=true`).then((result) => {
			if (result && result.data) {
				const options = result.data.response;

				callback(options);

			}
		}).catch((error) => {
			exceptionNotificationAPI(error);
		});
	}

	const getFontSize = async () => {
		if (window.screen.width <= 1600 && window.screen.width >= 1153) {
			setFontSizeSampleDescription(50)
			setFontSizeSummaryLength(30)
			setFontSizeParameterDescription(15)
			setFontSizeRecommendedParameterTemplateItem(13)
			setFontSizeLevelRecommended(25)
		}
		else if (window.screen.width <= 1152 && window.screen.width >= 1025) {
			setFontSizeSampleDescription(40)
			setFontSizeSummaryLength(25)
			setFontSizeParameterDescription(12)
			setFontSizeRecommendedParameterTemplateItem(10)
			setFontSizeLevelRecommended(21)
		}
		else if (window.screen.width <= 1024) {
			setFontSizeSampleDescription(40)
			setFontSizeSummaryLength(25)
			setFontSizeParameterDescription(12)
			setFontSizeRecommendedParameterTemplateItem(10)
			setFontSizeLevelRecommended(19)
		}
	}

	if (isLoading) {
		return (
			<TableLoading />
		)
	}
	return (
		<>
			<Box style={{ display: "flex", width: "98%", flexDirection: "column", marginRight: "2%" }}>
				<Box style={{ display: "flex", width: "100%", marginRight: 10 }} >
					<label style={{ width: "70%", fontFamily: "helvetica", marginLeft: "1%", fontWeight: 700, fontSize: fontSizeSampleDescription, color: "#161C24" }} >{"Recomendações"}</label>
					<label style={{ textAlign: "right", width: "30%", fontFamily: "helvetica", fontWeight: 700, fontSize: fontSizeSampleDescription, color: "#161C24" }} >{analyseSampleReturn ? analyseSampleReturn.sampleNumber : ""}/{analyseSampleReturn ? analyseSampleReturn.year : ""}</label>
				</Box>
				<Box style={{ display: "flex", width: "100%", marginTop: -20 }}>
					<label style={{ width: "70%", fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 37, color: "#161C24" }} >{"de produtividade do "} {analyseSampleReturn && analyseSampleReturn.analyseType ? analyseSampleReturn.analyseType.description : ""}</label>
					<label style={{ textAlign: "right", width: "30%", fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 37, color: "#161C24" }} >{"Amostra"}</label>
				</Box>
				<Box style={{ display: "flex", width: "100%" }}>
					<Box style={{ display: "flex", width: "70%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 37, color: "#161C24" }} >{"Dados Gerais"}</label>
					</Box>
					<label style={{ textAlign: "right", width: "30%", fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 37, color: "#161C24" }} >{"Cultura: "} {analyseSampleReturn ? analyseSampleReturn.nameCulture : ""}</label>
				</Box>
				<Box style={{ display: "flex", width: "100%" }} >
					<div className={styles.border} />
				</Box>
				<Box style={{ display: "flex", width: "100%", marginTop: "1%" }}>
					<Box style={{ display: "flex", width: "60%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 30, color: "#161C24" }} >{analyseSampleReturn && analyseSampleReturn.farmerCultureRegion ? analyseSampleReturn.farmerCultureRegion.farmer.name : ""}</label>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 30, color: "#161C24" }} >{analyseSampleReturn && analyseSampleReturn.farmerCultureRegion ? analyseSampleReturn.farmerCultureRegion.farmer.externalCode : ""}</label>
					</Box>
					<Box style={{ display: "flex", width: "30%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 30, color: "#161C24" }} >{analyseSampleReturn && analyseSampleReturn.farmerCultureRegion ? analyseSampleReturn.farmerCultureRegion.farmer.externalCode : ""}</label>
					</Box>
					<Box style={{ display: "flex", width: "20%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 30, color: "#161C24" }} >{analyseSampleReturn ? analyseSampleReturn.formattedDateEmission : ""}</label>
					</Box>
				</Box>
				<Box style={{ display: "flex", width: "100%" }}>
					<Box style={{ display: "flex", width: "60%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 25, color: "#161C24" }} >{"Produtor"}</label>
					</Box>
					<Box style={{ display: "flex", width: "30%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 25, color: "#161C24" }} >{"Código"}</label>
					</Box>
					<Box style={{ display: "flex", width: "20%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 25, color: "#161C24" }} >{"Data Expedição"}</label>
					</Box>
				</Box>
				<Box style={{ display: "flex", width: "100%", marginTop: "1%" }}>
					<Box style={{ display: "flex", width: "60%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 25, color: "#161C24" }} >{analyseSampleReturn && analyseSampleReturn.farmerCultureRegion ? analyseSampleReturn.farmerCultureRegion.branch.description : ""}</label>
					</Box>
					{
						analyseSampleReturn && analyseSampleReturn.instructor ?
							<Box style={{ display: "flex", width: "50%" }}>
								<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 25, color: "#161C24" }} >{analyseSampleReturn && analyseSampleReturn.instructor ? analyseSampleReturn.instructor.name : ""}</label>
							</Box>
							:
							<></>
					}
				</Box>
				<Box style={{ display: "flex", width: "100%" }}>
					<Box style={{ display: "flex", width: "60%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 25, color: "#161C24" }} >{"filial"}</label>
					</Box>
					{
						analyseSampleReturn && analyseSampleReturn.instructor ?
							<Box style={{ display: "flex", width: "50%" }}>
								<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 25, color: "#161C24" }} >{"instrutor"}</label>
							</Box>
							:
							<></>
					}

				</Box>
				<Box style={{ marginTop: "2%", display: "flex", width: "100%" }}>
					<Box style={{ display: "flex", width: "70%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 37, color: "#161C24" }} >{"Interpretação"}</label>
					</Box>
				</Box>
				<Box style={{ display: "flex", width: "100%" }} >
					<div className={styles.border} />
				</Box>
				{
					listInterpretation.map(e => {
						return (
							<Box style={{ marginTop: "2%", display: "flex", width: "100%", marginLeft: 15 }}>
								{
									e.item1 ?
										<Card style={{ "backgroundColor": "#FFFFFF", width: "24%", height: "220px", display: "flex" }}>
											<div style={{ display: "flex", width: "100%", marginLeft: "1%" }}>
												<div style={{ display: "column", width: "60%", marginLeft: "3%" }}>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", height: "25%" }}>
														<label style={{ fontFamily: "helvetica", fontWeight: 600, fontSize: e.item1.parameter.summary.length >= 5 && window.screen.width >= 1536 ? 30 : fontSizeSummaryLength, color: "#848994" }} >{e.item1.parameter.summary}</label>
													</div>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", marginTop: "-4%", height: "10%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeParameterDescription, color: "#000000" }} >{e.item1.parameter.description}</label>
													</div>
													<div style={{ display: "flex", width: "100%", height: "30%", marginTop: "4%%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 50, color: "#000000", fontWeight: 700 }} >{e.item1.value.toFixed(2)} </label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", marginTop: "2%", height: "15%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeRecommendedParameterTemplateItem, color: "#000000" }}>{"Recomendado "}{e.item1.parameterTemplateItem.recommendedParameterTemplateItem.minValue} - {e.item1.parameterTemplateItem.recommendedParameterTemplateItem.maxValue}</label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", height: "20%" }}>
														<label style={{ fontFamily: "helvetica", width: "100%", fontSize: fontSizeLevelRecommended, color: e.item1.parameterTemplateItem.level.recommended ? "#86c7ed" : "#ff0022", fontWeight: e.item1.parameterTemplateItem.level.recommended ? 500 : 700 }} >{e.item1.parameterTemplateItem.level.description} </label>
													</div>
												</div>
												<div style={{ display: "flex", width: "30%", marginTop: "1%", marginRight: "3%", marginBottom: "1%" }}>
													{
														e.item1.parameterTemplateItem.level.type == 5 ?
															<img src={levelEveryHight} />
															:
															<></>
													}
													{
														e.item1.parameterTemplateItem.level.type == 4 ?
															<img src={levelHigh} />
															:
															<></>
													}
													{
														e.item1.parameterTemplateItem.level.type == 3 ?
															<img src={levelNormal} />
															:
															<></>
													}
													{
														e.item1.parameterTemplateItem.level.type == 2 ?
															<img src={levelLow} />
															:
															<></>
													}
													{
														e.item1.parameterTemplateItem.level.type == 1 ?
															<img src={levelEveryLow} />
															:
															<></>
													}
												</div>
											</div>

										</Card>
										:
										<div style={{ width: "25%", height: "220px", display: "flex" }}></div>
								}
								{
									e.item2 ?
										<Card style={{ "backgroundColor": "#FFFFFF", width: "24%", height: "220px", display: "flex", marginLeft: "1%" }}>
											<div style={{ display: "flex", width: "100%", marginLeft: "1%" }}>
												<div style={{ display: "column", width: "60%", marginLeft: "3%" }}>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", height: "25%" }}>
														<label style={{ fontFamily: "helvetica", fontWeight: 600, fontSize: e.item2.parameter.summary.length >= 5 && window.screen.width >= 1536 ? 30 : fontSizeSummaryLength, color: "#848994" }} >{e.item2.parameter.summary}</label>
													</div>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", marginTop: "-4%", height: "10%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeParameterDescription, color: "#000000" }} >{e.item2.parameter.description}</label>
													</div>
													<div style={{ display: "flex", width: "100%", height: "30%", marginTop: "4%%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 50, color: "#000000", fontWeight: 700 }} >{e.item2.value.toFixed(2)} </label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", marginTop: "2%", height: "15%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeRecommendedParameterTemplateItem, color: "#000000" }}>{"Recomendado "}{e.item2.parameterTemplateItem.recommendedParameterTemplateItem.minValue} - {e.item2.parameterTemplateItem.recommendedParameterTemplateItem.maxValue}</label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", height: "20%" }}>
														<label style={{ fontFamily: "helvetica", width: "100%", fontSize: fontSizeLevelRecommended, color: e.item2.parameterTemplateItem.level.recommended ? "#86c7ed" : "#ff0022", fontWeight: e.item2.parameterTemplateItem.level.recommended ? 500 : 700 }} >{e.item2.parameterTemplateItem.level.description} </label>
													</div>
												</div>
												<div style={{ display: "flex", width: "30%", marginTop: "1%", marginRight: "3%", marginBottom: "1%" }}>
													{
														e.item2.parameterTemplateItem.level.type == 5 ?
															<img src={levelEveryHight} />
															:
															<></>
													}
													{
														e.item2.parameterTemplateItem.level.type == 4 ?
															<img src={levelHigh} />
															:
															<></>
													}
													{
														e.item2.parameterTemplateItem.level.type == 3 ?
															<img src={levelNormal} />
															:
															<></>
													}
													{
														e.item2.parameterTemplateItem.level.type == 2 ?
															<img src={levelLow} />
															:
															<></>
													}
													{
														e.item2.parameterTemplateItem.level.type == 1 ?
															<img src={levelEveryLow} />
															:
															<></>
													}
												</div>
											</div>

										</Card>
										:
										<div style={{ width: "25%", height: "220px", display: "flex" }}></div>
								}
								{
									e.item3 ?
										<Card style={{ "backgroundColor": "#FFFFFF", width: "24%", height: "220px", display: "flex", marginLeft: "1%" }}>
											<div style={{ display: "flex", width: "100%", marginLeft: "1%" }}>
												<div style={{ display: "column", width: "60%", marginLeft: "3%" }}>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", height: "25%" }}>
														<label style={{ fontFamily: "helvetica", fontWeight: 600, fontSize: e.item3.parameter.summary.length >= 5 && window.screen.width >= 1536 ? 30 : fontSizeSummaryLength, color: "#848994" }} >{e.item3.parameter.summary}</label>
													</div>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", marginTop: "-4%", height: "10%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeParameterDescription, color: "#000000" }} >{e.item3.parameter.description}</label>
													</div>
													<div style={{ display: "flex", width: "100%", height: "30%", marginTop: "4%%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 50, color: "#000000", fontWeight: 700 }} >{e.item3.value.toFixed(2)} </label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", marginTop: "2%", height: "15%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeRecommendedParameterTemplateItem, color: "#000000" }}>{"Recomendado "}{e.item3.parameterTemplateItem.recommendedParameterTemplateItem.minValue} - {e.item3.parameterTemplateItem.recommendedParameterTemplateItem.maxValue}</label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", height: "20%" }}>
														<label style={{ fontFamily: "helvetica", width: "100%", fontSize: fontSizeLevelRecommended, color: e.item3.parameterTemplateItem.level.recommended ? "#86c7ed" : "#ff0022", fontWeight: e.item3.parameterTemplateItem.level.recommended ? 500 : 700 }} >{e.item3.parameterTemplateItem.level.description} </label>
													</div>
												</div>
												<div style={{ display: "flex", width: "30%", marginTop: "1%", marginRight: "3%", marginBottom: "1%" }}>
													{
														e.item3.parameterTemplateItem.level.type == 5 ?
															<img src={levelEveryHight} />
															:
															<></>
													}
													{
														e.item3.parameterTemplateItem.level.type == 4 ?
															<img src={levelHigh} />
															:
															<></>
													}
													{
														e.item3.parameterTemplateItem.level.type == 3 ?
															<img src={levelNormal} />
															:
															<></>
													}
													{
														e.item3.parameterTemplateItem.level.type == 2 ?
															<img src={levelLow} />
															:
															<></>
													}
													{
														e.item3.parameterTemplateItem.level.type == 1 ?
															<img src={levelEveryLow} />
															:
															<></>
													}
												</div>
											</div>

										</Card>
										:
										<div style={{ width: "25%", height: "220px", display: "flex" }}></div>
								}
								{
									e.item4 ?
										<Card style={{ "backgroundColor": "#FFFFFF", width: "24%", height: "220px", display: "flex", marginLeft: "1%" }}>
											<div style={{ display: "flex", width: "100%", marginLeft: "1%" }}>
												<div style={{ display: "column", width: "60%", marginLeft: "3%" }}>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", height: "25%" }}>
														<label style={{ fontFamily: "helvetica", fontWeight: 600, fontSize: e.item4.parameter.summary.length >= 5 && window.screen.width >= 1536 ? 30 : fontSizeSummaryLength, color: "#848994" }} >{e.item4.parameter.summary}</label>
													</div>
													<div style={{ display: "flex", width: "100%", marginLeft: "2%", marginTop: "-4%", height: "10%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeParameterDescription, color: "#000000" }} >{e.item4.parameter.description}</label>
													</div>
													<div style={{ display: "flex", width: "100%", height: "30%", marginTop: "4%%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 50, color: "#000000", fontWeight: 700 }} >{e.item4.value.toFixed(2)} </label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", marginTop: "2%", height: "15%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: fontSizeRecommendedParameterTemplateItem, color: "#000000" }}>{"Recomendado "}{e.item4.parameterTemplateItem.recommendedParameterTemplateItem.minValue} - {e.item4.parameterTemplateItem.recommendedParameterTemplateItem.maxValue}</label>
													</div>
													<div style={{ display: "flex", width: "200px", marginLeft: "2%", height: "20%" }}>
														<label style={{ fontFamily: "helvetica", width: "100%", fontSize: fontSizeLevelRecommended, color: e.item4.parameterTemplateItem.level.recommended ? "#86c7ed" : "#ff0022", fontWeight: e.item4.parameterTemplateItem.level.recommended ? 500 : 700 }} >{e.item4.parameterTemplateItem.level.description} </label>
													</div>
												</div>
												<div style={{ display: "flex", width: "30%", marginTop: "1%", marginRight: "3%", marginBottom: "1%" }}>
													{
														e.item4.parameterTemplateItem.level.type == 5 ?
															<img src={levelEveryHight} />
															:
															<></>
													}
													{
														e.item4.parameterTemplateItem.level.type == 4 ?
															<img src={levelHigh} />
															:
															<></>
													}
													{
														e.item4.parameterTemplateItem.level.type == 3 ?
															<img src={levelNormal} />
															:
															<></>
													}
													{
														e.item4.parameterTemplateItem.level.type == 2 ?
															<img src={levelLow} />
															:
															<></>
													}
													{
														e.item4.parameterTemplateItem.level.type == 1 ?
															<img src={levelEveryLow} />
															:
															<></>
													}
												</div>
											</div>

										</Card>
										:
										<div style={{ width: "25%", height: "220px", display: "flex" }}></div>
								}
							</Box>

						)
					})
				}
				<Box style={{ marginTop: "2%", display: "flex", width: "100%" }}>
					<Box style={{ display: "flex", width: "70%" }}>
						<label style={{ fontFamily: "helvetica", marginLeft: 20, fontWeight: 500, fontSize: 37, color: "#161C24" }} >{"Recomendação"}</label>
					</Box>
				</Box>
				<Box style={{ display: "flex", width: "100%" }} >
					<div className={styles.border} />
				</Box>
				{
					listRecommendation.map(e => {
						return (
							<Box style={{ display: "column", width: "100%", marginLeft: 15 }}>
								{
									e.item1 ?
										<Box style={{ display: "column", width: "100%", marginLeft: 20, marginRight: 20, marginTop: "2%" }}>
											<Box style={{ display: "flex", width: "100%" }}>
												<label style={{ fontFamily: "helvetica", fontWeight: 500, fontSize: 22, color: "#161C24" }} >{"Recomendação de "}  {e.item1.parameter.description}</label>
											</Box>
											<Card style={{ "backgroundColor": "#f0f1f2", width: "98%", display: "flex", border: "0px", marginRight: "%" }}>
												<Box style={{ display: "column", width: "100%", marginTop: "1%" }}>
													<Box style={{ display: "flex", width: "100%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "30%", marginLeft: "1%" }} >{"Insumo"}</label>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "20%" }} >{"Quantidade"}</label>
													</Box>
													<Box style={{ display: "column", width: "100%", marginRight: 20 }}>
														{
															e.item1.parameterTemplateItem.listParameterTemplateItemInputDto.map(x => {
																return (
																	<Box style={{ display: "flex", width: "100%" }}>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25, marginLeft: "1%" }}>{x.input.description} </label>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25 }}>{x.amount} {x.input.unitOfMeasure.description} {x.input.type}</label>
																	</Box>

																)
															})
														}
													</Box>
												</Box>

											</Card>
										</Box>
										:
										<></>
								}
								{
									e.item2 ?
										<Box style={{ display: "column", width: "100%", marginLeft: 20, marginRight: 20, marginTop: "1%" }}>
											<Box style={{ display: "flex", width: "100%" }}>
												<label style={{ fontFamily: "helvetica", fontWeight: 500, fontSize: 22, color: "#161C24" }} >{"Recomendação de "}  {e.item2.parameter.description}</label>
											</Box>
											<Card style={{ "backgroundColor": "#f0f1f2", width: "98%", display: "flex", border: "0px", marginRight: "2%" }}>
												<Box style={{ display: "column", width: "100%", marginTop: "1%" }}>
													<Box style={{ display: "flex", width: "100%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "30%", marginLeft: "1%" }} >{"Insumo"}</label>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "20%" }} >{"Quantidade"}</label>
													</Box>
													<Box style={{ display: "column", width: "100%", marginRight: 20 }}>
														{
															e.item2.parameterTemplateItem.listParameterTemplateItemInputDto.map(x => {
																return (
																	<Box style={{ display: "flex", width: "100%" }}>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25, marginLeft: "1%" }}>{x.input.description} </label>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25 }}>{x.amount} {x.input.unitOfMeasure.description} {x.input.type}</label>
																	</Box>

																)
															})
														}
													</Box>
												</Box>

											</Card>
										</Box>
										:
										<></>
								}
								{
									e.item3 ?
										<Box style={{ display: "column", width: "100%", marginLeft: 20, marginRight: 20, marginTop: "1%" }}>
											<Box style={{ display: "flex", width: "100%" }}>
												<label style={{ fontFamily: "helvetica", fontWeight: 500, fontSize: 22, color: "#161C24" }} >{"Recomendação de "}  {e.item3.parameter.description}</label>
											</Box>
											<Card style={{ "backgroundColor": "#f0f1f2", width: "98%", display: "flex", border: "0px", marginRight: "2%" }}>
												<Box style={{ display: "column", width: "100%", marginTop: "1%" }}>
													<Box style={{ display: "flex", width: "100%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "30%", marginLeft: "1%" }} >{"Insumo"}</label>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "20%" }} >{"Quantidade"}</label>
													</Box>
													<Box style={{ display: "column", width: "100%", marginRight: 20 }}>
														{
															e.item3.parameterTemplateItem.listParameterTemplateItemInputDto.map(x => {
																return (
																	<Box style={{ display: "flex", width: "100%" }}>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25, marginLeft: "1%" }}>{x.input.description} </label>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25 }}>{x.amount} {x.input.unitOfMeasure.description} {x.input.type}</label>
																	</Box>

																)
															})
														}
													</Box>
												</Box>

											</Card>
										</Box>
										:
										<></>
								}
								{
									e.item4 ?
										<Box style={{ display: "column", width: "100%", marginLeft: 20, marginRight: 20, marginTop: "1%" }}>
											<Box style={{ display: "flex", width: "100%" }}>
												<label style={{ fontFamily: "helvetica", fontWeight: 500, fontSize: 22, color: "#161C24" }} >{"Recomendação de "}  {e.item4.parameter.description}</label>
											</Box>
											<Card style={{ "backgroundColor": "#f0f1f2", width: "98%", display: "flex", border: "0px", marginRight: "2%" }}>
												<Box style={{ display: "column", width: "100%", marginTop: "1%" }}>
													<Box style={{ display: "flex", width: "100%" }}>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "30%", marginLeft: "1%" }} >{"Insumo"}</label>
														<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", width: "20%" }} >{"Quantidade"}</label>
													</Box>
													<Box style={{ display: "column", width: "100%", marginRight: 20 }}>
														{
															e.item4.parameterTemplateItem.listParameterTemplateItemInputDto.map(x => {
																return (
																	<Box style={{ display: "flex", width: "100%" }}>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25, marginLeft: "1%" }}>{x.input.description} </label>
																		<label style={{ fontFamily: "helvetica", width: "30%", fontSize: 25 }}>{x.amount} {x.input.unitOfMeasure.description} {x.input.type}</label>
																	</Box>

																)
															})
														}
													</Box>
												</Box>

											</Card>
										</Box>
										:
										<></>
								}
							</Box>

						)
					})
				}
				<Box style={{ marginTop: "17%", display: "flex", width: "100%" }}>
					<label style={{ fontFamily: "helvetica", fontSize: 30, color: "#161C24", margin: "0 auto" }} >{"Responsável Técnico"}</label>
				</Box>
				<Box style={{ display: "flex", width: "50%", margin: "0 auto", marginTop: "5%" }} >
					<div className={styles.border} />
				</Box>
				<Box style={{ display: "column", width: "100%" }}>
					<Box style={{ display: "flex" }}>
						<label style={{ fontFamily: "helvetica", fontSize: 25, color: "#161C24", margin: "0 auto" }}>{analyseSampleReturn && analyseSampleReturn.agronomist ? analyseSampleReturn.agronomist.name : ""}</label>
					</Box>
					<Box style={{ display: "flex" }}>
						<label style={{ fontFamily: "helvetica", fontSize: 25, color: "#161C24", margin: "0 auto" }}>{"Engenheiro Agrônomo"}</label>
					</Box>
				</Box>
				<Box style={{ display: "flex", width: "100%" }} >
					<div className={styles.border} />
				</Box>
				<Box style={{ display: "flex", width: "100%" }}>
					<Box style={{ display: "flex", width: "60%", marginLeft: "2%" }}>
						<label style={{ fontFamily: "helvetica", fontSize: 18, color: "#161C24", margin: "0 auto" }}>{analyseSampleReturn ? analyseSampleReturn.text : ""}</label>
					</Box>
					<Box style={{ display: "flex", width: "40%", height: "30%", marginTop: "2%" }}>
						<img src={data_results_logo} style={{ margin: "0 auto", width: "70%" }} />
					</Box>
				</Box>
			</Box>
		</>
	);
}

export default AnalyseSampleViewGenerate;
