import React from "react";
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  pagewhite: {
    marginTop: "25px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    paddingTop: "10px",
    paddingBottom: "40px",
  },
  divlabel: {
    marginBottom: "25px",
    marginTop: "25px",
    alignItems: "end",
    display: "flex",
    justifyContent: "flex-end",
  },
  block1: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
    width: "60%",
  },
  block2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "60%",
    marginTop: 30
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 30
  },
  tableRows: {
    display: "flex",
    height: 44,
    background: "#F2F5F8",
    marginTop: 10,
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer"
  },
  boxStatus: {
    display: "flex",
    borderRadius: 500,
    background: "rgba(32, 139, 206, 0.4)",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    paddingBottom: 6,
    justifyContent: "center",
    alignItems: "center"
  },
  tableLabelsHeader: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 12,
    color: "#919EAB"
  },
  tableLabels: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: 14,
    color: "#161C24",
    cursor: "pointer"
  },
  search: {
    border: "1px solid rgba(145, 158, 171, 0.32)",
    borderRadius: 8,
    height: 44,
    width: "100%",
    paddingLeft: 20,
    alignItems: "center",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 14,
  },
  button: {
    width: "100%",
    backgroundColor: "#0c8662",
    fontFamily: "Montserrat",
    color: "white",
    fontWeight: 700,
    fontSize: 14,
    padding: '10px',
    textTransform: "none"
  },
  '@media (max-width: 600px)': { // Estilos específicos para telas menores que 600px de largura
    container: {
      alignItems: "start", // Alinhar ao início em dispositivos móveis
    },
    block1: {
      width: "90%", // Reduzir a largura do bloco para se ajustar melhor em dispositivos móveis
    },
    block2: {
      width: "90%", // Reduzir a largura do bloco para se ajustar melhor em dispositivos móveis
    }
  }
});
