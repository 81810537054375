import React from 'react';
import { useHistory } from 'react-router-dom';
import InterpretationDetail from '../../components/InterpretationDetail';

import { Container, Card } from './styles';

const InterpretationDetails = () => {
    const history = useHistory();

    return (
        <InterpretationDetail/>
        
    );
}

export default InterpretationDetails;
