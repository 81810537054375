import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";

export default function ModalFormConfirmInterpretation({ newModalConfirmInterpretation, setShow, ...props }) {

  useEffect(() => {
  });

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Deseja configurar a recomedação</label>
        </Container>
      </ModalHeader>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => { newModalConfirmInterpretation(false) }} >Não</button>
            <button className="btn button-confirm" onClick={() => { newModalConfirmInterpretation(true) }} >Sim</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}