import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";
import { useStyles } from './styles';
import { InputBase } from "@material-ui/core";
import { exceptionNotificationAPI } from "../../services/api";

export default function ModalFormFaturamento({ setShow, fnExport, ...props }) {
  const styles = useStyles();
  const [exportType, setExportType] = useState("P");
  const [searchText, setSearchText] = useState("");

  const validExport = () => {
    if (exportType == "O" && searchText == ""){
      exceptionNotificationAPI("Por favor, preencha o campo Ordem de Serviço!");
      return false;
    }
    fnExport(exportType, searchText)
  }

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Gerar relatório de Faturamento</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <label className="field">Selecione abaixo os dados que você deseja imprimir:</label>
          <select name='exportType' value={exportType} className={styles.search} onChange={(e) => { setExportType(e.target.value) }}>
              <option value="P">Pendentes Faturamento</option>
              <option value="O">Ordem de Serviço</option>
          </select>
          {exportType == "O" && <InputBase className={styles.search} value={searchText} placeholder={"Ordem de Serviço"} style={{ background: "white" }} onChange={(e) => {
                    setSearchText(e.target.value)
                }}
                    // startAdornment={
                    //     <InputAdornment position="start">
                    //         <SearchIcon htmlColor='#5A646E' />
                    //     </InputAdornment>
                    // }
                />}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => { validExport() }} >Exportar</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}