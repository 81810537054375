import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { Container, Error } from './styles';
import moment from 'moment';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import PasswordStrengthBar from 'react-password-strength-bar';
import Select, { ActionMeta, OnChangeValue } from 'react-select';


const FormInput = ({ inputType, name, placeholder, options, hideAlert, min, max, isDash = null, customOptions = null, refNumber = null, ...rest }) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [startDate, setStartDate] = useState(new Date());
    const [inputValue, setInputValue] = useState('');
    const [customOptionsValues, setCustomOptionsValues] = useState([]);
    const [multipleValues, setMultipleValues] = useState([]);
    const MAX_LENGTH = 100;

    useEffect(() => {
        if (customOptions != null) {
            if (customOptions.indexOf(";") > -1)
                setCustomOptionsValues(customOptions.split(";"));
            else
                setCustomOptionsValues([customOptions]);
        }
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                if(ref.current){
                    if (inputType == "date") {
                        return ref.current.props.selected;
                    }
                    else {
                        return ref.current.value
                    }
                }

                return null;
            },
            setValue: (ref, value) => {
                if (inputType == "date") {
                    setStartDate(new Date(value))
                    ref.current.value = value
                }
                else {
                    ref.current.value = value
                }
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        });
    }, [fieldName, registerField]);

    const handleDateSelect = () => {
        return startDate;
    }

    const handleDate = (date) => {
        setStartDate(date)
    }

    const handleInputFocus = () => {
        setIsFocused(true);
    }

    const handleInputBlur = () => {
        setIsFocused(false);

        setIsFilled(!!inputRef.current?.value);
    }

    if(!placeholder && inputType !== "select" && inputType !== undefined && typeof inputType.startsWith === 'function' && inputType?.startsWith("select")){
        placeholder = {value: "", text: "Select an option"};
    }

    return (
        <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
            {inputType === "select" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    {options.map((option, i) => <option key={`${name}-${i}`} value={option.value}>{option.text.length > MAX_LENGTH ? `${option.text.substring(0, MAX_LENGTH)}...` : option.text}</option>)}
                </select>
            }

            {inputType === "selectyn" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value=""></option>
                    <option key={`${name}-2`} value="Yes">Yes</option>
                    <option key={`${name}-3`} value="No">No</option>
                </select>
            }

            {inputType === "selectynu" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Yes">Yes</option>
                    <option key={`${name}-2`} value="No">No</option>
                    <option key={`${name}-3`} value="Unsure">Unsure</option>
                </select>
            }

            {inputType === "selectasnn" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Always">Always</option>
                    <option key={`${name}-2`} value="Sometimes">Sometimes</option>
                    <option key={`${name}-3`} value="Never">Never</option>
                    <option key={`${name}-4`} value="NA">NA</option>
                </select>
            }

            {inputType === "selectasn" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="All">All</option>
                    <option key={`${name}-2`} value="Some">Some</option>
                    <option key={`${name}-3`} value="None">None</option>
                </select>
            }

            {inputType === "selecthousing" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="All">All</option>
                    <option key={`${name}-2`} value="More than half">More than half</option>
                    <option key={`${name}-3`} value="Approximaterly half">Approximaterly half</option>
                    <option key={`${name}-4`} value="Less than half">Less than half</option>
                    <option key={`${name}-5`} value="None">None</option>
                </select>
            }

            {inputType === "selectcharge" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Full Cost">Full Cost</option>
                    <option key={`${name}-2`} value="Subsidised">Subsidised</option>
                    <option key={`${name}-3`} value="Free of Charge">Free of Charge</option>
                </select>
            }

            {inputType === "selectli" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Local">Local</option>
                    <option key={`${name}-2`} value="Imported">Imported</option>
                    <option key={`${name}-3`} value="Free of Charge">Free of Charge</option>
                </select>
            }

            {inputType === "selectcustom" &&
            ((refNumber != "7309" && refNumber != "7316") ?
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    {customOptionsValues.map((option, i) => <option key={`${name}-${i}`} value={option}>{option.length > MAX_LENGTH ? `${option.substring(0, MAX_LENGTH)}...` : option}</option>)}
                </select>
                :
                <Select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    options={customOptionsValues.map((option) => {
                        var data = {
                            value: option,
                            label: option
                        }
                        return data;
                    })}
                    value={multipleValues}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={(a) => setMultipleValues(a)}
                    className="col-md-12"
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />)
            }

            {inputType === "textarea" &&
                <textarea onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={inputRef}
                    {...rest} />
            }

            {(inputType === "number") &&
                <input onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={inputRef}
                    step="0"
                    presicion={0}
                    type={inputType}
                    min={0}
                    {...rest} />
            }

            {(inputType === "decimal") &&
                <input onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    step="0.01"
                    presicion={2}   //very important
                    ref={inputRef}
                    type={"number"}
                    min={0}
                    {...rest} />
            }

            {(inputType === "date") &&
                <DatePicker ref={inputRef} onSelect={handleDateSelect} selected={startDate} onChange={(date) => { setStartDate(date) }} dateFormat="dd-MMM-yyyy" />
            }

            {(inputType === "input" || inputType === "" || inputType == null) &&
                <input onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    type={inputType === "input" ? "text" : inputType}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={inputRef}
                    {...rest} />
            }
            {(inputType === "password") &&
                <>
                    <div className={"d-block"}>
                        <input onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            type={inputType}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            ref={inputRef}
                            onChange={(e) => {
                                setInputValue(
                                    e.target.value
                                );
                            }}
                        />
                        <PasswordStrengthBar
                            password={inputValue}
                            minLength={10}
                        />
                    </div>
                </>
            }

            {isDash != null &&
                <Error title={error} className={hideAlert ? "hide" : ""}>
                    <FiAlertCircle color="#e71700" size={20} />
                </Error>
            }
        </Container>
    );
}

export default FormInput;
