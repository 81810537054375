import React from "react";
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 1px;
    border: 2px solid #000000;
`;

export const useStyles = makeStyles({
  block1: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 10,
    width: "50%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",  // Isso fará com que os itens quebrem para a próxima linha
    width: '100%',
    alignItems: "center",
    justifyContent: "center", // Centralizar itens horizontalmente no container
  },
  

  buttonAnalyseColor: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "45px",
    background: "#E5FDE1",
    borderRadius: "1px",
    border: "1px solid #D0D0D0"
  },
  labelweight: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    alignItems: "center",
    marginLeft: 10,
    color: "#FFFFFF",
  },
  labelEdit: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontSize: "14px",
    alignItems: "center",
    color: "#FFFFFF",
    marginTop: '12px'
  },
  labelSelected: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    alignItems: "left",
    marginLeft: 10,
    color: "#FFFFFF",
    paddingLeft: '6px'
  },
  block: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingRight: '2%'
  },
  selectorStyled: {
    marginTop: '1px',
    height: '44px',
    borderRadius: '8px',
    border: '1px solid #ACB4BA',
    outline: 'none',
    textAlign: 'left',
    width: '100%',
  },
  border: {
    width: "98%",
    marginLeft: "1%",
    border: "1px solid #CCD1D6",
    marginTop: '5px',
    marginBottom: '5px'
  },
  button: {
    background: "#0C8662",
    borderRadius: 4,
    width: '150px',
    height: 40,
    fontFamily: "Montserrat",
    color: "white",
    fontSize: 12,
    textTransform: "none"
  },
  inputLevel: {
    width: '150px',
    marginTop: '1px',
    marginLeft: '16px',
    height: '44px',
    borderRadius: '8px',
    border: '1px solid #ACB4BA',
  },
  inputRecomendation: {
    height: '44px',
    marginTop: '1px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #ACB4BA',
  },
  textAreaRecomendation: {
    height: '120px',
    width: '100%',
    overflowY: 'scroll',
    borderRadius: '8px',
    border: '1px solid #ACB4BA',
  },
  colorBox: {
    height: '25px',
    width: '25px',
    marginLeft: '10px',
    marginTop: '12px',
    borderRadius: '6px'
  },
  addRecomendation: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    alignItems: "left",
    marginLeft: 10,
    marginTop: '12px',
    paddingLeft: '6px'
  },
  boxParameter: {
    border: "1px solid #F2F5F8",
    color: "#737D86",
    height: "60px",
    backgroundColor: "transparent",
    "&:hover, &:focus": {
      backgroundColor: "#AAD6C2"
    }
  },
  buttonAnalyse: {
    width: "100%", // 20% é 100% dividido por 5 (itens por linha), ajuste os valores conforme necessário
    height: "45px",
    background: "#FFFFFF",
    borderRadius: "1px",
    border: "1px solid #D0D0D0",
    boxSizing: "border-box"
  }, 
  pagewhite: {
    display: "flex",
    marginTop: "25px",
    padding: "20px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
  },
});
