import React from "react";
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 1px;
    border: 2px solid #000000;
`;

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  block2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    width: "72%",
  },
  block1: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 10,
    width: "80%",
  },
  block: {
    display: "flex",
    justifyContent: "center",
    width: "80%",
  },
  page: {
    marginLeft: '5rem',
    marginRight: '5rem',
    'page-break-after': 'always',
  },

  columnLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '3rem 0 5rem 0',
    gap: '2rem',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  spacer2: {
    height: '2rem',
  },

  fullWidth: {
    width: '100%',
  },

  marginb0: {
    marginBottom: 0,
  },
  tableLabelsHeader: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 14,
    color: "#161C24"
  },
  border: {
    width: "100%",
    marginTop: "5px",
    height: "0px",
    marginLeft: "1%",
    border: "1px solid #000000",
  },
  button: {
    background: "#0c8662",
    borderRadius: 8,
    width: 250,
    height: 30,
    fontFamily: "Montserrat",
    color: "white",
    fontSize: 14,
    textTransform: "none",
    marginBottom: 50,
  },
  fieldResponsible: {
    width: "30%",
    marginTop: "2px",
    height: "40px",
    borderRadius: "8px",
    outline: "none",
    padding: "15px 0 5 10px",
    color: "#000000",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 30
  },
  tableRows: {
    display: "flex",
    height: 44,
    background: "#F2F5F8",
    marginTop: 10,
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer"
  },
  inputExpand: {
    height: "35px",
    borderRadius: "8px",
    border: "1px solid #ACB4BA",
    outline: "none",
    padding: "10px"
  },
  input: {
    marginTop: "2px",
    height: "40px",
    width: "20%",
    borderRadius: "8px",
    border: "1px solid #ACB4BA",
    outline: "none",
    padding: "10px"
  },
  labelweight: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    alignItems: "center",
    marginLeft: 10,
    color: "#FFFFFF",
  },

  buttonWeight: {
    marginTop: "10px",
    width: "10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    height: "35px",
    background: "#0c8662",
    borderRadius: "8px",
  },
});