import styled from 'styled-components';

export const Container = styled.div`

    flex: 1;
    flex-direction: column;

    .main{
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: start;
        justify-content: center;
        margin-top: 20px;
    }

    .title{
        font: normal normal bold 22px Montserrat;
        margin-left: 4%;
        margin-top: 24px;
    }


    .label-index{
        font: normal normal bold 14px Montserrat;
    }

    .div-label{
        display: flex;
        margin-left: 24px;
        margin-bottom: 25px;
        width: 10px
        justify-content: start;
        flex: 2;
        align-items: end;
    }

    .div-icon{
        flex: 2;
        display: flex;
        margin-bottom: 5%;
        justify-content: center;
        align-items: end;
    }

    .icon{
        margin-left: 10px;
    }
`;

export const ContainerHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const Title = styled.h3`
    color: #27b5e5;
    text-align: left;
    font: normal normal bold 22px Segoe UI;
    letter-spacing: 1.1px;
`;

export const Actions = styled.div`
    display: flex;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    width: 22%;
    height: 99px;
    margin: 0px 12px;
    border-radius: 8px;
    cursor: pointer
`;


export const Table = styled.table`
    border-radius: 7px!important;
    background: white;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    border: 1px solid #5E7BB9;
    border-radius: 8px 8px 8px 7px;
    opacity: 1;

    tr:nth-child(odd) > td,
    tr:nth-child(odd) > th {
        background-color: rgba(0,0,0,.05);
    }

    tr:nth-child(even) > td,
    tr:nth-child(even) > th {
        background-color: white;
    }

    th {
        text-align: left!important;
        font: normal normal medium 12px Segoe UI!important;
        letter-spacing: 0px!important;
        opacity: 1!important;
        background-color: #234285!important;
        color: #D2D9E6!important;
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 7px;
        }

        td:last-child {
            border-bottom-right-radius: 7px;
        }
    }

    th:first-child {
        border-top-left-radius: 7px;
    }

    th:last-child {
        border-top-right-radius: 7px;
    }

    td:first-child {
        padding-top: 20px!important;
    }

    th, td {
        border-top: 0px!important;
        padding-left: 35px!important;
        padding-top: 15px!important;
        padding-bottom: 12px!important;
        padding-right: 12px!important;
        font-size: 14px;
        letter-spacing: 0.7px;
        opacity: 1;
        border: 0;
    }
`;


