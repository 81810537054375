import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import TableLoading from '../TableLoading';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import { error_message, success_message } from '../Toast/index.jsx';
import { Container, FormControlLabel, Checkbox } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box, Grid, Button } from '@material-ui/core';

const SettingsCompositionDetail = () => {
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [listRecommendation, setListRecommendation] = useState();
    const [listFertilizerComposition, setListFertilizerComposition] = useState();
    const location = useLocation();
    const [listFertilizerCompositionStage, setListFertilizerCompositionStage] = useState([]);
    const [parameterTemplateDescription, setParameterTemplateDescription] = useState([]);
    const [npkSummaryChecked, setNpkSummaryChecked] = useState(false);

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)

        loadFertilizerCompositionStages((options) => {
            if (cancel) return
            if (options) {
                setListFertilizerCompositionStage(options.item1)
                setNpkSummaryChecked(options.item2)
                setParameterTemplateDescription(options.item3)

                loadRecommendation((optionsRecommendation) => {
                    if (optionsRecommendation) {
                        setListRecommendation(optionsRecommendation)

                        loadFertilizerComposition((optionsFertilizerComposition) => {
                            if (optionsFertilizerComposition) {
                                setListFertilizerComposition(optionsFertilizerComposition)
                                setIsLoading(false)
                            }
                        })
                    }
                })
            }
        })

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const loadRecommendation = async (callback) => {
        api.get(`Recommendation/GetAllItems`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadFertilizerCompositionStages = async (callback) => {
        api.get(`FertilizerCompositionStage/GetAllFertilizerCompositionStages?id=${(location.state ? location.state.id : '00000000-0000-0000-0000-000000000000')}`).then((result) => {
            if (result && result.data && result.data.response.item1 &&  result.data.response.item3) {
                const options = result.data.response;

                callback(options);
            }
            else
            {
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadFertilizerComposition = async (callback) => {
        api.get(`FertilizerComposition/GetAllItems`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const setValues = async (event, element, type) => {
        setIsLoading(true)
        await listFertilizerCompositionStage.forEach(function (_fertilizerCompositionStage) {
            if (_fertilizerCompositionStage.stageID == element.stageID) {
                switch (type) {
                    case 'n_Recommendation':
                        if (event == 'noValue') {
                            _fertilizerCompositionStage.n_RecommendationID = null;

                            document.getElementById(_fertilizerCompositionStage.stageID + 1).disabled = false;
                            document.getElementById(_fertilizerCompositionStage.stageID + 2).disabled = false;
                            document.getElementById(_fertilizerCompositionStage.stageID + 3).disabled = false;
                        }
                        else {
                            _fertilizerCompositionStage.n_RecommendationID = event;

                            document.getElementById(_fertilizerCompositionStage.stageID + 1).disabled = false;
                            document.getElementById(_fertilizerCompositionStage.stageID + 2).disabled = true;
                            document.getElementById(_fertilizerCompositionStage.stageID + 2).selectedIndex = 0;

                            document.getElementById(_fertilizerCompositionStage.stageID + 3).disabled = true;
                            document.getElementById(_fertilizerCompositionStage.stageID + 3).selectedIndex = 0;
                        }
                        break;
                    case 'n_Value':
                        _fertilizerCompositionStage.n = event;
                        break;
                    case 'p_Recommendation':
                        if (event == 'noValue') {
                            _fertilizerCompositionStage.p_RecommendationID = null;

                            document.getElementById(_fertilizerCompositionStage.stageID + 1).disabled = false;
                            document.getElementById(_fertilizerCompositionStage.stageID + 2).disabled = false;
                            document.getElementById(_fertilizerCompositionStage.stageID + 3).disabled = false;
                        }
                        else {
                            _fertilizerCompositionStage.p_RecommendationID = event;

                            document.getElementById(_fertilizerCompositionStage.stageID + 1).disabled = true;
                            document.getElementById(_fertilizerCompositionStage.stageID + 1).selectedIndex = 0;

                            document.getElementById(_fertilizerCompositionStage.stageID + 2).disabled = false;

                            document.getElementById(_fertilizerCompositionStage.stageID + 3).disabled = true;
                            document.getElementById(_fertilizerCompositionStage.stageID + 3).selectedIndex = 0;
                        }
                        break;
                    case 'p_Value':
                        _fertilizerCompositionStage.p = event;
                        break;
                    case 'k_Recommendation':
                        if (event == 'noValue') {
                            _fertilizerCompositionStage.k_RecommendationID = null;

                            document.getElementById(_fertilizerCompositionStage.stageID + 1).disabled = false;
                            document.getElementById(_fertilizerCompositionStage.stageID + 2).disabled = false;
                            document.getElementById(_fertilizerCompositionStage.stageID + 3).disabled = false;
                        }
                        else {
                            _fertilizerCompositionStage.k_RecommendationID = event;


                            document.getElementById(_fertilizerCompositionStage.stageID + 1).disabled = true;
                            document.getElementById(_fertilizerCompositionStage.stageID + 1).selectedIndex = 0;

                            document.getElementById(_fertilizerCompositionStage.stageID + 2).disabled = true;
                            document.getElementById(_fertilizerCompositionStage.stageID + 2).selectedIndex = 0;

                            document.getElementById(_fertilizerCompositionStage.stageID + 3).disabled = false;
                        }
                        break;
                    case 'k_Value':
                        _fertilizerCompositionStage.k = event;
                        break;
                    case 'fertilizerComposition':
                        if (event == 'noValue') {
                            _fertilizerCompositionStage.fertilizerCompositionID = null;
                        }
                        else {
                            _fertilizerCompositionStage.fertilizerCompositionID = event;
                        }
                        break;
                    default:
                        break;
                }
                setIsLoading(false)
            }
        });
    }


    const save = async () => {
        try {
            var message = null;
            await listFertilizerCompositionStage.forEach(function (_fertilizerCompositionStage) {
                if (_fertilizerCompositionStage.fertilizerCompositionID === '00000000-0000-0000-0000-000000000000') {
                    message = "informe um Fertilizante para todos os itens";
                }
                else if (_fertilizerCompositionStage.n_RecommendationID === null && _fertilizerCompositionStage.p_RecommendationID === null && _fertilizerCompositionStage.k_RecommendationID === null) {
                    message = "informe uma Recomendação de base para todos os itens";
                }
                else {
                    _fertilizerCompositionStage.parameterTemplateID = location.state.id;
                }
            });
            if (message === null) {
                setIsLoading(true);
                const payload = {
                    fertilizerCompositionStages: [...listFertilizerCompositionStage],
                    npkSummary: npkSummaryChecked
                };
                await api.post('FertilizerCompositionStage/InsertUpdate', payload).then((result) => {
                    if (result.data.response == null) {
                        success_message("Configuração NPK salva com sucesso!");
                        history.goBack();
                    } else {
                        error_message(result.data.response);
                    }
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                    exceptionNotificationAPI(error);
                });
            } else {
                error_message(message);
            }
        } catch (error) {
            setIsLoading(false)
            exceptionNotificationAPI(error);
        }
    }

    return (
        <>
            {
                <>
                    <Container>
                        <Row style={{ marginTop: '30px' }}>
                            <Col md={12} lg={12} xs={12}>
                                <button className='btn' onClick={() => {
                                    history.goBack();
                                }}>
                                    <img src={ArrowLeft} className='icon' />
                                </button>
                                <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Configuração de NPK - " + parameterTemplateDescription}</label>
                            </Col>
                        </Row>
                        <Box style={{ marginTop: "5px", background: "#F2F5F8", border: "1px solid #F2F5F8", borderRadius: "8px" }}>
                            <Grid alignItems="flex-start" style={{ marginTop: "20px" }} container spacing={1}>
                                <Grid item xs={2} >
                                </Grid>
                                <Grid item xs={3} container justifyContent="center">
                                    <Box textAlign="center">
                                        <label style={{ fontWeight: 600 }}>N</label>
                                    </Box>
                                </Grid>
                                <Grid item xs={3} container justifyContent="center">
                                    <Box textAlign="center">
                                        <label style={{ fontWeight: 600 }}>P</label>
                                    </Box>
                                </Grid>
                                <Grid item xs={3} container justifyContent="center">
                                    <Box textAlign="center">
                                        <label style={{ fontWeight: 600 }}>K</label>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{ marginTop: "5px", background: "#F2F5F8", border: "1px solid #F2F5F8", borderRadius: "8px" }}>
                            <Grid alignItems="flex-start" style={{ marginTop: "20px" }} container spacing={1}>
                                <Grid item xs={2} >
                                    <label style={{ fontWeight: 600 }} >Estágio</label>
                                </Grid>
                                <Grid item xs={2} >
                                    <label style={{ fontWeight: 600 }} >Recomendação de Base</label>
                                </Grid>
                                <Grid item xs={1} >
                                    <label style={{ fontWeight: 600 }} >% Cálculo</label>
                                </Grid>
                                <Grid item xs={2} >
                                    <label style={{ fontWeight: 600 }} >Recomendação de Base</label>
                                </Grid>
                                <Grid item xs={1} >
                                    <label style={{ fontWeight: 600 }} >% Cálculo</label>
                                </Grid>
                                <Grid item xs={2} >
                                    <label style={{ fontWeight: 600 }} >Recomendação de Base</label>
                                </Grid>
                                <Grid item xs={1} >
                                    <label style={{ fontWeight: 600 }} >% Cálculo</label>
                                </Grid>
                                <Grid item xs={1} >
                                    <label style={{ fontWeight: 600 }} >Fertilizante</label>
                                </Grid>
                            </Grid>
                        </Box>
                        {
                            listFertilizerCompositionStage ? listFertilizerCompositionStage.filter(function (element) { return element.isDeleted == false; }).map(e => {
                                return (
                                    <Box key={e.stageID} style={{ marginTop: "5px", background: "#F2F5F8", border: "1px solid #F2F5F8", borderRadius: "8px" }}>
                                        <Grid alignItems="flex-start" style={{ marginTop: "20px" }} container spacing={1}>
                                            <Grid item xs={2} >
                                                <label >{e.stage.description}</label>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <select
                                                    id={`${e.stageID + 1}`}
                                                    disabled={e.n_RecommendationID == null && e.p_RecommendationID == null && e.k_RecommendationID == null ? false : e.n_RecommendationID == null ? true : false}
                                                    onChange={(t) => { setValues(t.target.value, e, 'n_Recommendation') }}
                                                    name='n_RecommendationID'
                                                    defaultValue={e.n_RecommendationID ? e.n_RecommendationID : "noValue"}
                                                    className={styles.search}
                                                    style={{ width: "100%", marginRight: "1%" }}
                                                >
                                                    {listRecommendation && listRecommendation.length > 0 ? (
                                                        <>
                                                            <option value="noValue">
                                                                Selecione
                                                            </option>
                                                            {listRecommendation.map((recommendation) => (
                                                                <option
                                                                    key={recommendation.recommendationID}
                                                                    value={recommendation.recommendationID}
                                                                >
                                                                    {recommendation.description}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <option value="noValue" selected>
                                                            Selecione
                                                        </option>
                                                    )}
                                                </select>
                                            </Grid>
                                            <Grid item xs={1} >
                                                <Box textAlign="center">
                                                    <input type={"number"} Value={e.n ? e.n : 0} onChange={(t) => { setValues(t.target.value, e, 'n_Value') }} style={{ width: "70%" }} className={styles.input}></input>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <select
                                                    id={`${e.stageID + 2}`}
                                                    disabled={e.n_RecommendationID == null && e.p_RecommendationID == null && e.k_RecommendationID == null ? false : e.p_RecommendationID == null ? true : false}
                                                    onChange={(t) => { setValues(t.target.value, e, 'p_Recommendation') }}
                                                    name='p_RecommendationID'
                                                    defaultValue={e.p_RecommendationID ? e.p_RecommendationID : "noValue"}
                                                    className={styles.search}
                                                    style={{ width: "100%", marginRight: "1%" }}
                                                >
                                                    {listRecommendation && listRecommendation.length > 0 ? (
                                                        <>
                                                            <option value="noValue">
                                                                Selecione
                                                            </option>
                                                            {listRecommendation.map((recommendation) => (
                                                                <option
                                                                    key={recommendation.recommendationID}
                                                                    value={recommendation.recommendationID}
                                                                >
                                                                    {recommendation.description}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <option value="noValue" selected>
                                                            Selecione
                                                        </option>
                                                    )}
                                                </select>
                                            </Grid>
                                            <Grid item xs={1} >
                                                <Box textAlign="center">
                                                    <input type={"number"} Value={e.p ? e.p : 0} onChange={(t) => { setValues(t.target.value, e, 'p_Value') }} style={{ width: "70%" }} className={styles.input}></input>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <select
                                                    id={`${e.stageID + 3}`}
                                                    disabled={e.n_RecommendationID == null && e.p_RecommendationID == null && e.k_RecommendationID == null ? false : e.k_RecommendationID == null ? true : false}
                                                    onChange={(t) => { setValues(t.target.value, e, 'k_Recommendation') }}
                                                    name='k_RecommendationID'
                                                    defaultValue={e.k_RecommendationID ? e.k_RecommendationID : "noValue"}
                                                    className={styles.search}
                                                    style={{ width: "100%", marginRight: "1%" }}
                                                >
                                                    {listRecommendation && listRecommendation.length > 0 ? (
                                                        <>
                                                            <option value="noValue">
                                                                Selecione
                                                            </option>
                                                            {listRecommendation.map((recommendation) => (
                                                                <option
                                                                    key={recommendation.recommendationID}
                                                                    value={recommendation.recommendationID}
                                                                >
                                                                    {recommendation.description}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <option value="noValue" selected>
                                                            Selecione
                                                        </option>
                                                    )}
                                                </select>
                                            </Grid>
                                            <Grid item xs={1} >
                                                <Box textAlign="center">
                                                    <input type={"number"} Value={e.k ? e.k : 0} onChange={(t) => { setValues(t.target.value, e, 'k_Value') }} style={{ width: "70%" }} className={styles.input}></input>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={1} >
                                                <select
                                                    onChange={(t) => { setValues(t.target.value, e, 'fertilizerComposition') }}
                                                    name='fertilizerCompositionID'
                                                    defaultValue={e.fertilizerCompositionID ? e.fertilizerCompositionID : "noValue"}
                                                    className={styles.search}
                                                    style={{ width: "100%", marginRight: "1%" }}
                                                >
                                                    {listFertilizerComposition && listFertilizerComposition.length > 0 ? (
                                                        <>
                                                            <option value="noValue" >
                                                                Selecione
                                                            </option>
                                                            {listFertilizerComposition.map((fertilizerComposition) => (
                                                                <option
                                                                    key={fertilizerComposition.fertilizerCompositionID}
                                                                    value={fertilizerComposition.fertilizerCompositionID}
                                                                >
                                                                    {fertilizerComposition.description}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <option value="noValue" selected>
                                                            Selecione
                                                        </option>
                                                    )}
                                                </select>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            })
                                :
                                <></>
                        }
                        {
                            <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "20px", marginLeft: "10px", marginBottom: "10px" }}>
                                <FormControlLabel
                                    control={<Checkbox name="npkSummary" checked={npkSummaryChecked} onChange={(e) => setNpkSummaryChecked(e.target.checked)} />}
                                    label="NPK Resumido no relatório"
                                />
                            </Box>
                        }
                        {
                            <Box style={{ display: "flex", justifyContent: "flex-end" }} >
                                <Button className={styles.buttonWeight} onClick={() => {
                                    save()
                                }}>
                                    <label className={styles.labelweight}>Salvar</label>
                                </Button>
                            </Box>
                        }
                    </Container>
                </>
            }
        </>
    );
}

export default SettingsCompositionDetail;
