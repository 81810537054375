import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import AnalyseSampleViewGenerate from '../../components/AnalyseSampleViewGenerate';
import { Box, Button } from '@material-ui/core';
import { useStyles } from './styles';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import { useHistory } from 'react-router-dom';

function AnalyseSampleView() {
	const AnalyseSampleViewGenerateRef = useRef(null);
	const styles = useStyles();
	const history = useHistory();

	const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'pt',
		});

		var value = 0.25;
		if (window.screen.width >= 2304 && window.screen.width <= 2496) {
			value = 0.29;
		}
		else if (window.screen.width >= 2112 && window.screen.width <= 2303) {
			value = 0.33;
		}
		else if (window.screen.width >= 1920 && window.screen.width <= 2111) {
			value = 0.37;
		}
		else if (window.screen.width >= 1728 && window.screen.width <= 1919) {
			value = 0.41;
		}
		else if (window.screen.width >= 1536 && window.screen.width <= 1727) {
			value = 0.45;
		}
		else if (window.screen.width >= 1344 && window.screen.width <= 1535) {
			value = 0.49;
		}
		else if (window.screen.width >= 1152 && window.screen.width <= 1343) {
			value = 0.53;
		}
		else if (window.screen.width >= 960 && window.screen.width <= 1151) {
			value = 0.57;
		}
		else if (window.screen.width <= 959) {
			value = 0.61;
		}

		doc.html(AnalyseSampleViewGenerateRef.current, {
			async callback(doc) {
				await doc.save('document');
			},
			html2canvas: { scale: value },
			margin: [40, 20, 40, 0],
		});
		
	};

	return (
		<Box style={{ marginTop: "20px" }}>
			<Box className={styles.block1} style={{ marginLeft: "10%" }}>
				<button className='btn' onClick={() => {
					history.goBack();
				}}>
					<img src={ArrowLeft} className='icon' />
				</button>
			</Box>
			<Button style={{ marginLeft: "45%", marginBottom: "1%" }} className={styles.buttonWeight} onClick={handleGeneratePdf}>
				<label className={styles.labelweight}>Gerar PDF</label>
			</Button>
			<Box style={{ width: "80%", margin: "0 auto" }}>
				<Box className={styles.block} ref={AnalyseSampleViewGenerateRef} >
					<AnalyseSampleViewGenerate />
				</Box>
			</Box>
		</Box>
	);
}

export default AnalyseSampleView;