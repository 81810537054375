import styled from 'styled-components';
import data_results_fundo from '../../assets/images/data_results_fundo.png';

export const Container = styled.div`
  .main-div{
    background-image: url(${data_results_fundo});
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .card {
    display: flex
    height: 100%;

    .image{
      color: #0E2B63;
      width: 100%;
    }

    .card-body{

      padding: 35px 50px 35px 50px;
      
      .logo{
        margin-left: 0%;
      }

      .form-group{
        padding: 10px;
      }

      .form{
        padding: 5px 0px 5px 0px;
      }

      label{
        font: normal normal bold 14px Montserrat;
        padding: 5px 200px 5px 0px;
      }

      input{
        border-radius: 8px;
        height: 44px;
      }

      button{
        border-radius: 8px;
        background-color: #0c8662;
        letter-spacing: 0.65px;
        text-align: center;
        width: 100%;
        height: 13%;
        color: #FFFFFF;
        font-weight: bold;
        margin-right: 34px;
        margin-top: 20px;
      }   
      
      a{
        margin-top: 15px;
        text-align: center;
        color: #0393E4;
        font: Montserrat;
        display: flex;
        justify-content: center;  
        size: 12px;
      }
    }


    .terms {
      padding: 1rem 0 0;
    }

    a { 
      text-decoration: underline;
      color: #00b1eb;
      font-weight: 800;
    }

    p{
      font-size: .9rem;
    }
  }

  .main-div .card-terms {
    background-color: #f9fafb;
    margin: 1rem auto;
  }
`;

export const PasswordInput = styled.input`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 100%;
    margin: 0.5rem 0rem;
    position: relative;
    padding: 0.2rem;
    
`;

