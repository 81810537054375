import React from 'react';
import { useHistory } from 'react-router-dom';
import TenantForm from '../../components/TenantForm';

import { Container, Card } from './styles';

const TenantForms = () => {
    const history = useHistory();

    return (
        <TenantForm/>
        
    );
}

export default TenantForms;
