import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";
import { SketchPicker } from 'react-color';

export default function ModalColor({ newModalColor, setShow, colorElement, ...props }) {
  const [color, setColor] = useState('#FF0000');
  const [element, setElement] = useState();

  useEffect(() => {
    if (colorElement) {
      setElement(colorElement)
      setColor(colorElement.resultColor ? colorElement.resultColor : '#FF0000')
    }
  }, [colorElement]);

  return (
    <Modal
      {...props}
      size='sm'
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Selecione a cor</label>
        </Container>
      </ModalHeader>
      <ModalBody style={{ margin: 'auto' }}>
        <Container >
          <div >
            <SketchPicker
              color={color}
              onChangeComplete={(color) => { setColor(color.hex) }}
            />
          </div>
          <div style={{
            background: color,
            height: '100px',
            width: '220px',
            transition: 'ease all 500ms'
          }}>

          </div>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => { newModalColor(color, element) }} >Selecionar</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}