//CRUDS
import Dashboard from '../pages/Dashboard';
import TenantIndex from '../pages/Tenant/index';
import TenantForm from '../pages/TenantForm';
import BranchIndex from '../pages/Branch/index';
import BranchForm from '../pages/Branch/form';
import SupplierIndex from '../pages/Supplier/index';
import SupplierForm from '../pages/Supplier/form';
import RecommendationIndex from '../pages/Recommendation/index';
import RecommendationForm from '../pages/Recommendation/form';
import FertilizerCompositionIndex from '../pages/FertilizerComposition/index';
import FertilizerCompositionForm from '../pages/FertilizerComposition/form';
import CultureIndex from '../pages/Culture/index';
import CultureForm from '../pages/Culture/form';
import RegionIndex from '../pages/Region/index';
import RegionForm from '../pages/Region/form';
import FarmerIndex from '../pages/Farmer/index';
import FarmerForm from '../pages/Farmer/form';
import UserIndex from '../pages/User/index';
import UserForm from '../pages/User/form';
import AgronomistIndex from '../pages/Agronomist/index';
import AgronomistForm from '../pages/Agronomist/form';
import RecommendationRegister from '../pages/RecommendationRegister';
import ForgotPassword from '../pages/SignIn/forgotPassword';

// relatórios
import RecomendationReport from '../reports/recomendationReport';

//Other Pages
import SignIn from '../pages/SignIn';

//Analysis
import AnalyseSample from '../pages/AnalyseSample';
import AnalyseSampleDetails from '../pages/AnalyseSampleDetails';
import AnalyseSampleViews from '../pages/AnalyseSampleViews';
import AnalyseSampleDetailsInterpretationRecommendation from '../pages/AnalyseSampleDetailsInterpretationRecommendation';

//Interpretation
import Interpretation from '../pages/Interpretation';
import InterpretationDetails from '../pages/InterpretationDetails';
import SettingsCompositionDetails from '../pages/SettingsCompositionDetails';
import AnalyseType from '../pages/AnalyseType/index';
import AnalyseTypeForm from '../pages/AnalyseTypeForm';
import ParameterIndex from '../pages/Parameter/index';
import ParameterForm from '../pages/Parameter/form';

//Check-in
import CheckIn from '../pages/CheckIn';

//Roles
import { ROLE } from '../config/roles';

const RoutesList = [
    {
        path: "/ForgotPassword/Token=:token&Id=:id",
        label: "Forgot Password",
        component: ForgotPassword,
        onlyGlobal: false
    },
    {
        path: "/ForgotPassword/Token=:token&Id=:id",
        label: "Forgot Password",
        component: ForgotPassword
    },
    {
        isVisible: true,
        path: "/",
        label: "Inicio",
        component: Dashboard,
        isPrivate: true,
        showInSidebar: true,
        icon: "HomeOutlinedIcon",
    },
    {
        isVisible: true,
        path: "/signin",
        label: "SignIn",
        component: SignIn
    },

    // adicionado para rota
    {
        isVisible: false,
        path: "/culture",
        label: "Cultura",
        component: CultureIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/culture/edit/:id",
        label: "Editar Cultura",
        component: CultureForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/culture/new",
        label: "Nova Cultura",
        component: CultureForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/interpretation",
        label: "interpretation",
        component: Interpretation,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/interpretation/details",
        label: "interpretation",
        component: InterpretationDetails,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/settingsComposition/details",
        label: "settingsComposition",
        component: SettingsCompositionDetails,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/analyseType",
        label: "analyseType",
        component: AnalyseType,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST, ROLE.SUPPLIER]
    },
    {
        isVisible: true,
        path: "/analyseType/new",
        label: "analyseType",
        component: AnalyseTypeForm,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST, ROLE.SUPPLIER]
    },
    {
        isVisible: true,
        path: "/analyseType/edit/:id",
        label: "Editar Tipo de Análise",
        component: AnalyseTypeForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST, ROLE.SUPPLIER]
    },
    {
        isVisible: false,
        path: "/parameter",
        label: "Parâmetro",
        component: ParameterIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.SUPPLIER, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/parameter/edit/:id",
        label: "Editar Parâmetro",
        component: ParameterForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.SUPPLIER, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/parameter/new",
        label: "Nova Parâmetro",
        component: ParameterForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.SUPPLIER, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/checkIn",
        label: "checkIn",
        component: CheckIn,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.SUPPLIER, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/analyseSample",
        label: "analyseSample",
        component: AnalyseSample,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST, ROLE.SOILANALYSISREPORT]
    },
    {
        isVisible: true,
        path: "/analyseSample/details",
        label: "analyseSampleDetails",
        component: AnalyseSampleDetails,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST, ROLE.SOILANALYSISREPORT]
    },
    {
        isVisible: true,
        path: "/analyseSample/views",
        label: "analyseSampleViews",
        component: AnalyseSampleViews,
        isPrivate: true,
        showInSidebar: false,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST, ROLE.SOILANALYSISREPORT]
    },
    {
        isVisible: true,
        path: "/analyseSample/details/interpretationRecommendations",
        label: "analyseSampleDetailsInterpretationRecommendation",
        component: AnalyseSampleDetailsInterpretationRecommendation,
        isPrivate: true,
        showInSidebar: false,
        icon: "Animation",
    },
    {
        isVisible: false,
        path: "/Tenant",
        label: "Empresa",
        component: TenantIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/Tenant/edit/:id",
        label: "Editar Empresa",
        component: TenantForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN]
    },
    {
        isVisible: true,
        path: "/Tenant/new",
        label: "Nova Empresa",
        component: TenantForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN]
    },
    {
        isVisible: false,
        path: "/branch",
        label: "Filial",
        component: BranchIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/branch/edit/:id",
        label: "Editar Filial",
        component: BranchForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
    },
    {
        isVisible: true,
        path: "/branch/new",
        label: "Nova Filial",
        component: BranchForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
    },
    {
        isVisible: false,
        path: "/supplier",
        label: "Laboratório",
        component: SupplierIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/supplier/edit/:id",
        label: "Editar Laboratório",
        component: SupplierForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN]
    },
    {
        isVisible: true,
        path: "/supplier/new",
        label: "Nova Laboratório",
        component: SupplierForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN]
    },
    {
        isVisible: false,
        path: "/recommendation",
        label: "Tipo de Recomendação",
        component: RecommendationIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/recommendation/edit/:id",
        label: "Editar Tipo de Recomendação",
        component: RecommendationForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/recommendation/new",
        label: "Novo tipo de recomendação",
        component: RecommendationForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: false,
        path: "/fertilizerComposition",
        label: "Composição do Fertilizante",
        component: FertilizerCompositionIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/fertilizerComposition/edit/:id",
        label: "Editar Recomendação do Fertilizante",
        component: FertilizerCompositionForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/fertilizerComposition/new",
        label: "Nova Recomendação do Fertilizante",
        component: FertilizerCompositionForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: false,
        path: "/region",
        label: "Região",
        component: RegionIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/region/edit/:id",
        label: "Editar Região",
        component: RegionForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/region/new",
        label: "Nova Região",
        component: RegionForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: false,
        path: "/farmer",
        label: "Produtor",
        component: FarmerIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST], //, ROLE.FARMER, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/farmer/edit/:id",
        label: "Editar Produtor",
        component: FarmerForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST] //, ROLE.FARMER, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/farmer/new",
        label: "Novo Produtor",
        component: FarmerForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST] //, ROLE.FARMER, ROLE.AGRONOMIST]
    },
    {
        isVisible: false,
        path: "/agronomist",
        label: "Responsável Técnico",
        component: AgronomistIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/agronomist/edit/:id",
        label: "Editar Responsável Técnico",
        component: AgronomistForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: true,
        path: "/agronomist/new",
        label: "Novo Responsável Técnico",
        component: AgronomistForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST]
    },
    {
        isVisible: false,
        path: "/user",
        label: "Usuário",
        component: UserIndex,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        path: "/user/new",
        label: "Novo Usuário",
        component: UserForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER, ROLE.AGRONOMIST]
    },
    {
        path: "/user/edit/:id",
        label: "Editar Usuário",
        component: UserForm,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER, ROLE.AGRONOMIST]
    },
    {
        isVisible: false,
        path: "/reports/recomendationReport",
        label: "recomendationReport",
        component: RecomendationReport,
        isPrivate: true,
        showInSidebar: false,
        icon: "Report",
    },
    {
        isVisible: false,
        path: "/recommendationRegister",
        label: "Cadastro de Recomendações",
        component: RecommendationRegister,
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true
    },
    {
        isVisible: true,
        path: "/AnalyseSample",
        label: "Análises",
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST, ROLE.SOILANALYSISREPORT],
        showInSidebar: true,
        icon: "Science"
    },
    {
        isVisible: true,
        label: "Configurações de Análise",
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST],
        showInSidebar: true,
        icon: "DisplaySettings",
        children: [
            {
                path: "/Interpretation",
                label: "Interpretações",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST],
                showInSidebar: true
            },
            {
                path: "/recommendation",
                label: "Itens de Recomendação",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST],
                showInSidebar: true
            },
            {
                path: "/fertilizerComposition",
                label: "Composição do Fertilizante",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST],
                showInSidebar: true
            },
        ]
    },
    {
        isVisible: true,
        label: "Estrutura Organizacional",
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
        showInSidebar: true,
        icon: "Lan",
        children: [
            {
                path: "/region",
                label: "Região",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
                showInSidebar: true
            },
            {
                path: "/branch",
                label: "Filial",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
                showInSidebar: true
            },
            {
                path: "/culture",
                label: "Cultura",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.AGRONOMIST],
                showInSidebar: true
            },
            {
                path: "/supplier",
                label: "Laboratório",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN],
                showInSidebar: true
            },
            {
                path: "/farmer",
                label: "Produtor",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.AGRONOMIST],
                showInSidebar: true
            },
            {
                path: "/agronomist",
                label: "Responsável/Agrônomo",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.FARMER, ROLE.AGRONOMIST],
                showInSidebar: true
            },
            {
                path: "/Tenant",
                label: "Empresa",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN],
                showInSidebar: true
            },
        ]
    },
    // {
    //     isVisible: true,
    //     label: "Cadastros",
    //     isPrivate: true,
    //     allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER],
    //     showInSidebar: true,
    //     icon: "ContentCopyIcon",
    //     children: [
    //         {
    //             path: "/fertilizerComposition",
    //             label: "Composição do Fertilizante",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/culture",
    //             label: "Cultura",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/Tenant",
    //             label: "Empresa",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/branch",
    //             label: "Filial",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/supplier",
    //             label: "Laboratório",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/farmer",
    //             label: "Produtor",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.FARMER],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/region",
    //             label: "Região",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/agronomist",
    //             label: "Responsável Técnico",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/recommendation",
    //             label: "Tipo de Recomendação",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //     ]
    // },
    // {
    //     isVisible: true,
    //     label: "Parâmetros de Analise",
    //     isPrivate: true,
    //     allowedRoles: [ROLE.ADMIN],
    //     showInSidebar: true,
    //     icon: "LeaderboardIcon",
    //     children: [
    //         {
    //             path: "/Interpretation",
    //             label: "Todas as interpretações",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/AnalyseType",
    //             label: "Tipo de Análise",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/parameter",
    //             label: "Parâmetro",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         },
    //         {
    //             path: "/recommendationRegister",
    //             label: "Cadastro de Recomendações",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         }
    //     ]
    // },
    {
        isVisible: true,
        label: "Laboratório",
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.SUPPLIER],
        showInSidebar: true,
        icon: "PlaylistAddCheckIcon",
        children: [
            {
                path: "/CheckIn",
                label: "Check-in",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.SUPPLIER],
                showInSidebar: true
            },
            {
                path: "/AnalyseType",
                label: "Tipo de Análise",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.SUPPLIER],
                showInSidebar: true
            },
        ]
    },
    {
        isVisible: true,
        label: "Configurações",
        isPrivate: true,
        allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER, ROLE.AGRONOMIST],
        showInSidebar: true,
        icon: "SettingsSuggest",
        children: [
            {
                path: "/user",
                label: "Usuários",
                isPrivate: true,
                allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER, ROLE.AGRONOMIST],
                showInSidebar: true
            }
        ]
    },
    // {
    //     isVisible: true,
    //     label: "Análises",
    //     isPrivate: true,
    //     allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //     showInSidebar: true,
    //     icon: "AppRegistrationIcon",
    //     children: [
    //         {
    //             path: "/AnalyseSample",
    //             label: "Análise",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT],
    //             showInSidebar: true
    //         }
    //     ]
    // },
    // {
    //     isVisible: true,
    //     label: "Configurações",
    //     isPrivate: true,
    //     allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER],
    //     showInSidebar: true,
    //     icon: "PermDataSettingIcon",
    //     children: [
    //         {
    //             path: "/user",
    //             label: "Usuário",
    //             isPrivate: true,
    //             allowedRoles: [ROLE.ADMIN, ROLE.TENANT, ROLE.SUPPLIER],
    //             showInSidebar: true
    //         }
    //     ]
    // },
];

export default RoutesList;
