import React from 'react';
import { useHistory } from 'react-router-dom';
import AnalyseSampleView from '../../components/AnalyseSampleView';

import { Container, Card } from './styles';

const AnalyseSampleViews = () => {
    const history = useHistory();

    return (
        <AnalyseSampleView/>
        
    );
}

export default AnalyseSampleViews;
