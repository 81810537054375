import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";
import { useStyles } from './styles';
import { text } from "@fortawesome/fontawesome-svg-core";

export default function ModalFormParameterCombined({ newModalCombined, setShow, lstAnalyseTypeParameterAll, ...props }) {
  const [selectedAnalyseTypeParameter, setSelectedAnalyseTypeParameter] = useState();
  const [selectedAnalyseTypeParameterCombined, setSelectedAnalyseTypeParameterCombined] = useState();
  const [analyseTypeParameter, setAnalyseTypeParameter] = useState();
  const [analyseTypeParameterCombined, setAnalyseTypeParameterCombined] = useState();
  const [parameterDescriprion, setParameterDescriprion] = useState('');
  const [parameterDescriprion1, setParameterDescriprion1] = useState();
  const [parameterDescriprion2, setParameterDescriprion2] = useState();

  const styles = useStyles();

  useEffect(() => {
    setParameterDescriprion('')
    if (lstAnalyseTypeParameterAll && lstAnalyseTypeParameterAll[0] && selectedAnalyseTypeParameter == undefined) {
      setSelectedAnalyseTypeParameter(lstAnalyseTypeParameterAll[0].analyseTypeParameterID)

      setParameterDescriprion1(lstAnalyseTypeParameterAll[0].parameter.description)
      setParameterDescriprion(lstAnalyseTypeParameterAll[0].parameter.description + ' + ' + lstAnalyseTypeParameterAll[0].parameter.description)
    }
    if (lstAnalyseTypeParameterAll && lstAnalyseTypeParameterAll[0] && selectedAnalyseTypeParameterCombined == undefined) {
      setParameterDescriprion2(lstAnalyseTypeParameterAll[0].parameter.description)
      setSelectedAnalyseTypeParameterCombined(lstAnalyseTypeParameterAll[0].analyseTypeParameterID)
    }
  }, [lstAnalyseTypeParameterAll]);

  const setParameter = async (event, type) => {
    var text1 = '';
    var text2 = '';

    if (type == 1) {
      var index = event.nativeEvent.target.selectedIndex;
      text1 = event.nativeEvent.target[index].text
      setParameterDescriprion1(text1)
    }
    else {
      var index = event.nativeEvent.target.selectedIndex;
      text2 = event.nativeEvent.target[index].text
      setParameterDescriprion2(text2)
    }
    if (type == 1) {
      setParameterDescriprion(text1 + (parameterDescriprion2 != undefined ? ' + ' + parameterDescriprion2 : ''))
    }
    else if (type == 2) {
      setParameterDescriprion((parameterDescriprion1 != undefined ? parameterDescriprion1 + ' + ' : '') + text2)
    }
  }

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Combinação de dois parâmetros</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <label className="field">Selecione o Parâmetro 1</label>
          <div className="div-form">
            <select name='analyseTypeParameters' value={analyseTypeParameter} onChange={(e) => {
              setSelectedAnalyseTypeParameterCombined(e.target.value)
              setParameter(e, 1)
            }} placeholder="Selecione o parâmetro">
              {
                lstAnalyseTypeParameterAll ?
                  lstAnalyseTypeParameterAll.map(analyseTypeParameter => {
                    return <option key={analyseTypeParameter.analyseTypeParameterID} value={analyseTypeParameter.analyseTypeParameterID}>{analyseTypeParameter.parameter.description}</option>
                  })
                  :
                  <></>
              }
            </select>
          </div>
          <label className="field">Selecione o Parâmetro 2</label>
          <div className="div-form">
            <select name='analyseTypeParameterCombineds' value={analyseTypeParameterCombined} onChange={(e) => {
              setSelectedAnalyseTypeParameter(e.target.value)
              setParameter(e, 2)
            }} placeholder="Selecione o parâmetro">
              {
                lstAnalyseTypeParameterAll ?
                  lstAnalyseTypeParameterAll.map(analyseTypeParameter => {
                    return <option key={analyseTypeParameter.analyseTypeParameterID} value={analyseTypeParameter.analyseTypeParameterID}>{analyseTypeParameter.parameter.description}</option>
                  })
                  :
                  <></>
              }
            </select>
          </div>
          <label className="field">Descrição dos Parâmetros</label>
          <div className="div-form">
            <input onChange={(t) => { setParameterDescriprion(t.target.value) }} id="description" className={styles.input} value={parameterDescriprion}></input>
          </div>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setParameterDescriprion('')
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => {
              newModalCombined(selectedAnalyseTypeParameter, selectedAnalyseTypeParameterCombined, parameterDescriprion)
              setParameterDescriprion('')
              setShow(false)
            }} >Confirmar</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}