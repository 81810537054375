import React from 'react';
import { useHistory } from 'react-router-dom';
import AnalyseSampleDetailsInterpretationRecommendation from '../../components/AnalyseSampleDetailItemInterpretationRecommendation';

import { Container, Card } from './styles';

const AnalyseSampleDetailsInterpretationRecommendations = () => {
    const history = useHistory();

    return (
        <AnalyseSampleDetailsInterpretationRecommendation/>
        
    );
}

export default AnalyseSampleDetailsInterpretationRecommendations;
