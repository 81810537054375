/* eslint-disable import/no-unresolved */
import { combineReducers, legacy_createStore as createStore } from "redux";

import recomendationReportData from '../store/recomendationReportStore';


const rootReducer = combineReducers({
    recomendationReportData,
});

const store = createStore(rootReducer);

export default store;
