import React, { useEffect, useState, forwardRef } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Box, Container, InputBase, InputAdornment, IconButton } from '@material-ui/core';
import { ContainerHeader, Table, useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import SearchIcon from '@mui/icons-material/Search';
import DateRangeIcon from '@mui/icons-material/DateRange';

import Button from '../Button';
import TableLoading from '../TableLoading';


const TableCrud = ({ columns, table, title, canDelete = true }) => {
    const history = useHistory();
    const styles = useStyles();
    const [data, setData] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];
            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                if (table === "user") {
                    return "Global";
                } else {
                    return "";
                }
            }
        }
        return currentObject;
    }


    const InputDate = forwardRef(({ value, onClick }, ref) => (
        <InputBase ref={ref} className={styles.search} placeholder={"Período"} style={{ background: "white", marginLeft: 30, paddingRight: 20 }} value={value} onClick={onClick}
            startAdornment={
                <InputAdornment position="start">
                    <DateRangeIcon htmlColor='#5A646E' />
                </InputAdornment>
            }
        />
    ));

    const loadTable = async (page = 1, skip = 10, callback) => {
        Api.get(`${table}/GetAll?page=${page}&&skip=${skip}&&SearchText=${searchText}
                &&startDate=${startDate ? moment(startDate).format("MM/DD/yyyy") : ""}            
                &&endDate=${endDate ? moment(endDate).format("MM/DD/yyyy") : ""}`).then((result) => {
            if (result && result.data && result.data.response) {
                const options = result.data.response

                setPage(page);
                setPages(result.data.response.pages)

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const deleteItem = (path, id) => {
        Swal.fire({
            title: 'Tem certeza de que deseja excluir o registro?',
            text: "Você não será capaz de reverter isso!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonColor: '#0c8662',
            cancelButtonColor: '#ec632c',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`/${path}/delete?id=${id}`).then((result) => {
                    if (result && result.data) {
                        loadTable(1, 10, (response) => {
                            setTotalRows(response.recordsTotal)
                            setData(response.data)
                            setIsLoading(false)
                        })
                    }
                });
            }
        })
    }


    useEffect(() => {
        loadTable(1, 10, (response) => {
            setTotalRows(response.recordsTotal)
            setData(response.data ? response.data : null)
            setIsLoading(false)
        })
    }, []);

    return (
        <div className="container">
            <ContainerHeader>
                <h3>{title}</h3>
                <div>
                    {
                        <Button style={{ marginRight: '15px' }} onClick={() => history.push(`/${table}/new`)}>Adicionar</Button>
                    }
                </div>
            </ContainerHeader>
            {
                data ?
                    <div >
                        <Box className={styles.container} >
                            <Box className={styles.block1}>
                                <InputBase className={styles.search} value={searchText} placeholder={"Buscar..."} style={{ background: "white" }} onChange={(e) => {
                                    setSearchText(e.target.value)
                                }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon htmlColor='#5A646E' />
                                        </InputAdornment>
                                    }
                                />

                                <Box style={{ width: "auto", visibility: table === 'user' ? 'hidden' : 'none' }}  >
                                    <DatePicker
                                        locale={"ptBR"}
                                        dateFormat={"dd/MM/yyyy"}
                                        customInput={<InputDate />}
                                        selectsRange
                                        startDate={startDate}
                                        endDate={endDate}
                                        selected={startDate}
                                        onChange={(dates) => {
                                            const [start, end] = dates;
                                            setStartDate(start);
                                            setEndDate(end);
                                        }}
                                        isClearable={true}
                                        visibility={table == 'user' ? false : true}
                                    />
                                </Box>


                                <Button className={styles.button} style={{ marginRight: '15px', marginLeft: "auto" }}
                                    onClick={() => {
                                        setIsLoading(true)
                                        loadTable(1, perPage, (response) => {
                                            setTotalRows(response.recordsTotal)
                                            setData(response.data)
                                            setIsLoading(false)
                                        })
                                    }}
                                >
                                    Buscar
                                </Button>
                            </Box>
                            <Table>
                                <thead >
                                    <tr>
                                        {
                                            columns.map((c, i) => {
                                                if (c.visible == undefined || c.visible == true) {
                                                    return <th key={i + "th"}>{c.label}</th>
                                                }
                                            })
                                        }
                                        <th width="1%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.length > 0 ?
                                            data.map((d, i) => {
                                                return <tr key={i + "tr"}>
                                                    {
                                                        columns.map((c, x) => {
                                                            if (c.visible == undefined || c.visible == true) {

                                                                if (typeof (d[c.name]) === "boolean") {
                                                                    if (c.name == "isDeleted") {
                                                                        return <td key={x}>
                                                                            {
                                                                                d[c.name] === true ?
                                                                                    <FaTimes color="#ff0000" size={14} />
                                                                                    :
                                                                                    <FaCheck color="#0c8662" size={14} />
                                                                            }
                                                                        </td>
                                                                    } else {
                                                                        return <td key={x}>
                                                                            {
                                                                                d[c.name] === true ?
                                                                                    <FaCheck color="#0c8662" size={14} />
                                                                                    :
                                                                                    <FaTimes color="#ff0000" size={14} />
                                                                            }
                                                                        </td>
                                                                    }
                                                                }
                                                                else if (c.type === "date") {
                                                                    return <td key={x}>{moment(d[c.name]).format("DD-MMM-yyyy")}</td>
                                                                }
                                                                else {
                                                                    if (c.name.indexOf(".") > -1) {
                                                                        var fragments = c.name.split('.');

                                                                        var object = getObjects(d, fragments);

                                                                        if (table === "user") {
                                                                            return <td key={x}>{(object != null ? object : "Global")}</td>
                                                                        } else {
                                                                            return <td key={x}>{(object != null ? object : "")}</td>
                                                                        }
                                                                    } else {
                                                                        return <td key={x}>{d[c.name]}</td>
                                                                    }
                                                                }
                                                            }
                                                            var teste = `/${table}/edit/${d[(table === "user" ? "id" : table + "ID")]}`;

                                                        })
                                                    }
                                                    <td>
                                                        {
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <FiMoreVertical size={16} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {
                                                                        <Dropdown.Item onClick={() => history.push(`/${table}/edit/${d[(table === "user" ? "id" : table + "ID")]}`)}>Editar</Dropdown.Item>
                                                                    }
                                                                    { canDelete ? <Dropdown.Item  onClick={() => deleteItem(table, d[(table === "user" ? "id" : table + "ID")])}>Deletar</Dropdown.Item> : "" }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr><td className="no-records" colSpan={columns.length + 1}>sem registros encontrados</td></tr>
                                    }
                                </tbody>

                            </Table>

                            <div className={styles.divlabel} >
                                <button
                                    className="border rounded p-1" style={{ marginRight: '5px' }}
                                    onClick={() => {
                                        loadTable(1, perPage, (response) => {
                                            setIsLoading(true)
                                            setTotalRows(response.recordsTotal)
                                            setData(response.data)
                                            setIsLoading(false)
                                        })
                                    }}
                                >
                                    {'<<'}
                                </button>
                                <button
                                    className="border rounded p-1" style={{ marginRight: '5px' }}
                                    onClick={() => {
                                        if (page > 1) {
                                            loadTable(page - 1, perPage, (response) => {
                                                setIsLoading(true)
                                                setTotalRows(response.recordsTotal)
                                                setData(response.data)
                                                setIsLoading(false)
                                            })
                                        }
                                    }}
                                >
                                    {'<'}
                                </button>
                                <button
                                    className="border rounded p-1" style={{ marginRight: '5px' }}
                                    onClick={() => {
                                        if (page < pages) {
                                            loadTable(page + 1, perPage, (response) => {
                                                setIsLoading(true)
                                                setTotalRows(response.recordsTotal)
                                                setData(response.data)
                                                setIsLoading(false)
                                            })
                                        }
                                    }}
                                >
                                    {'>'}
                                </button>
                                <button
                                    className="border rounded p-1" style={{ marginRight: '5px' }}
                                    onClick={() => {
                                        loadTable(pages, perPage, (response) => {
                                            setIsLoading(true)
                                            setTotalRows(response.recordsTotal)
                                            setData(response.data)
                                            setIsLoading(false)
                                        })
                                    }}
                                >
                                    {'>>'}
                                </button>
                                <span className={styles.container} style={{ marginRight: '5px' }}>
                                    <div>Página(s)
                                        <strong style={{ marginLeft: '5px' }}>
                                            {page} de {' '}
                                            {pages}
                                        </strong>
                                    </div>

                                </span>
                                <select
                                    className={styles.container}
                                    onChange={e => {
                                        setPerPage(Number(e.target.value))
                                        loadTable(1, Number(e.target.value), (response) => {
                                            setIsLoading(true)
                                            setTotalRows(response.recordsTotal)
                                            setData(response.data)
                                            setIsLoading(false)
                                        })
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Mostrar {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Box>
                    </div >
                    :
                    <TableLoading />
            }
        </div >
    );
}

export default TableCrud;
