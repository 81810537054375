import React from 'react';
import { useHistory } from 'react-router-dom';
import AnalyseSampleList from '../../components/AnalyseSampleList';

import { Container, Card } from './styles';

const AnalyseSample = () => {
    const history = useHistory();

    return (
            <AnalyseSampleList/>
    );
}

export default AnalyseSample;
