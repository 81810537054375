import React from 'react';
import { useHistory } from 'react-router-dom';
import AnalyseTypeForm from '../../components/AnalyseTypeForm';

import { Container, Card } from './styles';

const AnalyseTypeForms = () => {
    const history = useHistory();

    return (
        <AnalyseTypeForm/>
        
    );
}

export default AnalyseTypeForms;
