import React, { useState, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

//Icon
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import SpaOutlinedIconIcon from '@material-ui/icons/SpaOutlined';
import EmojiNatureOutlinedIcon from '@material-ui/icons/EmojiNatureOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AcUnit from '@material-ui/icons/AcUnit';
import Animation from '@mui/icons-material/Animation';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import { Circle, DisplaySettings, Hub, Lan, Science, SettingsSuggest } from "@mui/icons-material";

const MenuItem = ({ item, setActive }) => {
    const history = useHistory();
    const [isSubMenuShow, setIsSubMenuShow] = useState(false);

    return (
        <div>
            <div onClick={() => setIsSubMenuShow(!isSubMenuShow)}>
                <li key={item.label} onClick={() => { history.push(item.path) }}>
                    {item.icon === 'HomeOutlinedIcon' ? (<HomeOutlinedIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'ReceiptOutlinedIcon' ? (<ReceiptOutlinedIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'SpaOutlinedIcon' ? (<SpaOutlinedIconIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'EmojiNatureOutlined' ? (<EmojiNatureOutlinedIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'ListAltOutline' ? (<ListAltOutlinedIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'SettingsOutlined' ? (<SettingsOutlinedIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'ContentCopyIcon' ? (<ContentCopyIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'AcUnit' ? (<AcUnit style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'Animation' ? (<Animation style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'AppRegistrationIcon' ? (<AppRegistrationIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'AutoStoriesIcon' ? (<AutoStoriesIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'LeaderboardIcon' ? (<LeaderboardIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'PlaylistAddCheckIcon' ? (<PlaylistAddCheckIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === 'PermDataSettingIcon' ? (<PermDataSettingIcon style={{ "marginLeft": "25px", "color": "white[500]" }} />) : ""}
                    {item.icon === "Science" ? (<Science style={{"marginLeft": "25px", "color": "white[500]" }}/>) : ""}
                    {item.icon === "DisplaySettings" ? (<DisplaySettings style={{"marginLeft": "25px", "color": "white[500]" }}/>) : ""}
                    {item.icon === "Hub" ? (<Hub style={{"marginLeft": "25px", "color": "white[500]" }}/>) : ""}
                    {item.icon === "Lan" ? (<Lan style={{"marginLeft": "25px", "color": "white[500]" }}/>) : ""}
                    {item.icon === "SettingsSuggest" ? (<SettingsSuggest style={{"marginLeft": "25px", "color": "white[500]" }}/>) : ""}
                    <label>
                        {item.label}
                    </label>
                </li>
            </div>
            {item.children && isSubMenuShow && <SubMenu dropDownItem={item.children} setActive={setActive}/>}
        </div>
    );
};

const SubMenu = ({ dropDownItem, setActive }) => {
    const historyItem = useHistory();
    const { userRoles } = useAuth();

    const sidebarMenuItems = useMemo(() => {
        return dropDownItem.filter(item => item.showInSidebar
            && (item.allowedRoles == null || item.allowedRoles?.some(role => userRoles?.includes(role))));
    }, [userRoles]);

    return (
        <div>
            {sidebarMenuItems.map((item) => {
                return <li key={item.label} onClick={() => { setActive(false); historyItem.push(item.path) }}>
                    <label style={{"font-size": "13px", "marginLeft":"30px"}}>
                        <Circle style={{ "width" : "5px", "marginRight": "5px"}}/> {item.label}
                    </label>
                </li>;
            })}
        </div>
    );
};

export default MenuItem;
