/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
import React from 'react'

import { Rings } from 'react-loader-spinner';

import loading from '../../components/LoadingEvents/loading.css';

function Loading() {
    return (
        <div className="loader-container">
            <div className="outer">
                <div className="inner">
                    <Rings  
                        color="#198754"
                        height="150"
                        width="150"
                        radius="2"
                        ariaLabel='loading'
                    />
                </div>
            </div>
        </div>
    )
}

export default Loading