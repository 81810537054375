import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './styles/global';
import AppProvider from './hooks/index';
import Routes from './routes';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import store from './store';

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppProvider>
                    <Routes />
                </AppProvider>
                <GlobalStyle />
            </BrowserRouter>
        </Provider>
    );
}

export default App;
