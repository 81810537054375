import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const ParameterForm = () => {
    const [SupplierOptions, setSupplierOptions] = useState([]);

    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const fields = [
        {
            label: "Descrição",
            name: "description",
            type: "input",
        },
        {
            label: "Laborátorio",
            name: "supplierID",
            type: "select",
            options: SupplierOptions
        },
        {
            label: "Ativo",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ]

    const loadData = async () => {
        Api.get('Supplier/getAllItems').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.supplierID, text: item.description }));

                setSupplierOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        SupplierOptions.length > 0 ? <FormCrud formName={"parameter"} title={"Parâmetro"} fields={fields} /> : <></>
    );
}

export default ParameterForm;
