import React from "react";
import { makeStyles } from '@material-ui/styles';


export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonParameter: {
    textTransform: "none",
    backgroundColor: "transparent",
    "&:hover, &:focus": {
      backgroundColor: "transparent",
      color: "#0c8662",
      textDecoration: "underline"
    },
  },
  boxParameter: {
    border: "1px solid #F2F5F8",
    color: "#737D86",
    height: "60px",
    backgroundColor: "transparent",
    "&:hover, &:focus": {
      backgroundColor: "#AAD6C2"
    }
  },
  pagewhite: {
    marginTop: "25px",
    padding: "20px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
  },
  containerGround: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFFFF",
    width: "85%",
  },
  block2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    width: "72%",
  },
  block1: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 10,
    width: "90%",
  },
  block: {
    marginTop: "2%",
    display: "flex",
    justifyContent: "center",
  },
  tableLabelsHeader: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 14,
    color: "#161C24"
  },
  tableLabels: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 14,
    color: "#737D86",
    cursor: "pointer"
  },
  tableRows: {
    display: "flex",
    height: 44,
    background: "#F2F5F8",
    marginTop: 10,
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer"
  },
  border: {
    width: "100%",
    marginTop: "20px",
    height: "0px",
    border: "1px solid #E9EBEE",
  },
  button: {
    background: "#0c8662",
    borderRadius: 8,
    width: 250,
    height: 30,
    fontFamily: "Montserrat",
    color: "white",
    fontSize: 14,
    textTransform: "none",
    marginBottom: 50,
  },
  fieldResponsible: {
    width: "30%",
    marginTop: "2px",
    height: "40px",
    borderRadius: "8px",
    outline: "none",
    padding: "15px 0 5 10px",
    color: "#000000",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 30
  },
  tableRows: {
    display: "flex",
    height: 44,
    background: "#F2F5F8",
    marginTop: 10,
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer"
  },
  inputExpand: {
    height: "35px",
    borderRadius: "8px",
    border: "1px solid #ACB4BA",
    outline: "none",
    padding: "10px"
  },
  input: {
    marginTop: "2px",
    height: "40px",
    width: "20%",
    borderRadius: "8px",
    border: "1px solid #ACB4BA",
    outline: "none",
    padding: "10px"
  },
  inputDescription: {
    marginTop: "2px",
    height: "40px",
    width: "20%",
    borderWidth: "0 0 2px",
    border: "1px solid #CDD5DF",
    outline: "none",
    padding: "10px"
  },
  labelweight: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    alignItems: "center",
    marginLeft: 10,
    color: "#FFFFFF",
  },
  buttonWeight: {
    marginTop: "10px",
    width: "10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    height: "35px",
    background: "#0c8662",
    borderRadius: "8px",
  },
  buttonWhite: {
    marginTop: "10px",
    width: "30%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    height: "35px",
    background: "#F2F5F8",
    borderRadius: "8px",
  },
  labelBlack: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    alignItems: "center",
    marginLeft: 10,
    color: "#000000",
  },
  buttonAnalyse: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "45px",
    background: "#FFFFFF",
    borderRadius: "1px",
    border: "1px solid #D0D0D0"
  },
  buttonAnalyseColor: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "45px",
    background: "#E5FDE1",
    borderRadius: "1px",
    border: "1px solid #D0D0D0"
  },
  search: {
    border: "1px solid rgba(145, 158, 171, 0.32)",
    borderRadius: 5,
    height: 36,
    alignItems: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 14,
  },
  buttonBlue: {
    marginTop: "10px",
    width: "15%",
    display: "flex",
    flexDirection: "row",
    height: "35px",
    background: "#0047FF",
    borderRadius: "8px",
  },
  tableLabelsHeader: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 14,
    color: "#161C24"
  },
});
