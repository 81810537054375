import React from 'react';
import TableCrud from '../../components/TableCrud';

const ParameterIndex = () => {
    const columns = [
        {
            label: "Descrição",
            name: "description"
        },
        {
            label: "Laboratório",
            name: "supplier.description"
        },
        {
            label: "Ativo",
            name: "isActive"
        },
    ]

    return (
        <TableCrud title={"Parâmetro"} table={"parameter"} columns={columns}/>
    );
}

export default ParameterIndex;
