import React, { useState, forwardRef } from "react";
import { Box, Button } from '@material-ui/core';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import MenuIcon from '../../assets/icons/menu.png';



export default function MenuTable({ id, editFunction, deleteFunction, fertilizerComposition, cloneTemplate,  printFunction, isPrint, isFertilizerComposition, isClone, textPrint }) {

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      style={{ borderRadius: 500 }}
      ref={ref}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }}
    >
      <img src={MenuIcon} />
      {children}
    </Button>
  ));


  return (
    <Box style={{ width: "5%", display: "flex" }}>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        </Dropdown.Toggle>
        <Dropdown.Menu >
          {
            <Dropdown.Item onClick={(e) => {
              e.stopPropagation();
              editFunction(id)
            }}
            >
              Editar
            </Dropdown.Item>
          }
          {
            isPrint ?
              <Dropdown.Item onClick={(e) => {
                e.stopPropagation();
                printFunction(id)
              }}
              >
                {
                  textPrint ?
                    textPrint
                    :
                    'Visualizar'
                }

              </Dropdown.Item>
              :
              <></>
          }
          {
            isFertilizerComposition ?
              <Dropdown.Item onClick={(e) => {
                e.stopPropagation();
                fertilizerComposition(id)
              }}
              >
                Configuração do NPK
              </Dropdown.Item>
              :
              <></>
          }
           {
             isClone ?
             <Dropdown.Item onClick={(e) => {
               e.stopPropagation();
               cloneTemplate(id)
             }}
             >
               Clonar Template
             </Dropdown.Item>
             :
             <></>
          }
          {
            <Dropdown.Item onClick={(e) => {
              e.stopPropagation();
              deleteFunction(id)
            }}
            >
              Remover
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </Box>
  );
}

