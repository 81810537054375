import styled from 'styled-components';

export const Container = styled.div`

    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #161C24;
        margin-top: 15px;
        margin-left: 8px;
    }

    .field{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 570;
        font-size: 14px;
        line-height: 125%;
        color: #000000;
        margin-top: 15px;
        margin-left: 20px;
    }
    
    .div-list{
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .name:{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
        }
    }

    .div-form{
        margin-left: 20px;
        margin-right: 20px;


        .button-add{
            background-color: #0c8662;
            border-radius: 8px;
            margin-top: 10px;
            margin-left: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 12px 5px 8px;
            width: auto;
            height: 44px;
            color: #FFFFFF;
        }



        input{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 100%;
        }

        select{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 100%;
        }

        


    }

    .div-form-button{
        margin-left: 20px;
        margin-right: 20px;
        justify-content: space-between;


        .button-add{
            background-color: #0c8662;
            border-radius: 8px;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 12px 5px 8px;
            width: 25%;
            height: 44px;
            color: #FFFFFF;
        }



        input{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 70%;
        }

        select{
            margin-top: 10px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #ec632c;
            outline: none;
            padding: 10px;
            text-align: left;
            width: 70%;
        }


    }

    

`;

export const Footer = styled.div`
    display: flex;
    flex: 1;
    width: auto;
    justify-content: space-between;
    align-items: center;

    .div-footer{
        display:flex;
        justify-content: space-around;
        align-items: start;
        flex: 1;
        width: auto;
        margin-left: 7px;
        margin-right: 7px;
    
        .file-name {
            max-width: 200px; /* Defina o valor desejado para a largura máxima */
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; /* Adiciona "..." se o texto for muito longo */
          }

        .button-select{
            background-color: #0c8662;
            border-radius: 8px;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 44px;
            color: #FFFFFF;
            padding: 5px 12px 5px 8px;
        }

        .button-cancel{
            background-color: #ec632c;
            border-radius: 8px;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 44px;
            color: #FFFFFF;
            padding: 5px 12px 5px 8px;
        }

        .button-confirm{
            background-color: #0c8662;
            border-radius: 8px;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 12px 5px 8px;
            width: 200px;
            height: 44px;
            color: #FFFFFF;
        }

        .input{
            marginTop: 2px;
            height: 40px;
            width: "20%";
            borderRadius: 8px;
            border: 1px solid #ACB4BA;
            outline: none;
            padding: 10px;
        }
}
`
