import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function ModalFormImportAnalyseTypes({ newModalImport, setShow, analyseTypes, ...props }) {
  const [selectedAnalyseType, setSelectedAnalyseType] = useState();
  const [analyseType, setAnalyseType] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);

  const inputFile = useRef(null);

  useEffect(() => {
    if (analyseTypes && analyseTypes[0] && selectedAnalyseType == undefined) {
      setSelectedAnalyseType(analyseTypes[0].analyseTypeID)
    }
  }, [analyseTypes]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const onFilechange = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setSelectedFiles(e.target.files);
}

  const handleDownload = () => {
    // Crie um link de download
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/exemplo_planilha.xlsx';
    link.download = 'exemplo_planilha.xlsx';  // Nome do arquivo que será baixado

    // Simule um clique no link para iniciar o download
    link.click();
  };

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Importar planilha</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <Footer>
            <div className="row div-footer">
              <div className="d-flex align-items-center">
                <button
                  className="btn button-select"
                  style={{ backgroundColor: "#ec632c", width: 200, marginRight: "2%" }}
                  onClick={handleDownload}
                >
                  <ArrowDownwardIcon htmlColor="white" />
                  <label style={{ height: "auto", cursor: "pointer" }}>Exemplo planilha</label>
                </button>
              </div>
            </div>
          </Footer>

          <label className="field">Análises</label>
          <div className="div-form">
            <select name='analyseTypes' value={analyseType} onChange={(e) => {
              setSelectedAnalyseType(e.target.value)
            }}>
              {
                analyseTypes ?
                  analyseTypes.map(analyseType => {
                    return <option key={analyseType.analyseTypeID} value={analyseType.analyseTypeID}>{analyseType.description}</option>
                  })
                  :
                  <></>
              }
            </select>
          </div>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <div className="d-flex align-items-center">
              <button className="btn button-select" style={{ marginRight: '10px' }} onClick={() => inputFile.current.click()}>Selecionar</button>

              {selectedFile && <span className="file-name">{selectedFile.name}</span>}

              <input
                type='file'
                id='file'
                ref={inputFile}
                style={{ display: 'none' }}
                accept=".xlsx"
                onChange={(t) => { onFilechange(t) }}
              />
            </div>

            <div className="button-group d-flex align-items-center">
              <div>
                <button className="btn button-cancel" onClick={() => setShow(false)} style={{ marginRight: '10px' }}>Cancelar</button>
              </div>
              <div>
                <button className="btn button-confirm" onClick={() => newModalImport(selectedAnalyseType, selectedFiles)}>Confirmar</button>
              </div>
            </div>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}