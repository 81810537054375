import React, { useState, forwardRef } from "react";
import { Box } from '@material-ui/core';
import { ImClipboard, ImBin, ImFileText2  } from "react-icons/im";

import { Container } from './styles';
import { Height } from "@material-ui/icons";
export default function MenuTable({ id, editFunction, deleteFunction, editInterpretationAnalyse }) {

  return (
    <Box style={{ display: "flex", border:1 }}>
      <Container type="button" onClick={() => editFunction(id)} title={'Resultados'}><ImClipboard size={20}/></Container>
      <Container type="button" onClick={() => editInterpretationAnalyse(id)} title={'Interpretação'}><ImFileText2 size={20}/></Container>      
      <Container type="button" onClick={() => deleteFunction(id)} title={'Deletar'}><ImBin size={20}/></Container>

    </Box>
  );
}

