import React from 'react';
import TableCrud from '../../components/TableCrud';

const UserIndex = () => {
    const columns = [
        {
            label: "Nome do usuário",
            name: "userName"
        },
        {
            label: "Nome completo",
            name: "fullName"
        },
        {
            label: "Empresa",
            name: "tenant.name"
        },
        {
            label: "E-mail",
            name: "email"
        },
        {
            label: "Permissão web",
            name: "isWeb"
        },
        {
            label: "Permissão aplicativo",
            name: "isMobile"
        },
        {
            label: "Função",
            name: "roleName"
        }
    ]

    return (
        <TableCrud title={"Usuário"} table={"user"} columns={columns}/>
    );
}

export default UserIndex;
