import React, { useEffect, useState, useRef, forwardRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box, InputBase, InputAdornment, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import Code from '../../assets/icons/code.png';
import { error_message, success_message } from '../Toast/index.jsx';
import binRed from '../../assets/icons/binRed.png';
import read from '../../assets/icons/read.png';
import entry from '../../assets/icons/entry.png';
import ModalFormCheckIn from '../ModalFormCheckIn';
import TableLoading from '../TableLoading';
import moment from 'moment';

const CheckInList = () => {
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState("");
    const [lstCodes, setLstCodes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [lstConsultAnalyseSamples, setLstConsultAnalyseSamples] = useState([]);

    useEffect(() => {
    }, []);

    const ConsultAnalyseSampleApi = async (callback) => {
        setIsLoading(true)
        api.post('AnalyseSample/ConsultAnalyseSampleAPI?code=' + code).then((result) => {
            if (result.data.statusCode == 200) {
                var isAdd = true;
                lstCodes.forEach(function (AnalyseSample) {
                    if (AnalyseSample.analyseSampleID == result.data.response.item3.analyseSampleID) {
                        if (AnalyseSample.isDeleted == false) {
                            isAdd = false;
                        }
                    }
                });
                if (isAdd) {
                    result.data.response.item3.dateCheckin =  moment().format('YYYY-MM-DD HH:mm:ss');
                    lstCodes.push(result.data.response.item3);
                }
                else {
                    error_message("Já adicionado")
                }

                setLstCodes(lstCodes)

            }
            else {
                error_message(result.data.response)
            }
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
            exceptionNotificationAPI(error);
            setIsLoading(false)
        });
    }

    const getAnalyseSampleApi = async () => {
        setIsLoading(true)
        setLstConsultAnalyseSamples([])
        api.get(`AnalyseSample/getConsultAnalyseSampleApi?SearchText=${''}`).then((result) => {
          if (result && result.data && result.data.response) {
            setLstConsultAnalyseSamples(result.data.response.data);
            setIsLoading(false)
            setShowModal(true);
          }
        }).catch((error) => {
          exceptionNotificationAPI(error);    
        });
      }

    const removeAnalyseSample = async (_AnalyseSample) => {
        setIsLoading(true)
        await lstCodes.forEach(function (AnalyseSample) {
            if (AnalyseSample.analyseSampleID == _AnalyseSample.analyseSampleID) {
                AnalyseSample.isDeleted = true;
            }
        });
        setIsLoading(false)
    }

    const newModal = async (lstConsultAnalyseSamples) => {
        lstConsultAnalyseSamples.forEach(function (analyseSample) {
            analyseSample.dateCheckin =  moment().format('YYYY-MM-DD HH:mm:ss');
            if (analyseSample.checked !== undefined && analyseSample.checked) {
                var isAdd = true;
                lstCodes.forEach(function (code) {
                    if (code.analyseSampleID == analyseSample.analyseSampleID && code.isDeleted == false) {
                        isAdd = false;
                        code.isDeleted = false;
                        code.dateCheckin =  moment().format('YYYY-MM-DD HH:mm:ss');
                    }
                });
                if (isAdd) {
                    lstCodes.push(analyseSample);
                }
            }
        });
    }

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const updateStatus = async () => {
        setIsLoading(true)
        api.post('AnalyseSample/UpdateStatus', [...lstCodes]).then((result) => {
            if (result.data.response != null) {
                setLstCodes([]);
                success_message("Atualizado com sucesso!")
            }
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
            exceptionNotificationAPI(error);
        });
    }

    return (
        <Box className={styles.container} >
            <Box className={styles.block1} >
                <button className='btn' onClick={() => {
                    history.goBack();
                }}>
                    <img src={ArrowLeft} className='icon' />
                </button>
                <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Check-in de Amostras"}</label>
            </Box>
            <Box className={styles.block1} >
                <div className={styles.border} />
            </Box>
            <Box className={styles.block} style={{ marginTop: "20px", marginLeft: "8%", justifyContent: "flex-start" }}>
                <label className={styles.tableLabelsHeader} style={{ width: "40%" }}>Código</label>
            </Box>
            <Box className={styles.block} style={{ position: "relative", marginTop: "20px", marginLeft: "8%", justifyContent: "flex-start" }}>
                <input id={"inputID"} type="text" onChange={(t) => { setCode(t.target.value) }} onKeyPress={event => {
                    if (event.key === 'Enter') {
                        ConsultAnalyseSampleApi()
                    }
                }} className={styles.input} style={{ width: "35%", maxLength: "11" }}></input>
                <img src={Code} style={{ position: "absolute", marginLeft: "33%", width: "1.5%", height: "55%", marginTop: "10px" }} />

                <Button style={{ marginLeft: "5%" }} className={styles.button} onClick={() => {
                    getAnalyseSampleApi(true)
                }}>
                    <img src={read} className='icon' />
                    <label className={styles.labelweight}>Listagem Completa</label>
                </Button>
            </Box>

            <Box className={styles.block} style={{ marginTop: "20px", marginLeft: "8%", justifyContent: "flex-start" }}>
                <label className={styles.tableLabelsHeader} style={{ width: "20%" }}>Código</label>
                <label className={styles.tableLabelsHeader} style={{ width: "20%" }}>Amostra</label>
                <label className={styles.tableLabelsHeader} style={{ width: "20%" }}>Empresa</label>
                <label className={styles.tableLabelsHeader} style={{ width: "30%" }}>produtor</label>
            </Box>
            {
                lstCodes ? lstCodes.filter(function (element) { return element.isDeleted == false; }).map(e => {
                    return (
                        <Box key={e.analyseSampleID} className={styles.block} style={{ marginTop: "20px", marginLeft: "8%", justifyContent: "flex-start" }}>
                            <label className={styles.tableLabels} style={{ width: "20%" }}>{e.externalCode}</label>
                            <label className={styles.tableLabels} style={{ width: "20%" }}>{e.sampleNumber}</label>
                            <label className={styles.tableLabels} style={{ width: "20%" }}>{e.tenant.name}</label>
                            <label className={styles.tableLabels} style={{ width: "30%" }}>{e.farmerCultureRegion.farmer.name}</label>
                            <Box style={{ width: "2%" }}>
                                <button className='btn' onClick={() => {
                                    removeAnalyseSample(e)
                                }}>
                                    <img src={binRed} className='icon' />
                                </button>
                            </Box>
                        </Box>
                    )
                })
                    :
                    <></>
            }
            <Box style={{ display: "flex", justifyContent: "flex-end", width: "82%" }} >
                <Button className={styles.buttonBlue} onClick={() => {
                    updateStatus()
                }}>
                    <img src={entry} className='icon' />
                    <label className={styles.labelweight} style={{ color: "#FFFFFF" }}>Registrar CheckIn</label>
                </Button>
            </Box>
            <ModalFormCheckIn show={showModal} setShow={setShowModal} newModal={newModal} _lstConsultAnalyseSamples={lstConsultAnalyseSamples} />
        </Box >
    );
}

export default CheckInList;
