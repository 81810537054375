import React, { useEffect, useState, useRef, forwardRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { useStyles } from './styles';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import TableLoading from '../TableLoading';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import { error_message, success_message } from '../Toast/index.jsx';
import { Box, Grid, Button } from '@material-ui/core';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}


const AnalyseTypeForm = () => {
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [externalCode, setExternalCode] = useState("");
    const [externalCodeSent, setExternalCodeSent] = useState("");
    const [analyseTypeReturn, setAnalyseTypeReturn] = useState([]);
    const { id } = useParams();
    const activeOptions = [
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
    ];
    const [isActive, setIsActive] = useState();
    const [tenants, setTenants] = useState();
    const [selectedTenant, setSelectedTenant] = useState();
    
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };



    useEffect(() => {
        let cancel = false;
        setIsLoading(true)
        analyseType((options) => {
            if (cancel) return
            if (options) {

                loadTenants((dataTenant) => {
                    setTenants(dataTenant)
                    if (options && options != undefined && options.tenantID != null && options.tenantID != '00000000-0000-0000-0000-000000000000') {
                        setSelectedTenant(options.tenantID)
                    }
                    else {
                        setSelectedTenant(dataTenant[0].tenantID)
                    }
                    setAnalyseTypeReturn(options)
                    setDescription(options.description)
                    setExternalCode(options.externalCode)
                    setExternalCodeSent(options.externalCodeSent)

                    setLeft(options.listAnalyseTypeParameterNotAddDto)
                    setRight(options.listAnalyseTypeParameterAddDto)
                    setIsLoading(false)
                })
            }

        });

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);


    const analyseType = async (callback) => {
        api.get(`AnalyseType/Get?id=${(id != undefined ? id : "")}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const endAnalyseType = async () => {
        setIsLoading(true)

        if (description == '' || description == undefined) {
            error_message("informe a descrição")
        }
        else if (externalCode == '' || externalCode == undefined) {
            error_message("informe o código da análise")
        }
        else {
            try {
                analyseTypeReturn.description = description;
                analyseTypeReturn.externalCode = externalCode;
                analyseTypeReturn.externalCodeSent = externalCodeSent;
                analyseTypeReturn.tenantID = selectedTenant;
                analyseTypeReturn.listAnalyseTypeParameterAddDto = right;
                analyseTypeReturn.listAnalyseTypeParameterNotAddDto = right;

                const result = await api.post(`/AnalyseType/Save`, analyseTypeReturn);
                if (result.data.response == true) {
                    success_message("Tipo de Análise salva com sucesso!")
                    history.goBack();
                }

            } catch (error) {
                setIsLoading(false)
                exceptionNotificationAPI(error);

            }
        }
        setIsLoading(false)
    }

    const loadTenants = async (callback) => {
        api.get(`Tenant/GetAllItemsSupplier`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'todos os itens selecionados',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionado`}
            />
            <Divider />
            <List
                sx={{
                    width: 400,
                    height: 500,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.parameter.description}`} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    return (
        <>
            {
                <Box className={styles.container}>
                    <Box className={styles.block1}>
                        <button className='btn' onClick={() => {
                            history.goBack();
                        }}>
                            <img src={ArrowLeft} className='icon' />
                        </button>
                        <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Novo tipo de Análise"}</label>
                    </Box>
                    <Box className={styles.block} >
                        <div className={styles.border} />
                    </Box>
                    <Grid style={{ marginTop: "20px" }} className={styles.block} container spacing={5}>
                        <Grid item xs={3}>
                            <label className={styles.tableLabelsHeader} >Descrição</label>
                        </Grid>
                        <Grid item xs={3}>
                            <label className={styles.tableLabelsHeader} >Empresa</label>
                        </Grid>
                        <Grid item xs={2}>
                            <label className={styles.tableLabelsHeader} >Código Análise</label>
                        </Grid>
                        <Grid item xs={2}>
                            <label className={styles.tableLabelsHeader} >Código Análise Enviado</label>
                        </Grid>
                        <Grid item xs={2}>
                            <label className={styles.tableLabelsHeader} >Ativo</label>
                        </Grid>
                    </Grid>
                    <Grid className={styles.block} container spacing={5}>
                        <Grid item xs={3}>
                            <input onChange={(t) => { setDescription(t.target.value) }} type="text" className={styles.input} defaultValue={analyseTypeReturn ? analyseTypeReturn.description : ''} style={{ width: "100%" }}></input>
                        </Grid>
                        <Grid item xs={3}>
                            <select name='tenants' value={selectedTenant} className={styles.search} style={{ width: "100%", marginRight: "5%" }} onChange={(e) => { setSelectedTenant(e.target.value) }}>
                                {
                                    tenants ?
                                        tenants.map(tenant => {
                                            return <option key={tenant.tenantID} value={tenant.tenantID}>{tenant.name}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select>
                        </Grid>
                        <Grid item xs={2}>
                            <input onChange={(t) => { setExternalCode(t.target.value) }} type="text" className={styles.input} defaultValue={analyseTypeReturn ? analyseTypeReturn.externalCode : ''} style={{ width: "100%" }}></input>
                        </Grid>
                        <Grid item xs={2}>
                            <input onChange={(t) => { setExternalCodeSent(t.target.value) }} type="text" className={styles.input} defaultValue={analyseTypeReturn ? analyseTypeReturn.externalCodeSent : ''} style={{ width: "100%" }}></input>
                        </Grid>
                        <Grid item xs={2}>
                            <select name='isActive' value={isActive} className={styles.search} style={{ width: "100%" }} onChange={(e) => { setIsActive(e.target.value) }}>
                                {
                                    activeOptions ?
                                        activeOptions.map(active => {
                                            return <option key={active.value} value={active.value}>{active.text}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select>
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "20px" }} container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item>{customList('Parametros', left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList('Selecionados', right)}</Grid>
                    </Grid>
                    <Box style={{ display: "flex", justifyContent: "flex-end", width: "30%", marginTop: "1%" }} >
                        <Button className={styles.buttonWeight} onClick={() => {
                            endAnalyseType()
                        }}>
                            <label className={styles.labelweight}>Salvar</label>
                        </Button>
                    </Box>
                </Box>
            }
        </>
    );
}

export default AnalyseTypeForm;
