import React from 'react';
import TableCrud from '../../components/TableCrud';

const AnalyseType = () => {
    const columns = [
        {
            label: "Nome",
            name: "description"
        },
        {
            label: "Empresa",
            name: "tenant.name"
        },
        {
            label: "Laboratório",
            name: "supplier.description"
        },
        {
            label: "Ativo",
            name: "isActive"
        }
    ]

    return (
        <TableCrud title={"Tipo de análise"} table={"analyseType"} columns={columns}/>
    );
}

export default AnalyseType;
