import styled from 'styled-components';

export const Container = styled.div`
    h3{
        margin-top: 42px;
        padding-bottom: 25px;
    }

    table{
        tbody{
            box-shadow: 0px 3px 20px #0000000d;

            tr{
                td{
                    display: table-cell;
                    padding: 10px;
                    vertical-align: middle;

                    .required{
                        color: red;
                    }
                }
    
                &:first-child{
                    td:first-child{
                        border-radius: 8px 0 0 0;
                    }
    
                    td:last-child{
                        border-radius: 0 8px 0 0;
                    }
                }
    
                &:last-child{
                    td:first-child{
                        border-radius: 0 0 0 8px;
                    }
                    
                    td:last-child{
                        border-radius: 0 0 8px 0;
                    }

                    border-bottom: transparent;
                }

                &.hide{
                    display: none;
                }
            }
        }
    }
`