import React, { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Password from 'antd/lib/input/Password';
import api, { exceptionNotificationAPI } from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [data, setData] = useState(() => {
        const token = localStorage.getItem('@SoilAnalysis:token');
        const user = localStorage.getItem('@SoilAnalysis:user');
        const userRoles = JSON.parse(localStorage.getItem('@SoilAnalysis:userRoles'));

        if (token && user) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            return { token, user, userRoles };
        }

        return {};
    });

    const signIn = async ({ username, password, resultResponse, normalizedName }) => {
        if (resultResponse === null)
        {
            const result = await api.post('/signin', {
                username,
                password,
            });

            return result;
        }
        else if (resultResponse !== null)
        {
            if (resultResponse.data === undefined)
            {
                setData({});

                const token = localStorage.getItem('@SoilAnalysis:token');
                const user = localStorage.getItem('@SoilAnalysis:user');
                const userRoles = resultResponse.roles.find(obj => obj.normalizedName === normalizedName).normalizedName;
                localStorage.setItem('@SoilAnalysis:userRoles', JSON.stringify(userRoles));

                api.defaults.headers.authorization = `Bearer ${token}`;

                setData({ token, user, userRoles });
            }
            else
            {
                const token = resultResponse.data.response.accessToken;
                const user = resultResponse.data.response.user;
                const userRoles = resultResponse.data.response.roles.find(obj => obj === normalizedName);

                localStorage.setItem('@SoilAnalysis:token', token);
                localStorage.setItem('@SoilAnalysis:user', JSON.stringify(user));
                localStorage.setItem('@SoilAnalysis:userRoles', JSON.stringify(userRoles));                
    
                api.defaults.headers.authorization = `Bearer ${token}`;
        
                setData({ token, user, userRoles });
            }

        }        
    }

    const signOut = () => {
        localStorage.removeItem('@SoilAnalysis:token');
        localStorage.removeItem('@SoilAnalysis:user');
        localStorage.removeItem('@SoilAnalysis:userRoles');
        localStorage.removeItem('@SoilAnalysis:AnalyseSampleStatus');
        localStorage.removeItem('@SoilAnalysis:AnalyseSampleStatusFinancial');
        localStorage.removeItem('@SoilAnalysis:AnalyseSampleBranch');

        setData({});
    }    

    const updateCrop = (newCropYear) => {
        let userStoraged = JSON.parse(localStorage.getItem('@SoilAnalysis:user'));
        userStoraged.selectedCropYear = newCropYear ;
        localStorage.setItem('@SoilAnalysis:user', JSON.stringify(userStoraged));
    }    

    return (
        <AuthContext.Provider
            value={{ user: data.user, userRoles: data.userRoles, signIn, signOut, updateCrop}}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    return useContext(AuthContext);
}
