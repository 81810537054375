import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";

export default function ModalFormPrint({ newPrint, setShow, cultureTypes, agronomist, ...props }) {
  const [selectedCultureTypes, setSelectedCultureTypes] = useState();
  const [selectedAgronomist, setSelectedAgronomist] = useState();

  useEffect(() => {
    if (cultureTypes && cultureTypes[0]) {
      setSelectedCultureTypes(cultureTypes[0].value)
    }
    if (agronomist && agronomist[0]) {
      setSelectedAgronomist(agronomist[0].value)
    }
  }, [cultureTypes], [agronomist]);

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Visualizar análise</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <label className="field">Tipo</label>
          <div className="div-form">
            <select value={selectedCultureTypes} onChange={(e) => { setSelectedCultureTypes(e.target.value) }} placeholder="cultureTypes" >
              {cultureTypes && cultureTypes.length > 0 ? cultureTypes.map((option) => <option key={option.value} value={option.value}>{option.text}</option>) : <></>}
            </select>
          </div>
          <label className="field">Agrônomo</label>
          <div className="div-form">
            <select value={selectedAgronomist} onChange={(e) => { setSelectedAgronomist(e.target.value) }} placeholder="agronomist" >
              {agronomist && agronomist.length > 0 ? agronomist.map((option) => <option key={option.value} value={option.value}>{option.text}</option>) : <></>}
            </select>
          </div>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => { newPrint(selectedCultureTypes, selectedAgronomist) }} >Ver</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}