import React, { useEffect, useState, useRef, forwardRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box, InputBase, InputAdornment, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { useLocation, useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import TableLoading from '../TableLoading';
import moment from 'moment';
import MenuTableAnalyseSample from '../MenuTableAnalyseSample';
import MenuTableAnalyseSampleView from '../MenuTableAnalyseSampleView';
import DatePicker, { registerLocale } from 'react-datepicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ModalFormAnalyseTypes from '../ModalFormAnalyseTypes';
import ModalFormImportAnalyseTypes from '../ModalFormImportAnalyseTypes';
import ModalFormTemplate from '../ModalFormTemplate';
import ModalFormPrint from '../ModalFormPrint';
import ModalFormFaturamento from '../ModalFormFaturamento';
import { error_message, success_message } from '../Toast/index.jsx';
import { useAuth } from '../../hooks/auth';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';


const AnalyseSampleList = () => {
    const history = useHistory();
    const styles = useStyles();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [analyseTypes, setAnalyseTypes] = useState([]);
    const [cultureTypes, setCultureTypes] = useState([]);
    const [agronomist, setAgronomist] = useState([]);
    const [branchs, setBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState();

    const [showModal, setShowModal] = useState(false);
    const [showModalImport, setShowModalImport] = useState(false);
    const [showModalTemplate, setShowModalTemplate] = useState(false);
    const [showModalFaturamento, setShowModalFaturamento] = useState(false);
    const [showModalPrint, setShowModalPrint] = useState(false);
    const [ID, setID] = useState(false);
    const inputFile = useRef(null)
    const { userRoles } = useAuth();

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');

    const statusOptions = [
        { value: 0, text: "Todos Status" },
        { value: 1, text: "Enviado - Laboratório" },
        { value: 2, text: "Recebido - Laboratório" },
        { value: 6, text: "Aguardando Análise - Laboratório" },
        { value: 3, text: "Pendente a Interpretação" },
        { value: 4, text: "Pendente a Impressão" },
        //{ value: 7, text: "Pendente Faturamento" },
        { value: 5, text: "Finalizado" },
    ];
    const [selectedStatus, setSelectedStatus] = useState(0);

    const statusFinancialOptions = [
        { value: 0, text: "Todos Status Financeiro" },        
        { value: 1, text: "Aguardando Análise" },
        { value: 2, text: "Pendente Faturamento" },        
        { value: 3, text: "Finalizado" },
        { value: 4, text: "Não Aplicável" },
    ];
    const [selectedStatusFinancial, setSelectedStatusFinancial] = useState(0);

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)

        loadAllAnalyseTypes((optionsAnalyseSample) => {
            if (cancel) return
            if (optionsAnalyseSample)
            {
                setAnalyseTypes(optionsAnalyseSample)

                loadTable(1, perPage, (response) => {                    
                    if (cancel) return
                    setTotalRows(response.recordsTotal)
                    setList(response.data)
    
                    loadAllParameterTemplate((optionsCulture) => {
                        setCultureTypes(optionsCulture)
    
                        loadAgronomist((optionAgronomist) => {
                            setAgronomist(optionAgronomist)
    
                            loadAllBranchs((optionBranchs) => {
                                setBranchs(optionBranchs)
                                setIsLoading(false)
                            });
                        });
    
                    });
    
                });
            }
        });

        // vai carregar o ultimo filtro aplicado nas combos
        if(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatus') != null){
            var _status = parseInt(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatus'));
            setSelectedStatus(_status);            
        }

        if(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatusFinancial') != null){
            var _statusFinancial = parseInt(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatusFinancial'));
            setSelectedStatusFinancial(_statusFinancial);            
        }

        if(localStorage.getItem('@SoilAnalysis:AnalyseSampleBranch') != null){
            var _branch = localStorage.getItem('@SoilAnalysis:AnalyseSampleBranch');   
            setSelectedBranch(_branch);            
        }

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    useEffect(() => {
        let cancel = false;
        if (refresh) {

            setIsLoading(true)

            loadTable(1, 10, (response) => {                
                if (cancel) return
                setTotalRows(response.recordsTotal)
                setList(response.data)
                setIsLoading(false);
            }).catch(e => {

            });

        }

        return () => {
            cancel = true;
            setIsLoading(false)
        }

    }, [refresh]);

    if (loading) {
        return (
            <Alert severity="info" sx={{ fontSize: '1.5rem' }}>
                {message}
                <LinearProgress variant="determinate" value={progress} sx={{ width: '100%', marginTop: 2, height: '0.5rem' }} />
            </Alert>
        )
    }

    const removeAnalyse = async (id) => {
        setIsLoading(true)
        api.post('AnalyseSample/delete?id=' + id).then((result) => {
            if (result.data.statusCode == 200) {
                setIsLoading(false)
                setRefresh(true)
                loadTable(1, 10, (response) => {
                    setTotalRows(response.recordsTotal)
                    setList(response.data)
                    setIsLoading(false)
                })
            }
        }).catch((error) => {
            setIsLoading(false)
            exceptionNotificationAPI(error);
        });
    }

    const printFunction = async (id) => {
        setShowModalPrint(true)
        setID(id)
    }

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const loadTable = async (page = 1, skip = 10, callback, isSearch = false) => {

        var _status = 0;
        var _statusFinancial = 0;
        var _branch = '';

        // Verifica se é filtro da busca ou do local Storage
        if(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatus') != null){
            _status = parseInt(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatus'));
        }

        if(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatusFinancial') != null){
            _statusFinancial = parseInt(localStorage.getItem('@SoilAnalysis:AnalyseSampleStatusFinancial'));
        }

        if(localStorage.getItem('@SoilAnalysis:AnalyseSampleBranch') != null){
            _branch = localStorage.getItem('@SoilAnalysis:AnalyseSampleBranch');     
        }

        // vai carregar o ultimo filtro aplicado nas combos
        if(_status != selectedStatus && isSearch == true){
            _status = selectedStatus;
        }

        if(_statusFinancial != selectedStatusFinancial && isSearch == true){
            _statusFinancial = selectedStatusFinancial;
        }

        if(_branch != selectedBranch  && isSearch == true){
            _branch = selectedBranch;
        }
        
        api.get(`AnalyseSample/GetAll?page=${page}&&skip=${skip}&&SearchText=${searchText}&&status=${_status}&&statusFinancial=${_statusFinancial}&&id=${selectedBranch ? selectedBranch : ''}
                &&startDate=${startDate ? moment(startDate).format("MM/DD/yyyy") : ""}
                &&endDate=${endDate ? moment(endDate).format("MM/DD/yyyy") : ""}`).then((result) => {
            if (result && result.data && result.data.response) {
                const options = result.data.response;                

                localStorage.setItem('@SoilAnalysis:AnalyseSampleStatus', _status);
                localStorage.setItem('@SoilAnalysis:AnalyseSampleStatusFinancial', _statusFinancial);
                localStorage.setItem('@SoilAnalysis:AnalyseSampleBranch', _branch);

                setPage(page);
                setPages(result.data.response.pages)

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadAllAnalyseTypes = async (callback) => {
        api.get('AnalyseSample/GetAllAnalyseTypeItems?isActive=true').then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadAllParameterTemplate = async (callback) => {
        api.get('Tenant/getAllParameterTemplate').then((result) => {
            if (result && result.data) {
                const options = result.data.response.data.map(item => ({
                    value: item.culture.cultureID,
                    text: `${item.culture.name} `
                }));

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadAgronomist = async (callback) => {
        api.get('Agronomist/GetAllAgronomistItems').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({
                    value: item.agronomistID,
                    text: `${item.name} `
                }));

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadAllBranchs = async (callback) => {
        api.get('Branch/GetAllBranchUsers').then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const InputDate = forwardRef(({ value, onClick }, ref) => (
        <InputBase ref={ref} className={styles.search} placeholder={"Período"} style={{ background: "white", marginLeft: 30, paddingRight: 20, width: '200px' }} value={value} onClick={onClick}
            startAdornment={
                <InputAdornment position="start">
                    <DateRangeIcon htmlColor='#5A646E' />
                </InputAdornment>
            }
        />
    ));

    const editAnalyse = async (id) => {
        history.push({ pathname: "/analyseSample/details", state: { analyseSampleID: id, isCreate: false, isInterpretation: false } })
    }

    const editInterpretationAnalyse = async (id) => {
        history.push({ pathname: "/analyseSample/details", state: { analyseSampleID: id, isCreate: false, isInterpretation: true } })
    }

    const viewAnalyse = async (id) => {
        history.push({ pathname: "/analyseSample/details", state: { analyseSampleID: id, isCreate: false } })
    }

    const newModalImport = async (analyseTypeID, files) => {
        const resultSingleton = await api.post(`/AnalyseSample/CreateSingletonAnalyseTypeID?AnalyseTypeID=${analyseTypeID}`);

        if (resultSingleton.data.response === true) {
            if (files && files[0]) {
                setIsLoading(true);

                const formData = new FormData();
                formData.append('file', files[0]);

                try {
                    const result = await api.post('/AnalyseSample/ImportFromFile', formData);

                    if (result.data.response === 'Success') {
                        success_message("Análise importada com sucesso!");
                        setIsLoading(false);
                        setShowModalImport(false);
                        
                        loadTable(1, perPage, (response) => {
                            setTotalRows(response.recordsTotal)
                            setList(response.data)
                            setIsLoading(false)
                        });
                    } else {
                        setIsLoading(false);
                        setIsLoading(false);
                        error_message(result.data.response);
                    }
                } catch (error) {
                    error_message("Erro na importação: " + error.message);
                }
            } else {
                error_message("Selecione um arquivo");
                setLoading(false);
            }
        }
    };

    //TODO
    const newModal = async (analyseTypeID, branchID) => {
        history.push({ pathname: "/analyseSample/details", state: { analyseTypeID: analyseTypeID, analyseTypeID: analyseTypeID, isCreate: true } })
        setIsLoading(false)
    }

    const newPrint = async (cultureID, agronomistID) => {
        if (agronomistID != undefined) {
            history.push({ pathname: "/analyseSample/views", state: { cultureID: cultureID, analyseSampleID: ID, agronomistID: agronomistID } })
        }
        setIsLoading(false)
    }

    function funcCheck() {
        try {
            var checkboxElement = document.getElementById('CheckboxBox');

            if (checkboxElement) {
                var checked = checkboxElement.checked;

                list.forEach(function (analyseSample) {
                    var checkboxElement = document.getElementById('CheckboxBox' + analyseSample.analyseSampleID);
                    if (checkboxElement) {
                        analyseSample.checked = checked;

                        var checkboxSampleElement = document.getElementById('CheckboxBox' + analyseSample.analyseSampleID);

                        if (checkboxSampleElement) {
                            checkboxSampleElement.checked = checked;
                        }
                    }

                });
            } else {
                console.log("Elemento com ID 'CheckboxBox' não encontrado.");
            }
        } catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    function funcCheckItem(analyseSampleID) {
        var checked = document.getElementById("CheckboxBox" + analyseSampleID).checked;
        list.forEach(function (analyseSample) {
            if (analyseSample.analyseSampleID == analyseSampleID) {
                analyseSample.checked = checked;
            }
        });
    }

    const newTemplate = async () => {
        const checkedItems = list.filter(analyseSample => analyseSample.checked);

        if (checkedItems.length > 0) {
            setShowModalTemplate(false)

            try {
                const analyseSampleIDs = [];

                const resultReportTemplate = await api.get(`AnalyseSample/GetReportTemplate`);

                if (resultReportTemplate && resultReportTemplate.data && resultReportTemplate.data.response) {
                    for (const analyseSample of checkedItems) {
                        analyseSampleIDs.push(analyseSample.analyseSampleID);                        
                    }

                    downloadReportRecomendationByAnalyseSample(resultReportTemplate.data.response, analyseSampleIDs);
                }
                else{
                    error_message('Esse Tenant não possui um Template de relatório configurado.')
                }
            } catch (error) {
                exceptionNotificationAPI(error);
            }
        } else {
            error_message('Selecione um item ou mais antes de enviar')
        }
    }

    const downloadReportRecomendationByAnalyseSample = async (ReportTemplate, analyseSampleIDs) => {
        async function loadReport() {
            localStorage.setItem('recomendationReportData', JSON.stringify({
                reportTemplate: ReportTemplate,
                analyseSampleIDs: analyseSampleIDs,
                isDownloadReport: true,
            }));

            localStorage.removeItem('secondScreenClosed');
            window.open(`/reports/recomendationReport`, '_blank', 'noopener', true);

            pollForSecondScreenClose();
        }

        function pollForSecondScreenClose() {
            const pollInterval = 1000;
            const poll = setInterval(() => {
                const secondScreenClosed = localStorage.getItem('secondScreenClosed');
                if (secondScreenClosed === 'true') {
                    localStorage.removeItem('secondScreenClosed');

                    setIsLoading(true)

                    loadTable(page, perPage, (response) => {
                        setTotalRows(response.recordsTotal)
                        setList(response.data)
                        setIsLoading(false)
                    })

                    clearInterval(poll); // Pare o polling
                }
            }, pollInterval);
        }

        loadReport();
    }

    function getStatus(status) {
        if (status === 1) {
            return "Enviado";
        } else if (status === 2) {
            return "Recebido";
        } else if (status === 3) {
            return "Pendente a Interpretação";
        }
        else if (status === 4) {
            return "Pendente a Impressão";
        }
        else if (status === 5) {
            return "Finalizado";
        }
        else if (status === 6) {
            return "Aguardando Análise";
        }
        else if (status === 7) {
            return "Pendente Faturamento";
        }
    }

    function getStatusFinancial(status) {
        if (status === 1) {
            return "Aguardando Análise";
        } else if (status === 2) {
            return "Pendente Faturamento";
        }
        else if (status === 3) {
            return "Finalizado";
        }
        else if (status === 4) {
            return "Não aplicável";
        }
    }

    const exportDataFaturamento = async (exportType, OSNumber) => {
        setShowModalFaturamento(false);
        setLoading(true);
        try {
            const values = { exportType : exportType, OSNumber: OSNumber };
            const result = await api.post('AnalyseSample/exportFinancialReport', values, {
                responseType: 'arraybuffer', // Solicitar uma resposta do tipo array de bytes (arquivo)
            });

            // Crie um blob a partir dos dados da resposta
            const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crie uma URL para o blob e crie um link para fazer o download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Faturamento.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            setLoading(false);
        } catch (error) {
            exceptionNotificationAPI(error);
            setLoading(false);
        }
    }

    return (
        <Box className={styles.container} >
            <Box className={styles.block2} style={{ margin: '0 auto', paddingLeft: 0, marginTop: '25px', width: '80%' }} >
                <label style={{ fontFamily: "Montserrat", fontWeight: 700, fontSize: 24, paddingTop: '17px', color: "#161C24" }} >Análises</label>
            </Box>
            <Box className={styles.block1} style={{ justifyContent: "flex-end", width: '80%' }}>
            <Button className={styles.button} style={{ backgroundColor: "#ec632c", width: 300, marginRight: "2%" }}
                    startIcon={
                        <AddIcon htmlColor="white" />
                    }
                    onClick={() => {
                        setShowModalFaturamento(true)
                    }}
                >
                    <label style={{ height: "auto", cursor: "pointer" }}>Exportar para Faturamento</label>
                </Button>
                <Button className={styles.button} style={{ backgroundColor: "#ec632c", width: 200, marginRight: "2%" }}
                    startIcon={
                        <AddIcon htmlColor="white" />
                    }
                    onClick={() => {
                        setShowModalTemplate(true)
                    }}
                >
                    <label style={{ height: "auto", cursor: "pointer" }}>Gerar Relatório</label>
                </Button>
                <Button className={styles.button} style={{ backgroundColor: "#ec632c", width: 200, marginRight: "2%" }}
                    startIcon={
                        <AddIcon htmlColor="white" />
                    }
                    onClick={() => {
                        setShowModalImport(true)
                    }}
                >
                    <label style={{ height: "auto", cursor: "pointer" }}>Importar planilha</label>
                </Button>
                { <Button className={styles.button} style={{ backgroundColor: "#0c8662", height: 44 }}
                    startIcon={
                        <AddIcon htmlColor="white" />
                    }
                    onClick={() => {
                        setShowModal(true)
                    }}
                >
                    <label style={{ height: "auto", cursor: "pointer" }}>Novo</label>
                </Button> }
            </Box>
            <Box className={styles.block1} style={{ width: '80%' }}>
                <InputBase className={styles.search} value={searchText} placeholder={"Buscar..."} style={{ background: "white", width: '200px' }} onChange={(e) => {
                    setSearchText(e.target.value)
                }}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon htmlColor='#5A646E' />
                        </InputAdornment>
                    }
                />

                <Box style={{ width: "auto" }}  >
                    <DatePicker
                        locale={"ptBR"}
                        dateFormat={"dd/MM/yyyy"}
                        customInput={<InputDate />}
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        selected={startDate}
                        onChange={(dates) => {
                            const [start, end] = dates;
                            setStartDate(start);
                            setEndDate(end);
                        }}
                        isClearable={true}
                    />
                </Box>
                <select onChange={e => { setSelectedStatus(e.target.value) }}
                    style={{ border: "1px solid rgba(145, 158, 171, 0.32)", padding: '5px', marginLeft: "2%", width: "15%", marginRight: "2%" }} name='status' value={selectedStatus} className={styles.fieldResponsible} >
                    {statusOptions.map((option) => <option key={option.value} value={option.value}>{option.text}</option>)}
                </select>
                <select onChange={e => { setSelectedStatusFinancial(e.target.value) }}
                    style={{ border: "1px solid rgba(145, 158, 171, 0.32)", padding: '5px', marginLeft: "2%", width: "15%", marginRight: "2%" }} name='statusFinancial' value={selectedStatusFinancial} className={styles.fieldResponsible} >
                    {statusFinancialOptions.map((option) => <option key={option.value} value={option.value}>{option.text}</option>)}
                </select>
                <select name='branch' value={selectedBranch}  onChange={(e) => { setSelectedBranch(e.target.value) }}
                style={{ border: "1px solid rgba(145, 158, 171, 0.32)", padding: '5px', marginLeft: "2%", width: "15%", marginRight: "2%" }} className={styles.fieldResponsible}>
                    <option value="">Selecione a Filial</option>
                    {
                        branchs && branchs.map(branch => {
                            return <option key={branch.branchID} value={branch.branchID}>{branch.description}</option>
                        })
                    }
                </select>
                <Button className={styles.button} style={{ marginLeft: "10px" }}
                    onClick={() => {
                        setIsLoading(true)
                        
                        loadTable(1, perPage, (response) => {
                            setTotalRows(response.recordsTotal)
                            setList(response.data)
                            setIsLoading(false)
                        }, true)
                    }}
                >
                    Buscar
                </Button>
            </Box>
            <div className={styles.block2} style={{ margin: '0 auto', marginTop: '25px', padding: '20px', width: '80%', backgroundColor: 'white', borderRadius: '8px' }}>
                {/* <Box
                    className={styles.block2}> */}
                <Box className={styles.header}>
                    <input id={"CheckboxBox"} type="checkbox" style={{ width: "5%" }} onClick={() => funcCheck()}></input>
                    <label className={styles.tableLabels} style={{ width: "30%" }}>Selecionar Todos</label>
                    <label className={styles.tableLabels} style={{ width: "65%", textAlign: "right" }}>Total de <b>{totalRows}</b> registros</label>
                </Box>

                <Box className={styles.header}>
                    <label className={styles.tableLabelsHeader} style={{ width: "3%" }}></label>
                    <label className={styles.tableLabelsHeader} style={{ width: "10%" }}>Cód. Cliente</label>
                    <label className={styles.tableLabelsHeader} style={{ width: "10%" }}>Amostra</label>
                    <label className={styles.tableLabelsHeader} style={{ width: "15%" }}>Análise</label>
                    <label className={styles.tableLabelsHeader} style={{ width: "20%" }}>Nome do produtor</label>
                    <label className={styles.tableLabelsHeader} style={{ width: "10%" }}>Data da emissão</label>
                    <label className={styles.tableLabelsHeader} style={{ width: "10%" }}>Status</label>
                    <label className={styles.tableLabelsHeader} style={{ width: "15%" }}>Status Financeiro</label>
                    <label className={styles.tableLabelsHeader} style={{ width: "7%" }}></label>
                </Box>
                {
                    list.map((e, i) => {
                        return (
                            <Box
                                key={e.analyseSampleID}
                                className={styles.tableRows}
                                style={{
                                    background: i % 2 === 0 ? '' : 'white',
                                }}
                            >
                                {
                                    e.status === 4 || e.status == 5 || e.status == 7 
                                        ?
                                        <input id={"CheckboxBox" + e.analyseSampleID} type="checkbox" style={{ width: "3%" }} onClick={() => funcCheckItem(e.analyseSampleID)}></input>
                                        :
                                        <label className={styles.tableLabels} style={{ width: "3%" }}></label>
                                }
                                <label className={styles.tableLabels} style={{ width: "10%" }}>{e.sampleNumber}</label>
                                <label className={styles.tableLabels} style={{ width: "10%" }}>{e.friendlyCode}</label>
                                <label className={styles.tableLabels} style={{ width: "15%" }}>{e.analyseType.description}</label>
                                <label className={styles.tableLabels} style={{ width: "20%" }}>{e.farmerCultureRegion.farmer.name}</label>
                                <label className={styles.tableLabels} style={{ width: "10%" }}>{e.dateEmission == null ? '--' : moment(e.dateEmission).format("DD/MM/YYYY")}</label>
                                <label className={styles.tableLabels} style={{ width: "10%" }}>{getStatus(e.status)}</label>
                                <label className={styles.tableLabels} style={{ width: "15%" }}>{getStatusFinancial(e.statusFinancial)}</label>
                                {
                                    e.status === 3 || e.status === 4 || e.status === 5 || e.status === 7 ?
                                        userRoles.includes("ROLESOILANALYSISREPORT") ?
                                            <MenuTableAnalyseSampleView style={{ width: "7%", height: "15%" }} id={e.analyseSampleID} viewFunction={viewAnalyse} />
                                            :
                                            <MenuTableAnalyseSample style={{ width: "7%", height: "15%" }} id={e.analyseSampleID} editFunction={editAnalyse} deleteFunction={removeAnalyse} editInterpretationAnalyse={editInterpretationAnalyse}/>
                                        :
                                        <label className={styles.tableLabelsHeader} style={{ width: "7%" }}></label>
                                }
                            </Box>
                        )
                    })
                }
                {/* </Box> */}
                <div className={styles.divlabel} >
                    <button
                        className="border rounded p-1" style={{ marginRight: '5px' }}
                        onClick={() => {
                            
                            loadTable(1, perPage, (response) => {
                                setIsLoading(true)
                                setTotalRows(response.recordsTotal)
                                setList(response.data)
                                setIsLoading(false)
                            })
                        }}
                    >
                        {'Primeira'}
                    </button>
                    <button
                        className="border rounded p-1" style={{ marginRight: '5px' }}
                        onClick={() => {
                            if (page > 1) {
                                
                                loadTable(page - 1, perPage, (response) => {
                                    setIsLoading(true)
                                    setTotalRows(response.recordsTotal)
                                    setList(response.data)
                                    setIsLoading(false)
                                })
                            }
                        }}
                    >
                        {'Anterior'}
                    </button>
                    <button
                        className="border rounded p-1" style={{ marginRight: '5px' }}
                        onClick={() => {
                            if (page < pages) {
                                
                                loadTable(page + 1, perPage, (response) => {
                                    setIsLoading(true)
                                    setTotalRows(response.recordsTotal)
                                    setList(response.data)
                                    setIsLoading(false)
                                })
                            }
                        }}
                    >
                        {'Próxima'}
                    </button>
                    <button
                        className="border rounded p-1" style={{ marginRight: '5px' }}
                        onClick={() => {
                            
                            loadTable(pages, perPage, (response) => {
                                setIsLoading(true)
                                setTotalRows(response.recordsTotal)
                                setList(response.data)
                                setIsLoading(false)
                            })
                        }}
                    >
                        {'Última'}
                    </button>
                    <span className={styles.container} style={{ marginRight: '5px', marginLeft: '20px', lineHeight: '30px' }}>
                        <div>Página(s)
                            <strong style={{ marginLeft: '5px' }}>
                                {page} de {' '}
                                {pages}
                            </strong>
                        </div>

                    </span>
                    <select
                        style={{ border: "1px solid rgba(145, 158, 171, 0.32)", padding: '5px', borderRadius: "8px" }}
                        className={styles.container}
                        onChange={e => {
                            setPerPage(Number(e.target.value))
                            
                            loadTable(1, Number(e.target.value), (response) => {
                                setIsLoading(true)
                                setTotalRows(response.recordsTotal)
                                setList(response.data)
                                setIsLoading(false)
                            })
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            pageSize == perPage ?
                                <option key={perPage} value={perPage}>
                                    Mostrar {perPage}
                                </option>
                                :
                                <option key={pageSize} value={pageSize}>
                                    Mostrar {pageSize}
                                </option>
                        ))}
                    </select>
                </div>
            </div>
            <ModalFormTemplate show={showModalTemplate} setShow={setShowModalTemplate} newTemplate={newTemplate} />
            <ModalFormAnalyseTypes show={showModal} setShow={setShowModal} analyseTypes={analyseTypes} newModal={newModal} />
            <ModalFormImportAnalyseTypes show={showModalImport} setShow={setShowModalImport} analyseTypes={analyseTypes} newModalImport={newModalImport} />
            <ModalFormPrint show={showModalPrint} setShow={setShowModalPrint} cultureTypes={cultureTypes} agronomist={agronomist} newPrint={newPrint} />
            <ModalFormFaturamento show={showModalFaturamento} setShow={setShowModalFaturamento} fnExport={exportDataFaturamento} />
        </Box >
    );
}

export default AnalyseSampleList;
