import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { useStyles } from './styles';
import { ModalFooter } from "react-bootstrap";
import { Box, Grid, Button } from '@material-ui/core';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ModalFormParameterCombined from '../ModalFormParameterCombined';

export default function ModalFormParameter({ newModal, setShow, _left, _right, _lstAnalyseTypeParameterAll, ...props }) {
  const [searchText, setSearchText] = useState("");
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [checked, setChecked] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [showModal, setShowModal] = useState(false);
  const [lstAnalyseTypeParameterAll, setLstAnalyseTypeParameterAll] = useState([]);
  const { v4: uuidv4 } = require('uuid');

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    var newList = [...right]
    leftChecked.forEach(function (_leftCheckedAux) {
      const leftCheckedAux = {
        ..._leftCheckedAux,
        analyseTypeParameterID: _leftCheckedAux.analyseTypeParameterID,
        analyseTypeParameterAuxID: uuidv4(),
        parameterID: _leftCheckedAux.parameterID,
        analyseTypeID: _leftCheckedAux.analyseTypeID,
        description: _leftCheckedAux.description,
      };
      newList.push(leftCheckedAux)
    });
    newList.sort((a, b) => a.description.localeCompare(b.description))
    setRight(newList);
  };

  const handleCheckedLeft = () => {
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }


  useEffect(() => {
    let cancel = false;
    setIsLoading(true)

    if (_left && _left[0]) {
      setLeft(_left)
    }
    if (_right && _right[0]) {
      setRight(_right)
    }
    if (_lstAnalyseTypeParameterAll && _lstAnalyseTypeParameterAll[0]) {
      setLstAnalyseTypeParameterAll(_lstAnalyseTypeParameterAll)
    }

    return () => {
      cancel = true;
      setIsLoading(false)
    }
  }, [_left, _right]);


  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'todos os itens selecionados',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionado`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 500,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.description}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const newModalCombined = async (analyseTypeParameterID, analyseTypeParameterCombinedID, parameterDescriprion) => {
    lstAnalyseTypeParameterAll.forEach(function (_analyseTypeParameter) {
      if (_analyseTypeParameter.analyseTypeParameterID == analyseTypeParameterID) {
        if (analyseTypeParameterCombinedID) {
          _analyseTypeParameter.dependencyAnalyseTypeParameterID = analyseTypeParameterCombinedID
          _analyseTypeParameter.hasDependency = true
        }
        lstAnalyseTypeParameterAll.forEach(function (_analyseTypeParameterCombined) {
          if (_analyseTypeParameterCombined.analyseTypeParameterID == analyseTypeParameterCombinedID) {
            _analyseTypeParameter.description = parameterDescriprion
            right.push(_analyseTypeParameter)
          }
        });
      }
    });
  }

  return (
    <Modal
      {...props}
      size="xl"
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Parâmetros</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <Grid style={{ marginTop: "20px" }} container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList('Parâmetros', left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList('Selecionados', right)}</Grid>
          </Grid>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <Button className={styles.buttonWhite} onClick={() => {
              setShowModal(true)
            }}>
              <label className={styles.labelBlack}>Combinar dois parâmetros</label>
            </Button>
          </div>
        </Footer>
      </ModalFooter>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => {
              newModal(left, right)
              setShow(false)
            }} >Adicionar Selecionadas</button>
          </div>
        </Footer>
      </ModalFooter>
      <ModalFormParameterCombined show={showModal} setShow={setShowModal} newModalCombined={newModalCombined} lstAnalyseTypeParameterAll={lstAnalyseTypeParameterAll} />
    </Modal>
  );
}