import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import api, { exceptionNotificationAPI } from '../../services/api';

import { Box, Grid, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import download from '../../assets/icons/download.png';
import { ImUndo2 } from "react-icons/im";
import Card from '@mui/material/Card';
import LoadingEvents from '../../components/LoadingEvents';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import data_results_logo from '../../assets/images/data_results_logo.png';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import assinatura_unisc from '../../assets/images/assinatura_unisc.png';
import header_laudo from '../../assets/images/header_laudo.png';
import { useDispatch } from 'react-redux'; // Importe apenas o useDispatch aqui

import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import ReactDOM from 'react-dom';
import JSZip from 'jszip';
import { PDFDocument } from 'pdf-lib';
//import { PDFLib } from 'pdf-lib';
import { NumericFormat } from 'react-number-format';

const RecomendationReport = () => {
    const dispatch = useDispatch();
    const [isLoadingEvents, setIsLoadingEvents] = useState(false);
    const [start, setStart] = useState(true);
    const [startDownload, setStartDownload] = useState(true);

    const history = useHistory();
    const styles = useStyles();
    const { data } = useSelector(state => state.recomendationReportData);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');

    let reportTemplate, combinedList, isDownloadReport, analyseSampleIDs;

    if (data) {
        reportTemplate = data.reportTemplate;
        if (data.combinedList) {
            combinedList = data.combinedList;
        }
        if (data.isDownloadReport) {
            isDownloadReport = data.isDownloadReport;
        }
        if (data.analyseSampleIDs) {
            analyseSampleIDs = data.analyseSampleIDs;
        }
    }

    useEffect(() => {
        const storedData = localStorage.getItem('recomendationReportData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);

            reportTemplate = parsedData.reportTemplate;
            if (parsedData.combinedList) {
                combinedList = parsedData.combinedList;
            }
            isDownloadReport = parsedData.isDownloadReport;
            analyseSampleIDs = parsedData.analyseSampleIDs;

            if (isDownloadReport && combinedList) {
                generateAndDownloadPdfSeveral();
            }
            else if (isDownloadReport) {
                setMessage('1/3 - Preparando relatório(s), aguarde...');
                setLoading(true);
                setProgress(0);

                consultAnalyseSampleParameterTemplate();

            }

            if (start) {
                setStart(false)
                dispatch({
                    type: 'SET_RECOMENDATION_REPORT_DATA',
                    payload: {
                        combinedList: combinedList,
                        reportTemplate: reportTemplate,
                        isDownloadReport: isDownloadReport
                    },
                });
            }


        }
    }, [dispatch, reportTemplate, combinedList, isDownloadReport, analyseSampleIDs]);



    const consultAnalyseSampleParameterTemplate = async () => {
        const totalRequests = analyseSampleIDs.length;
        const combinedListAux = [];

        for (let i = 0; i < totalRequests; i++) {
            try {
                const analyseSampleID = analyseSampleIDs[i];
                const resultAnalyseSample = await api.get(`AnalyseSample/GetById?id=${analyseSampleID}`);

                if (resultAnalyseSample && resultAnalyseSample.data.response) {
                    const resultAnalyseSampleParameterTemplate = await api.get(`AnalyseSample/ConsultAnalyseSampleParameterTemplate?id=${analyseSampleID}`);

                    if (resultAnalyseSampleParameterTemplate && resultAnalyseSampleParameterTemplate.data.response) {
                        const parameterTemplates = resultAnalyseSampleParameterTemplate.data.response;

                        for (const parameterTemplate of parameterTemplates) {
                            const resultAnalyseSampleResultInterpretation = await api.get(`AnalyseSample/ConsultAnalyseSampleResultInterpretation?id=${analyseSampleID}&&idAux=${parameterTemplate.parameterTemplateID}`);

                            if (resultAnalyseSampleResultInterpretation && resultAnalyseSampleResultInterpretation.data.response) {
                                const resultLoadAnalyseReportWithObservation = await api.get(`Interpretation/Recommendation/LoadAnalyseSampleObservationList?analyseSampleID=${analyseSampleID}&&idAux=${parameterTemplate.parameterTemplateID}&&isForReport=false`);

                                if (resultLoadAnalyseReportWithObservation && resultLoadAnalyseReportWithObservation.data.response.length > 0) {
                                    combinedListAux.push({
                                        analyseSampleReturn: resultAnalyseSample.data.response,
                                        analyseSampleParameterTemplates: parameterTemplate,
                                        agronomist: parameterTemplate.agronomist,
                                        lstAnalyseSampleResultInterpretation: resultAnalyseSampleResultInterpretation.data.response.item1,
                                        lstParameterTemplateItem: resultAnalyseSampleResultInterpretation.data.response.item2,
                                        observationList: resultLoadAnalyseReportWithObservation.data.response,
                                    });
                                } else {
                                    combinedListAux.push({
                                        analyseSampleReturn: resultAnalyseSample.data.response,
                                        analyseSampleParameterTemplates: parameterTemplate,
                                        agronomist: parameterTemplate.agronomist,
                                        lstAnalyseSampleResultInterpretation: resultAnalyseSampleResultInterpretation.data.response.item1,
                                        lstParameterTemplateItem: resultAnalyseSampleResultInterpretation.data.response.item2,
                                    });
                                }
                            }
                        }
                    }

                    const progress = Math.round((i + 1) / totalRequests * 100);
                    setProgress(progress);
                }
            } catch (error) {
                console.log('Error generating consultAnalyseSampleParameterTemplate:', error);
            }
        }

        // Atualize o estado fora do loop para garantir que ele seja atualizado após todas as chamadas da API
        if (start) {
            setLoading(false);
            setStart(false)
            dispatch({
                type: 'SET_RECOMENDATION_REPORT_DATA',
                payload: {
                    combinedList: combinedListAux,
                    reportTemplate: reportTemplate,
                    isDownloadReport: isDownloadReport
                },
            });
        }
    };

    const generateAndDownloadPdfSeveral = async () => {
        try {
            const allPdfBytes = [];

            // Renderize o conteúdo HTML usando a constante
            const container = document.createElement('div');
            document.body.appendChild(container);

            // Posicione o container fora da tela
            container.style.position = 'absolute';
            container.style.left = '-9999px';

            const pdfContentContainer = document.createElement('div');
            container.appendChild(pdfContentContainer);

            setMessage('2/3 - Carregando Arquivos, aguarde...');
            setLoading(true);

            ReactDOM.render(
                <div style={{ overflowX: 'hidden' }}>
                    <PdfContent />
                </div>,
                pdfContentContainer
            );

            // Aguarde até que o componente esteja montado
            await new Promise((resolve) => setTimeout(resolve, 0));
            setProgress(0);

            for (let i = 0; i < combinedList.length; i++) {
                let pdfDataHtml = null;

                // Aguarde até que pdfDataHtml não seja nulo
                while (!pdfDataHtml) {
                    pdfDataHtml = document.getElementById(
                        'pdfData' +
                        combinedList[i].analyseSampleReturn.analyseSampleID +
                        combinedList[i].analyseSampleParameterTemplates.parameterTemplateID
                    );
                }

                setProgress((i + 1) / combinedList.length * 100);

                const doc = new jsPDF('p', 'mm', 'a4');

                const pdfWidthInMM = 210;
                const pdfHeightInMM = 297;
                const marginInMM = 1;
                const dpi = 600;

                const canvas = await html2canvas(pdfDataHtml, {
                    scale: 1.7,
                    dpi: dpi,
                });

                const imgData = canvas.toDataURL('image/jpeg'); // Reduzindo a qualidade da imagem para 50%
                const imgWidthInMM = pdfWidthInMM - 2 * marginInMM;
                const imgHeightInMM =
                    (imgWidthInMM / canvas.width) * canvas.height;
                const totalPages = Math.ceil(imgHeightInMM / pdfHeightInMM);

                for (let page = 1; page <= totalPages; page++) {
                    if (page !== 1) {
                        doc.addPage();
                    }

                    const offsetY = -(page - 1) * pdfHeightInMM;
                    doc.addImage(
                        imgData,
                        'jpeg',
                        marginInMM,
                        offsetY + marginInMM,
                        imgWidthInMM,
                        imgHeightInMM
                    );
                }

                // Converte o documento jsPDF para bytes
                const pdfBytes = doc.output('arraybuffer');

                allPdfBytes.push({
                    pdfBytes: pdfBytes,
                    analyseSampleID:
                        combinedList[i].analyseSampleReturn.analyseSampleID,
                });
            }

            // Remova os elementos renderizados do DOM
            ReactDOM.unmountComponentAtNode(container);
            document.body.removeChild(container);

            setMessage('3/3 - Efetuando Download, aguarde...');
            setLoading(true);

            // Envia os bytes para o backend
            await sendPdfBytesToBackend(allPdfBytes);

            localStorage.setItem('secondScreenClosed', 'true');

            //window.close();
        } catch (error) {
            console.log('Error generating PDF:', error);
        }
    };


    const generateAndDownloadPdf = async () => {
        try {
            const allPdfBytes = [];

            for (let i = 0; i < combinedList.length; i++) {
                let pdfDataHtml = null;

                // Aguarda até que pdfDataHtml não seja nulo
                while (!pdfDataHtml) {
                    pdfDataHtml = document.getElementById("pdfData" + combinedList[i].analyseSampleReturn.analyseSampleID + combinedList[i].analyseSampleParameterTemplates.parameterTemplateID);
                }

                const pdfWidthInMM = 210;
                const pdfHeightInMM = 297;
                const marginInMM = 1;
                const dpi = 600; // Reduzindo a resolução para 72 DPI

                const canvas = await html2canvas(pdfDataHtml, {
                    scale: 1.7, // Evitando escala adicional
                    dpi: dpi
                });

                const imgData = canvas.toDataURL('image/jpeg'); // Reduzindo a qualidade da imagem para 50%
                const imgWidthInMM = pdfWidthInMM - (2 * marginInMM);
                const imgHeightInMM = (imgWidthInMM / canvas.width) * canvas.height;
                const totalPages = Math.ceil(imgHeightInMM / pdfHeightInMM);

                //const doc = new jsPDF('p', 'mm', 'a4');
                const doc = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                    putOnlyUsedFonts:true
                   });

                for (let page = 1; page <= totalPages; page++) {
                    if (page !== 1) {
                        doc.addPage();
                    }

                    const offsetY = -(page - 1) * pdfHeightInMM;
                    doc.addImage(imgData, 'JPEG', marginInMM, offsetY + marginInMM, imgWidthInMM, imgHeightInMM);
                }

                // Aplica compressão adicional ao PDF
                const pdfBytes = await applyPdfCompression(doc.output('arraybuffer'));

                allPdfBytes.push({
                    pdfBytes: pdfBytes,
                    analyseSampleID: combinedList[i].analyseSampleReturn.analyseSampleID
                });
            }

            // Envia os bytes para o backend
            setMessage('gerando relatório(s), aguarde...');
            setLoading(true);

            await sendPdfBytesToBackend(allPdfBytes);

            // Aguarde um pouco antes de prosseguir para o próximo item (opcional)
            await new Promise(resolve => setTimeout(resolve, 1000));

            window.close();

        } catch (error) {
            console.log('Error generating PDF:', error);
        }
    };

    const applyPdfCompression = async (pdfBytes) => {
        try {
            // PDF Modification
            const pdfDoc = await PDFDocument.load(pdfBytes);

            // Carregar o PDF usando a biblioteca pdf-lib
            //const pdfDoc = await PDFLib.PDFDocument.load(pdfBytes);
            // Otimizar o PDF removendo metadados e compactando recursos
            await pdfDoc.compress();

            // Salvar o PDF otimizado e retornar os bytes
            const compressedBytes = await pdfDoc.save();
            return compressedBytes;

        } catch (error) {
            console.error('Erro ao aplicar compressão ao PDF:', error);
            // Em caso de erro, retornar os bytes originais sem compressão
            return pdfBytes;
        }
    };


    if (loading) {
        return (
            <Alert severity="info" sx={{ fontSize: '1.5rem' }}>
                {message}
                <LinearProgress variant="determinate" value={progress} sx={{ width: '100%', marginTop: 2, height: '0.5rem' }} />
            </Alert>
        )
    }
    const sendPdfBytesToBackend = async (allPdfBytes) => {
        const zip = new JSZip();
        const formData = new FormData();

        for (let i = 0; i < allPdfBytes.length; i++) {
            const pdfBytes = allPdfBytes[i];
            const fileName = `lstFrontendPdf_${i}.pdf`;

            // Comprimir o PDF antes de adicioná-lo ao arquivo ZIP
            const compressedPdfBytes = await compressPdf(pdfBytes.pdfBytes);

            // Adicionar o PDF comprimido ao arquivo ZIP
            zip.file(fileName, compressedPdfBytes);
            formData.append(`analyseSampleIDs`, pdfBytes.analyseSampleID); // Adiciona o ID da amostra
        }

        const zipFile = await zip.generateAsync({ type: 'blob' });
        formData.append('zipFiles', zipFile);

        setProgress(0);

        const config = {
            onUploadProgress: (progressEvent) => {
                const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(progressPercentage);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        try {
            const result = await api.post('/Recommendation/GetPdf', formData, config);

            if (result.data.response) {

                //console.log('--->' + result.data.response.fileContents);

                const decodedPdfContent = atob(result.data.response.fileContents);
                const blob = new Blob([Uint8Array.from(decodedPdfContent, c => c.charCodeAt(0))], { type: 'application/pdf' });

                const currentDate = new Date();
                const formattedDate = currentDate.toISOString().replace(/[:.]/g, "-");

                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `Relatorio_${formattedDate}.pdf`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

                setProgress(100);
                setLoading(false);
            }
        } catch (error) {
            // Tratar erros de forma adequada
            console.error('Erro durante o envio para o backend:', error);
            setLoading(false);
        }
    };

    const sendPdfBytesToBackend1 = async (allPdfBytes) => {
        const zip = new JSZip();
        const formData = new FormData();

        allPdfBytes.forEach((pdfBytes, index) => {
            const fileName = `lstFrontendPdf_${index}.pdf`; // Nome de arquivo único para cada PDF
            zip.file(fileName, pdfBytes.pdfBytes); // Adiciona o PDF ao arquivo ZIP
            formData.append(`analyseSampleIDs`, pdfBytes.analyseSampleID); // Adiciona o ID da amostra
        });

        const zipFile = await zip.generateAsync({ type: 'blob' });
        formData.append('zipFiles', zipFile);

        setProgress(0);

        const config = {
            onUploadProgress: (progressEvent) => {
                const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(progressPercentage);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        try {
            const result = await api.post('/Recommendation/GetPdf', formData, config);

            if (result.data.response) {
                const decodedPdfContent = atob(result.data.response.fileContents);
                const blob = new Blob([Uint8Array.from(decodedPdfContent, c => c.charCodeAt(0))], { type: 'application/pdf' });

                const currentDate = new Date();
                const formattedDate = currentDate.toISOString().replace(/[:.]/g, "-");

                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `Relatorio_${formattedDate}.pdf`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

                setProgress(100);
                setLoading(false);
            }
        } catch (error) {
            // Tratar erros de forma adequada
            console.error('Erro durante o envio para o backend:', error);
            setLoading(false);
        }
    };

    const compressPdf = async (pdfBytes) => {
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const compressedPdfBytes = await pdfDoc.save();
        return compressedPdfBytes;
    };

    const handleDateFormater = (paramDate) => {
        if (paramDate) {
            const _date = new Date(paramDate);
            const day = (_date.getDate()).toString().padStart(2, '0');
            const month = (_date.getMonth() + 1).toString().padStart(2, '0');
            const year = _date.getFullYear().toString();
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        }
        return undefined;
    }

    const renderInterpretationRowComplete = (description, intervalNormalValue, actualValue, valueDescription, thermometerValue, color, actualValue2, listParameterTemplateItem) => {
        return (
            <div className={styles.interpretationRow} style={{
                width: '169px',
                height: '140px',
                borderRadius: '8px', border: '1px solid #CCD1D6'
            }}>
                <div style={{ display: 'flex', padding: '2px', fontFamily: 'Montserrat' }}>
                    <div style={{ width: '70%', lineHeight: '10px' }}>
                        <div style={{ height: '35px' }}>
                            <p style={{ fontWeight: 'bold', color: '#ACB4BA', fontSize: '12px', lineHeight: '14px' }}>{description}</p>
                        </div>
                        <p style={{ fontWeight: 'bold', fontSize: '16px', padding: '8px 0 8px 0', lineHeight: 0.5 }}>
                            {actualValue2 ? actualValue2.toFixed(2) : actualValue.toFixed(2)}</p>

                        {
                            listParameterTemplateItem !== null ?
                                <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Normal: <b style={{ fontSize: '9px' }}>{intervalNormalValue}</b></p>
                                :
                                ''
                        }
                        {
                            <p style={{ fontWeight: 'bold', fontSize: '10px', width: '100px' }}>
                                <div style={{ width: '12px', height: '12px', backgroundColor: `${color}`, borderRadius: '4px', position: 'absolute' }}> </div>
                                <p style={{ paddingLeft: '16px', paddingTop: '2px' }}>{valueDescription}</p>
                            </p>
                        }

                    </div>
                    {
                        listParameterTemplateItem !== null ?
                            <div style={{ width: '30%', height: '125px', fontSize: '8px', fontWeight: 'bold', color: '#737D86', lineHeight: '10px' }}>
                                <div style={{
                                    borderRadius: '100%',
                                    backgroundColor: 'white',
                                    border: '2px solid #0C8662',
                                    width: '19px',
                                    height: '19px',
                                    position: 'absolute',
                                    marginLeft: '-5px',
                                    transform: `translateY(${570 - thermometerValue}%)`
                                }}>
                                </div>
                                {
                                    listParameterTemplateItem !== null ?
                                        listParameterTemplateItem.map(e => {
                                            return (
                                                <p style={{ float: 'left', paddingLeft: '16px', width: '100%' }}>{e.resultDescription}</p>
                                            )
                                        })
                                        :
                                        <></>
                                }
                                {
                                    listParameterTemplateItem !== null ?
                                        <div style={{
                                            background: linearGradient(listParameterTemplateItem),
                                            width: '8px',
                                            height: '100%',
                                            borderRadius: '5px'
                                        }}>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                            :
                            <></>
                    }

                </div>
            </div >
        );
    }

    const addCommas = (num) =>
        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");      
    
      

    const renderInterpretationRow = (description, actualValue, valueDescription, color, actualValue2) => {
        return (
            <div className={styles.interpretationRow} style={{ width: '105px', height: '85px', borderRadius: '8px', border: '1px solid #ACB4BA', marginTop: '-1%' }}>
                <div style={{ display: 'flex', padding: '2px', fontFamily: 'Montserrat' }}>
                    <div style={{ width: '70%', lineHeight: '10px' }}>
                        <div style={{ height: '35px' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '12px', lineHeight: '14px' }}>{description}</p>
                        </div>
                        <p style={{ fontWeight: 'bold', fontSize: '16px', marginTop: "4px" }}>
                            {actualValue2 ? actualValue2.toFixed(1) : actualValue.toFixed(1)}</p>
                        <p style={{ fontWeight: 'bold', fontSize: '10px', width: '95px' }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: `${color}`, borderRadius: '4px', position: 'absolute' }}> </div>
                            <p style={{ textAlign: 'center', paddingTop: '2px' }}>{valueDescription}</p>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    const linearGradient = (listParameterTemplateItem) => {
        var text = 'linear-gradient(to bottom'
        listParameterTemplateItem.map(e => {
            text += ', ' + e.resultColor;
        })
        text += ')';
        return text;
    }

    const handleObservationList = (dataList) => {
        return dataList
            ? dataList.filter(e => e.description !== '')
                .map((e, index) => (
                    <Box key={index} style={{
                        width: '100%',
                        marginLeft: '2%',
                        marginTop: index === 0 ? '5px' : '0',
                        fontSize: '12px'
                    }}>
                        {e.description}
                    </Box>
                ))
            : null;
    }

    // Defina a constante com o conteúdo a ser renderizado
    const PdfContent = () => {

        return (
            <div style={{ overflowX: 'hidden' }}>
                {
                    combinedList ? combinedList.map((itemList, index) => {
                        const pdfDataId = `pdfData${itemList.analyseSampleReturn.analyseSampleID + itemList.analyseSampleParameterTemplates.parameterTemplateID}`;

                        return (
                            <div style={{ width: "794px", margin: '0 auto' }}>
                                <div id={pdfDataId} style={{ width: "794px", margin: '0 auto', backgroundColor: 'white', borderRadius: '5px' }}>
                                    <Box style={{ display: "flex", flexDirection: "column", padding: '2%', margin: '1%' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                            {
                                                itemList.analyseSampleParameterTemplates.parameterTemplate.tenant && itemList.analyseSampleParameterTemplates.parameterTemplate.tenant.logo ?
                                                    <div style={{ maxWidth: "100px", maxHeight: '70px', borderRadius: '5px' }}>
                                                        <img src={`data:image/jpeg;base64, ${itemList.analyseSampleParameterTemplates.parameterTemplate.tenant.logo}`} alt="Logo"
                                                            style={{ margin: '0px auto', width: '70%' }} />
                                                    </div>
                                                    :
                                                    ''
                                            }
                                            <label style={{ fontFamily: "Montserrat", marginLeft: "1px", fontWeight: 700, fontSize: "20px", lineHeight: '41.45px', color: "black" }}>
                                                {reportTemplate ? reportTemplate.title : 'Recomendações de Produtividade do Solo'}
                                            </label>
                                        </Box>
                                        <Box style={{ display: "flex", width: "100%", marginTop: "-5px" }} >
                                            <div className={styles.border} />
                                        </Box>
                                        <Box className={styles.containerColumns} style={{ marginTop: '10px' }}>
                                            <div className={styles.columnsRow} style={ { width: '25%' }}>
                                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Produtor</p>
                                                <p style={{ lineHeight: '12px' }}>
                                                    {(itemList.analyseSampleReturn?.farmerCultureRegion?.externalCode ?? '')}
                                                    {itemList.analyseSampleReturn?.farmerCultureRegion?.farmer?.name ? ` - ${itemList.analyseSampleReturn?.farmerCultureRegion?.farmer?.name}` : ''}
                                                </p>
                                            </div>
                                            {
                                                itemList.analyseSampleReturn?.instructor ?
                                                        <div className={styles.columnsRow} style={ { width: '25%' }}>
                                                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Instrutor</p>
                                                            <p style={{ lineHeight: '12px' }}>
                                                                {(itemList.analyseSampleReturn?.instructor?.externalCode ?? '')}      
                                                                {itemList.analyseSampleReturn?.instructor?.name ? ` - ${itemList.analyseSampleReturn?.instructor?.name}` : ''}                                              
                                                            </p>
                                                        </div>
                                                :
                                                ''
                                            }                                            
                                            <div className={styles.columnsRow} style={{ width: '18%' }}>
                                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Data Emissão</p>
                                                <p style={{ lineHeight: '12px' }}>{(itemList.analyseSampleReturn?.formattedDateEmission ? handleDateFormater(itemList.analyseSampleReturn?.dateEmission) : '')}</p>
                                            </div>
                                            <div className={styles.columnsRow} style={{ width: '10%' }}>
                                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Cultura</p>
                                                <p style={{ lineHeight: '12px', marginTop: '-2%' }}>{(itemList.analyseSampleParameterTemplates.parameterTemplate.culture?.name ?? "Todas")}</p>
                                            </div>
                                            <div className={styles.columnsRow} style={{ width: '22%' }}>
                                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Amostra</p>
                                                <p style={{ lineHeight: '12px', marginTop: '-2%' }}>{(itemList.analyseSampleReturn?.friendlyCode ?? '')}</p>
                                            </div>
                                        </Box>
                                        <Box style={{ display: "flex", width: "100%", marginTop: "-5px" }} >
                                            <div className={styles.border} />
                                        </Box>
                                        <Box style={{ width: "100%", paddingTop: '0px' }} >
                                            <p style={{ fontFamily: "Montserrat", marginLeft: "1%", fontWeight: 700, fontSize: "16px", marginTop: '5px' }}>Interpretação</p>
                                        </Box>
                                        <Box className={styles.interpretationElement} style={{
                                            height: reportTemplate && reportTemplate.versionComplete == 0 ? '250px' : '840px',
                                        }}>
                                            <Box className={styles.interpretationElement} >
                                                {itemList.lstAnalyseSampleResultInterpretation ? itemList.lstAnalyseSampleResultInterpretation.map(e => {
                                                    return (
                                                        <>
                                                            {
                                                                reportTemplate && reportTemplate.versionComplete == 1 ?
                                                                    renderInterpretationRowComplete(
                                                                        e.parameterTemplateItem != null ? e.parameterTemplateItem.parameterTemplateItemHeader.description : e.analyseSampleResult.parameter.description
                                                                        , e.intervalNormalValue
                                                                        , e.analyseSampleResult.value
                                                                        , e.parameterTemplateItem ? e.parameterTemplateItem.resultDescription : ""
                                                                        , e.thermometerValue
                                                                        , e.parameterTemplateItem ? e?.parameterTemplateItem.resultColor : 'white'
                                                                        , e?.analyseSampleResultDependency?.value ?? null
                                                                        , e.listParameterTemplateItemDto ? e.listParameterTemplateItemDto : null)
                                                                    :
                                                                    renderInterpretationRow(
                                                                        e.parameterTemplateItem != null ? e.parameterTemplateItem.parameterTemplateItemHeader.description : e.analyseSampleResult.parameter.description
                                                                        , e.analyseSampleResult.value
                                                                        , e.parameterTemplateItem ? e.parameterTemplateItem.resultDescription : ""
                                                                        , e.parameterTemplateItem ? e?.parameterTemplateItem.resultColor : 'white'
                                                                        , e?.analyseSampleResultDependency?.value ?? null)
                                                            }
                                                        </>
                                                    )
                                                }) : ''}
                                            </Box>
                                        </Box>

                                        <Box style={{ display: "flex", width: "100%", paddingTop: '10px' }} >
                                            <div className={styles.border} />
                                        </Box>

                                        {
                                            reportTemplate && reportTemplate.hasRecommendation || reportTemplate == null ?
                                                <Box>
                                                    <Box style={{
                                                        height: itemList.lstParameterTemplateItem && itemList.lstParameterTemplateItem.every(e => !e.parameterTemplateItemHeader.isReportRecommendation) ? '130px' : (reportTemplate && reportTemplate.versionComplete == 0 ? 'auto' : '850px'),
                                                        marginTop: "2%"
                                                    }}>
                                                        {itemList.lstParameterTemplateItem ? itemList.lstParameterTemplateItem.map(e => {
                                                            return (
                                                                e.parameterTemplateItemHeader.isReportRecommendation ?
                                                                    <>
                                                                        {e.parameterTemplateItemID !== null && e.listParameterTemplateItemRecommendationDto.length > 0 ?
                                                                            e.listParameterTemplateItemRecommendationDto.map(p => {
                                                                                return (
                                                                                    <Grid item xs={12} style={{ marginTop: "-1%", fontSize: '12px' }}>
                                                                                        <Card style={{ backgroundColor: "white", border: "1px solid #F2F5F8", height: "auto", padding: '6px' }}>
                                                                                            {
                                                                                                
                                                                                                <Box style={{ display: "column" }}>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "1%", marginTop: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={12}>
                                                                                                            <label style={{ fontWeight: 600, }} >{p.title}</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={2}>
                                                                                                            <label style={{ fontWeight: 600 }}>Corretivo do Solo</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2}>
                                                                                                            <label style={{ fontWeight: 600 }}>Quantidade</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={8}>
                                                                                                            <label style={{ fontWeight: 600 }}>Instrução de dosagem</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "1%", marginRight: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                                            <label>{p.recommendation?.description}</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                                            <label>
                                                                                                                {`${addCommas(p.amount)} ${p.recommendation.unitOfMeasure.description} ${p.recommendation.type}`}</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={8} style={{ marginTop: "-1%" }}>
                                                                                                            <label>{p.instruction}</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={12}>
                                                                                                            <label style={{ fontWeight: 600 }}>Observação</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "1%", marginRight: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={12} style={{ marginTop: "-1%" }}>
                                                                                                            <label >{p.observation}</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                            }
                                                                                        </Card>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                            : ''
                                                                        }
                                                                    </>
                                                                    :
                                                                    ''
                                                            )
                                                        }) : ''}
                                                        {handleObservationList(itemList.observationList)}
                                                    </Box >
                                                    <Box style={{ display: "flex", width: "100%", paddingTop: '20px', marginTop: '-18px' }} >
                                                        <div className={styles.border} />
                                                    </Box>
                                                </Box>
                                                :
                                                ''
                                        }

                                        <Box style={{
                                            marginLeft: '1%',
                                            height: reportTemplate && reportTemplate.versionComplete == 0 ? 'auto' : '850px'
                                        }}>
                                            {itemList.lstAnalyseSampleResultInterpretation ? itemList.lstAnalyseSampleResultInterpretation.map(e => {
                                                return (
                                                    <Box className={styles.interpretationElement}>
                                                        {
                                                            itemList.analyseSampleParameterTemplates.parameterTemplate.summaryNPK == true ?
                                                                e.listRecommendationResultDto != undefined && e.listRecommendationResultDto.length > 0 ?
                                                                    <Grid alignItems="flex-start" style={{ marginTop: '5px', marginBottom: '5px !important', width: "100%" }} container spacing={0.5}>
                                                                        <Grid >
                                                                            <label className={styles.tableLabelsHeader}>{reportTemplate && reportTemplate.titleNPK !== null ? reportTemplate.titleNPK : ''}</label>
                                                                        </Grid>
                                                                        {
                                                                            e.recommendationRequestDto ?
                                                                                <Grid item xs={2} style={{ marginTop: index > 0 && index % 2 === 0 ? "5px" : "-1%", fontSize: '12px' }}>
                                                                                    <Card style={{ backgroundColor: "white", border: "1px solid #F2F5F8", height: "auto", padding: '6px' }}>
                                                                                        {
                                                                                            <Box style={{ display: "column" }}>
                                                                                                <Grid style={{ display: "flex", marginLeft: "1%", marginTop: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                    <Grid item xs={12}>
                                                                                                        <label style={{ fontWeight: 600 }}>{'Kg/ha'}</label>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                    <Grid item xs={4}>
                                                                                                        <label>N</label>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <label>P</label>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <label >K</label>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid style={{ display: "flex", marginLeft: "1%", marginRight: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                                    <Grid item xs={4} style={{ marginTop: "-1%" }}>
                                                                                                        <label style={{ fontWeight: 600 }}>{e.recommendationRequestDto.n}</label>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} style={{ marginTop: "-1%" }}>
                                                                                                        <label style={{ fontWeight: 600 }}>{e.recommendationRequestDto.p}</label>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4} style={{ marginTop: "-1%" }}>
                                                                                                        <label style={{ fontWeight: 600 }}>{e.recommendationRequestDto.k}</label>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>
                                                                                        }
                                                                                    </Card>
                                                                                </Grid>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </Grid>

                                                                    :
                                                                    <></>
                                                                :

                                                                e.listRecommendationResultDto != undefined && e.listRecommendationResultDto.length > 0 ?
                                                                    <Grid alignItems="flex-start" style={{ marginTop: '10px', marginRight: "15px !important", marginBottom: '5px !important', width: "100%" }} container spacing={0.5}>
                                                                        <Grid >
                                                                            <label className={styles.tableLabelsHeader}>{reportTemplate && reportTemplate.titleNPK !== null ? reportTemplate.titleNPK : ''}</label>
                                                                        </Grid>
                                                                        {
                                                                            e.listRecommendationResultDto.map((p, index) => {
                                                                                return (
                                                                                    <Grid item xs={6} style={{ marginTop: index > 0 && index % 2 === 0 ? "5px" : "-1%", fontSize: '12px' }}>
                                                                                        <Card style={{ backgroundColor: "white", border: "1px solid #F2F5F8", height: "auto", padding: '6px' }}>
                                                                                            {
                                                                                                <Box style={{ display: "column" }}>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "5px", marginTop: "0" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={12}>
                                                                                                            <label style={{ fontWeight: 600 }}>{p.fertilizerCompositionStage.stage.description}</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "5px" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={2}>
                                                                                                            <label>Kg/ha</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2}>
                                                                                                            <label>N</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2}>
                                                                                                            <label>P2O5</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2}>
                                                                                                            <label>K2O</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={4}>
                                                                                                            <label >Fórmula Sugerida</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid style={{ display: "flex", marginLeft: "1%", marginRight: "5px" }} alignItems="flex-start" container spacing={1}>
                                                                                                        <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                                            <label style={{ fontWeight: 600 }}>{p.total}</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                                            <label style={{ fontWeight: 600 }}>{p.total_N.toFixed(0)}</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                                            <label style={{ fontWeight: 600 }}>{p.total_P.toFixed(0)}</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                                            <label style={{ fontWeight: 600 }}>{p.total_K.toFixed(0)}</label>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={4} style={{ marginTop: "-1%" }}>
                                                                                                            <label style={{ fontWeight: 600 }}>{p.fertilizerCompositionStage.fertilizerComposition.description}</label>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                            }
                                                                                        </Card>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>

                                                                    :
                                                                    <></>
                                                        }
                                                    </Box>
                                                )
                                            })
                                                : ''
                                            }
                                        </Box>


                                        <Box style={{ marginTop: "5px", display: "flex", width: "100%" }}>
                                            <label style={{ fontFamily: "Montserrat", fontSize: 12, color: "#161C24", margin: "0 auto" }} >Responsável Técnico</label>
                                        </Box>
                                        <Box style={{ display: "flex", width: "50%", margin: "0 auto", marginTop: "30px" }} >
                                            <div className={styles.border} />
                                        </Box>
                                        <Box style={{ display: "column", width: "100%" }}>
                                            <Box style={{ display: "flex" }}>
                                                <label style={{ fontFamily: "Montserrat", fontSize: 16, color: "#161C24", margin: "0 auto" }}>{(itemList.analyseSampleReturn && itemList.analyseSampleReturn.agronomist ? itemList.analyseSampleReturn.agronomist.name : "")}</label>
                                            </Box>
                                            <Box style={{ display: "flex" }}>
                                                <label style={{ fontFamily: "Montserrat", fontSize: 12, marginLeft: '10px', color: "#161C24", margin: "0 auto" }}>{itemList.agronomist ? (itemList.agronomist.name) : 'Agrônomo Selecionado'}</label>
                                            </Box>
                                            <Box style={{ display: "flex" }}>
                                                <label style={{ fontFamily: "Montserrat", fontSize: 12, marginLeft: '10px', color: "#161C24", margin: "0 auto" }}>{itemList.agronomist ? (itemList.agronomist.descriptionFunction + " " + itemList.agronomist.crea) : ''}</label>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: "flex", width: "100%" }} >
                                            <div className={styles.border} />
                                        </Box>
                                        <Box style={{ display: "flex", width: "100%", paddingTop: '5px' }}>
                                            <Box style={{ display: "flex", width: "65%", marginLeft: "1%" }}>
                                                <p style={{ fontSize: 7, color: "#000000", margin: "0 auto", fontFamily: 'Montserrat', lineHeight: '15px' }}>
                                                    As interpretações e recomendações apresentadas neste documento são aplicáveis somente a(s) área(s) da(s) amostra(s) analisada(s) conforme laudo de análise de solo emitido por laboratório credenciado a Rede Oficial de Laboratório de Análise de Solos (ROLAS). Os tipos, fórmulas e quantidades de fertilizantes acima indicados são informações referenciais, podendo a parte interessada utilizar fórmulas e dosagens similares disponíveis no mercado, desde que amparada por nova Recomendação para adequação da fertilização.                                                </p>
                                            </Box>
                                            <Box style={{ display: "flex", width: "35%", marginTop: "3%", textAlign: 'right' }}>
                                                <div style={{ float: 'right', margin: "0 0 0 auto" }}>
                                                    <img src={data_results_logo} style={{ margin: "0 auto", maxWidth: '200px', height: 'auto', alignItems: 'right' }} />
                                                </div>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {isLoadingEvents ? <LoadingEvents /> : <></>}
                                </div>
                                {
                                    isDownloadReport ?
                                        ''
                                        :

                                        itemList.analyseSampleReturn.securityCode != null ?

                                        <Box style={{ paddingTop: '20px' }}>
                                            <iframe
                                                title="Relatório Autêntico"
                                                src={'https://online.unisc.br/centralAnalitica/relatoriosUnisc/relatorioautentico.php?codigo=' + itemList.analyseSampleReturn.securityCode + '&lab=1'}
                                                width="100%"
                                                height="500px"
                                                frameBorder="0"
                                                allowFullScreen
                                            />
                                        </Box>

                                        : 
                                        <Box style={{ padding: '20px', textAlign: 'center', fontWeight: 'bold', border: '1px solid black' }}>
                                            Não foi possível encontrar o Laudo do Laboratório. 
                                        </Box>
                                }
                                {
                                    isDownloadReport ?
                                        '' :
                                        ''
                                }
                            </div >)
                    })
                        :
                        <></>
                }
            </div >
        );
    };

    const clickToList = async (id) => {
        history.push({ pathname: "/analyseSample/" })
    }

    return (
        <div style={{ overflowX: 'hidden' }}>
            <div style={{ display: 'flex', margin: '0 auto', width: "794px" }}>
                <Box className={styles.block1} style={{ margin: '0 auto', width: "50%", paddingLeft: 0, paddingTop: '6px' }} >
                    <button className='btn' onClick={() => {
                        history.goBack();
                    }}>
                        <img src={ArrowLeft} className='icon' />
                    </button>
                    <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, paddingTop: '17px', color: "#161C24" }} >Análises</label>
                </Box>
                <div style={{ width: "50%", margin: '0 auto', padding: '20px 0 20px 0', textAlign: 'right' }}>
                    <Button className={styles.button}
                        onClick={clickToList} style={{ marginRight: '10px' }}
                    >
                        <ImUndo2 size={15} />
                        <label style={{ height: "auto", cursor: "pointer", paddingLeft: '6px'}}>Ir para listagem</label>
                    </Button>
                    <Button className={styles.button}
                        onClick={generateAndDownloadPdf}
                    >
                        <img src={download} style={{ width: '8%' }} className='icon' />
                        <label style={{ height: "auto", cursor: "pointer", paddingLeft: '6px' }}>Salvar PDF</label>
                    </Button>
                </div>
            </div>
            {
                isDownloadReport ?
                    '' :
                    <PdfContent />
            }
        </div >
    );
}


export default RecomendationReport;