import React, { useEffect, useState, useRef, forwardRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { useStyles } from './styles';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import TableLoading from '../TableLoading';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import { error_message, success_message } from '../Toast/index.jsx';
import { Box, Grid } from '@material-ui/core';
import CancelButton from '../CancelButton';
import Button from '../Button';
import ButtonBootstrap from 'react-bootstrap/Button';

const TenantForm = () => {
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [TenantReturn, setTenantReturn] = useState([]);
    const { id } = useParams();
    const activeOptions = [
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
    ];
    const [isActive, setIsActive] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [fileByteArray, setFileByteArray] = useState(null);
    const [isAlter, setIsAlter] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)
        Tenant((options) => {
            if (cancel) return
            if (options) {
                setTenantReturn(options)
                setName(options.name)
                if (options.logo) {

                    setSelectedImage('data:image/jpeg;base64,' + options.logo)
                    setFileByteArray(options.logo)
                }
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
            }

        });

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);


    const Tenant = async (callback) => {
        api.get(`Tenant/Get?id=${(id != undefined ? id : "")}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const endTenant = async () => {
        setIsLoading(true)

        if (name == '' || name == undefined) {
            error_message("informe a descrição")
        }
        else {
            try {
                TenantReturn.name = name;
                TenantReturn.logo = fileByteArray;
                const result = await api.post(`/Tenant/Save`, TenantReturn);
                if (result.data.response == true) {
                    success_message("Empresa salva com sucesso!")
                    history.goBack();
                }

            } catch (error) {
                setIsLoading(false)
                exceptionNotificationAPI(error);

            }
        }
        setIsLoading(false)
    }

    const read = async (fileRead) => {
        if (fileRead) {
            setIsAlter(true)
            var reader = new FileReader();
            var fileByteArrayAux = [];
            reader.readAsArrayBuffer(fileRead);
            reader.onloadend = function (evt) {
                if (evt.target.readyState == FileReader.DONE) {
                    var arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < array.length; i++) {
                        fileByteArrayAux.push(array[i]);
                    }
                    setFileByteArray(fileByteArrayAux)
                }
            }
        }
        else {
            setFileByteArray(null)
        }
    }

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            {
                <Box className={styles.container}>
                    <Box className={styles.block1}>
                        <label style={{ fontFamily: "Montserrat", marginTop: "25px", marginLeft: 15, fontWeight: 700, fontSize: 18, color: "#161C24" }} >{"Empresa"}</label>
                    </Box>
                    <Grid style={{ height: "70px", borderRadius: "20px", marginTop: "10px", background: "#e9ebed" }} className={styles.block} container spacing={5}>
                        <Grid style={{ marginTop: "5px" }} item xs={3}>
                            <label className={styles.tableLabelsHeader}>Nome<span className="required" style={{ color: "red" }}> *</span></label>
                        </Grid>
                        <Grid item xs={9}>
                            <input onChange={(t) => { setName(t.target.value) }} type="text" className={styles.input} defaultValue={TenantReturn ? TenantReturn.name : ''} style={{ width: "100%" }}></input>
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "10px" }} className={styles.block} container spacing={5}>
                        <Grid style={{ marginTop: "5px" }} item xs={3}>
                            <label className={styles.tableLabelsHeader}>Ativo</label>
                        </Grid>
                        <Grid item xs={9}>
                            <select name='isActive' value={isActive} className={styles.search} style={{ width: "100%" }} onChange={(e) => { setIsActive(e.target.value) }}>
                                {
                                    activeOptions ?
                                        activeOptions.map(active => {
                                            return <option key={active.value} value={active.value}>{active.text}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select>
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "10px" }} className={styles.block} container spacing={5}>
                        <Grid style={{ marginTop: "5px" }} item xs={2}>
                            <ButtonBootstrap onClick={handleButtonClick}>Selecionar Arquivo</ButtonBootstrap>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(event) => {
                                    if (event.target.files[0]) {
                                        setSelectedImage(event.target.files[0]);
                                        read(event.target.files[0])
                                    }
                                    else {
                                        setSelectedImage(null);
                                        setFileByteArray(null);
                                        read(null);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid style={{ marginTop: "5px" }} item xs={1}>
                            {selectedImage && (
                                <ButtonBootstrap onClick={() => setSelectedImage(null)}>Remover</ButtonBootstrap>
                            )}
                        </Grid>
                        <Grid item xs={9}>
                            {selectedImage && (
                                <div>
                                    <img
                                        width={"250px"}
                                        src={isAlter ? URL.createObjectURL(selectedImage) : selectedImage}
                                    />
                                    <br />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "10px" }} className={styles.block} container spacing={5}>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={1}>
                            <CancelButton />
                        </Grid>
                        <Grid item xs={1}>
                            <Button onClick={() => {
                                endTenant()
                            }}>Salvar</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    );
}

export default TenantForm;
