import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';
import { useAuth } from '../../hooks/auth';
import { ROLE } from '../../config/roles';
import { useParams } from 'react-router-dom';

const UserForm = () => {
    const { userRoles, user } = useAuth();
    const [TenantOptions, setTenantOptions] = useState([]);
    const [rolesOptions, setRolesOptions] = useState(null);
    const [branchsOptions, setBranchsOptions] = useState(null);
    const [supplierOptions, setSupplierOptions] = useState(null);
    const [agronomistOptions, setAgronomistOptions] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [selectedRole, setSelectedRole] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const { id } = useParams();

    const [selectedUser, setSelectedUser] = useState({});

    const handleUserRoleChange = (_roles) => {
        const roleSelect = _roles.find(obj => obj.key === 'RoleAgronomist');
        if (roleSelect !== undefined) {
            let role = rolesList.find((i) => i.id == roleSelect.value);
            let description = role?.normalizedName;
            setSelectedRole(description);
        }
        else {
            setSelectedRole(false);
        }
    };

    const activeOptions = [
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
    ];

    const fields = [
        {
            label: "Nome do usuário",
            name: "userName",
            type: "input"
        },
        {
            label: "Empresa",
            name: "tenantID",
            type: "select",
            options: TenantOptions,
            required: false,
            visible: userRoles.includes(ROLE.ADMIN)
        },
        {
            label: "Laboratório",
            name: "supplierID",
            type: "select",
            options: supplierOptions,
            required: false,
            visible: userRoles.includes(ROLE.ADMIN)
        },
        {
            label: "Primeiro nome",
            name: "firstName",
            type: "input"
        },
        {
            label: "Último nome",
            name: "lastName",
            type: "input"
        },
        {
            label: "Permissão aplicativo",
            name: "isMobile",
            type: "select",
            options: activeOptions
        },
        {
            label: "Permissão web",
            name: "isWeb",
            type: "select",
            options: activeOptions
        },
        {
            label: "E-mail",
            name: "email",
            type: "input"
        },
        {
            label: "Senha",
            name: "password",
            editable: false,
            type: "password"
        },
        {
            label: "Confirme sua senha",
            name: "confirmPassword",
            editable: false,
            type: "password"
        },
        {
            label: "Filiais",
            name: "branchs",
            type: "select",
            options: branchsOptions,
            multiple: true,
            required: false,
        },
        {
            label: "Função",
            name: "roles",
            type: "select",
            options: rolesOptions,
            multiple: true,
            required: false,
            onChanged: handleUserRoleChange,
        },
        {
            label: "Responsável/Agrônomo",
            name: "agronomistID",
            type: "select",
            options: agronomistOptions,
            required: selectedRole == ROLE.AGRONOMIST,
            visible: selectedRole == ROLE.AGRONOMIST
        }
    ]

    const loadData = async () => {
        Api.get('Tenant/isTenant').then((result) => {
            if (result.data.response) {
                setIsVisible(true)
            }
            else {
                setIsVisible(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get('Tenant/getAllItems').then((result) => {
            if (result && result.data) {
                var options = result.data.response.map(item => ({ value: item.tenantID, text: item.name }));
                options = [{ value: "", text: "Selecione" }, ...options];
                setTenantOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get('Supplier/getAllItems').then((result) => {
            if (result && result.data) {
                var options = result.data.response.map(item => ({ value: item.supplierID, text: item.description }));
                options = [{ value: "", text: "Selecione" }, ...options];
                setSupplierOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get('User/getAllRoles').then((result) => {
            if (result && result.data) {
                const rolesOptions = result.data.response.map(item => ({ value: item.id, text: item.description, key: item.name }));
                setRolesOptions(rolesOptions);
                setRolesList(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        Api.get('Branch/GetAllBranchs').then((result) => {
            if (result && result.data) {
                const branchsOptions = result.data.response.map(item => ({ value: item.branchID, text: item.description, key: item.cnpj }));
                setBranchsOptions(branchsOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        var url = '';
        if (id !== undefined && id !== null) {
            url = 'Agronomist/GetAllItemsNotUser?id=' + id
        }
        else {
            url = 'Agronomist/GetAllItemsNotUser'
        }
        Api.get(url).then((result) => {
            if (result && result.data) {
                var options = result.data.response.map(item => ({ value: item.agronomistID, text: item.name }));
                options = [{ value: "", text: "Selecione" }, ...options];
                setAgronomistOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (selectedUser && selectedUser.roles)
            selectedUser.roles.forEach(function (role) {
                if (role.normalizedName) {
                    if (role.normalizedName == ROLE.AGRONOMIST) {
                        setSelectedRole(role.normalizedName);
                    }
                }
            });
    }, [selectedUser]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        branchsOptions != null && rolesOptions != null && TenantOptions.length > 0 && supplierOptions != null && supplierOptions.length > 0 && agronomistOptions != null && agronomistOptions.length > 0 ? <FormCrud formName={"user"} title={"Usuário"} fields={fields} setSelected={setSelectedUser} selectedRole={selectedRole} /> : <></>
    );
}

export default UserForm;
