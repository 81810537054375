import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";

export default function ModalFormAnalyseTypes({ newModal, setShow, analyseTypes, ...props }) {
  const [selectedAnalyseType, setSelectedAnalyseType] = useState();
  const [analyseType, setAnalyseType] = useState();

  useEffect(() => {
    if (analyseTypes && analyseTypes[0] && selectedAnalyseType == undefined) {
      setSelectedAnalyseType(analyseTypes[0].analyseTypeID)
    }
  }, [analyseTypes]);

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Nova análise</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <label className="field">Análises</label>
          <div className="div-form">
            <select name='analyseTypes' value={analyseType} onChange={(e) => {
              setSelectedAnalyseType(e.target.value)
            }}>
              {
                analyseTypes ?
                  analyseTypes.map(analyseType => {
                    return <option key={analyseType.analyseTypeID} value={analyseType.analyseTypeID}>{analyseType.description}</option>
                  })
                  :
                  <></>
              }
            </select>
          </div>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => { newModal(selectedAnalyseType) }} >Confirmar</button>
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}