import React, { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { useLocation, useHistory } from 'react-router-dom';
import api, { exceptionNotificationAPI } from '../../services/api';
import AddIcon from '@mui/icons-material/Add';
import binRed from '../../assets/icons/binRed.png';
import TableLoading from '../TableLoading';
import { useAuth } from '../../hooks/auth';
import { ROLE } from '../../config/roles';
import { Col, Container, Row } from 'react-bootstrap';
import Card from '@mui/material/Card';

const AnalyseSampleDetailItemInterpretation = ({ sampleData, place, list, analyseSampleID, listItens }) => {
    const history = useHistory();
    const { userRoles, user } = useAuth();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [parameterTemplate, setParameterTemplate] = useState();
    const [parameterTemplates, setParameterTemplates] = useState();
    const [selectedParameterTemplate, setSelectedParameterTemplate] = useState();
    const [lstParameterTemplates, setLstParameterTemplates] = useState([]);
    const [parameterTemplatesAux, setParameterTemplatesAux] = useState([]);

    useEffect(() => {
        let cancel = false;

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)
        loadParameterTemplates((options) => {
            if (options.item1.length > 0)
            {
                setParameterTemplates(options.item1)
                setSelectedParameterTemplate(options.item1[0].parameterTemplateID)
                setParameterTemplate(options.item1[0].parameterTemplateID)
            }
            if (options.item2.length > 0)
            {
                setParameterTemplatesAux(options.item2)
            }



            loadConsultAnalyseSampleParameterTemplate((optionsAnalyseSampleParameterTemplate) => {
                if (optionsAnalyseSampleParameterTemplate) {
                    setLstParameterTemplates(optionsAnalyseSampleParameterTemplate)
                }
                setIsLoading(false)
            })

            cancel = true;
        })


        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, [place, list, analyseSampleID, listItens]);

    const loadParameterTemplates = async (callback) => {
        api.get(`AnalyseSample/getAllItemsParameterTemplate?id=${analyseSampleID}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadConsultAnalyseSampleParameterTemplate = async (callback) => {
        api.get(`AnalyseSample/ConsultAnalyseSampleParameterTemplate?id=${analyseSampleID}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const createAnalyseSampleParameterTemplate = async (parameterTemplateId) => {
        setIsLoading(true)
        
        api.get(`AnalyseSample/CreateAnalyseSampleParameterTemplate?id=${analyseSampleID}&&idAux=${(parameterTemplateId)}`).then((result) => {
            if (result && result.data) {
                setLstParameterTemplates(result.data.response);
            }
            setIsLoading(false)
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setIsLoading(false)
        });
    }

    const removeAnalyseSampleParameterTemplate = async (parameterTemplate) => {
        setIsLoading(true)
        api.get(`AnalyseSample/RemoveAnalyseSampleParameterTemplate?id=${analyseSampleID}&&idAux=${(parameterTemplate.parameterTemplateID)}`).then((result) => {
            if (result && result.data) {
                setLstParameterTemplates(result.data.response);
            }
            setIsLoading(false)
        }).catch((error) => {
            exceptionNotificationAPI(error);
            setIsLoading(false)
        });
    }

    const editAnalyse = async (e) => {
        history.push({ pathname: "/analyseSample/details/interpretationRecommendations", state: { analyseSampleID: analyseSampleID, parameterTemplateID: e.parameterTemplateID, analyseSampleParameterTemplateID: e.analyseSampleParameterTemplateID, list: listItens } })
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
    };

    if (isLoading) {
        return (
            <Box style={{ width: "90%" }}>
                <TableLoading />
            </Box>
        )
    }

    return (
        <>
            {
                <Container>
                    {lstParameterTemplates.length === 0 && (
                        <>
                            <br />
                            <Row className={styles.errorMsg}>
                                <Col md={12}>
                                    <label>Com base nas informações da amostra, veja as interpretações sugeridas:</label>
                                </Col>
                            </Row>
                            <br />
                            <Row className={styles.errorMsg}>
                                <Col md={12}>
                                    {parameterTemplatesAux.length > 0 && (
                                        parameterTemplatesAux.map((item, index) => (
                                            <Card key={index} style={{ backgroundColor: '#F2F5F8', padding: '10px', marginBottom: '10px' }}>
                                                <Row className={styles.errorMsg}>
                                                    <Col md={3} className="d-flex align-items-center">
                                                        <label>{item.description}</label>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Button variant="contained" className={styles.button} onClick={() => { createAnalyseSampleParameterTemplate(item.parameterTemplateID) }}>
                                                            Gerar Interpretação
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                    {lstParameterTemplates.length >= 1 && (
                        <Row className={styles.header}>
                            <Col md={6} lg={6} xs={6}>
                                <label className={styles.tableLabelsHeader} style={{ color: '#737D86' }}>Tipo de Interpretação</label>
                            </Col>
                            <Col md={4} lg={4} xs={4}>
                                <label className={styles.tableLabelsHeader} style={{ color: '#737D86' }}>Cultura</label>
                            </Col>
                            <Col md={2} lg={2} xs={2}>
                            </Col>
                        </Row>
                    )}

                    {
                        lstParameterTemplates.filter(function (element) { return element.isDeleted == false; }).map((e, i) => {
                            return (
                                <Row key={e.analyseSampleParameterTemplateID} className={styles.tableRows}
                                    onClick={() => {
                                        editAnalyse(e)
                                    }}
                                    style={{ background: i % 2 === 0 ? '' : 'white' }}>
                                    <Col md={6} lg={6} xs={6}>
                                        <label className={styles.tableLabels}>{e.parameterTemplate.description}</label>
                                    </Col>
                                    <Col md={4} lg={4} xs={4}>
                                        <label className={styles.tableLabels}>{e.parameterTemplate.culture.name}</label>
                                    </Col>
                                    <Col md={2} lg={2} xs={2} style={{ textAlign: "right" }}>
                                        {((userRoles.includes(ROLE.AGRONOMIST) || userRoles.includes(ROLE.ADMIN)) && sampleData.status != 5) ?
                                            <button className='btn' onClick={handleButtonClick} onClickCapture={() => {
                                                removeAnalyseSampleParameterTemplate(e)

                                            }}>
                                                <img src={binRed} className='icon' />
                                            </button>
                                            : <></>
                                        }
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    {(userRoles.includes(ROLE.AGRONOMIST) || userRoles.includes(ROLE.ADMIN)) ?
                        <>
                            <Row>
                                <Col md={12} lg={12} xs={12} style={{ marginTop: '30px' }}>
                                    <label className={styles.tableLabelsHeader}>Nova Interpretação</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col md={4} lg={4} xs={8}>
                                    <select name='parameterTemplates' value={selectedParameterTemplate} text={parameterTemplate} className={styles.search} onChange={(e) => { setSelectedParameterTemplate(e.target.value) }}>
                                        {
                                            parameterTemplates ?
                                                parameterTemplates.map(parameterTemplate => {
                                                    return <option key={parameterTemplate.parameterTemplateID} value={parameterTemplate.parameterTemplateID}>{parameterTemplate.description}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </Col>
                                <Col md={3} lg={3} xs={4}>
                                    <Button variant="contained" className={styles.button}
                                        startIcon={
                                            <AddIcon htmlColor="white" />
                                        }
                                        onClick={() => {
                                            createAnalyseSampleParameterTemplate(selectedParameterTemplate)
                                        }}
                                    >
                                        Gerar Interpretação
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        :
                        <></>
                    }
                </Container>
            }
        </>
    );
}

export default AnalyseSampleDetailItemInterpretation;
