import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const ContainerHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 42px;
    
`;



export const useStyles = makeStyles({
    container: {
        marginTop: 10,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    divlabel:{
        marginBottom: "25px",
        marginTop: "25px",
        alignItems: "end",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    block1: {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: 30,
        width: "100%",
    },
    block2: {
        marginTop: 30,
        width: "100%",
        flexDirection: "column",
        borderRadius: 8
    },
    search: {
        border: "1px solid rgba(145, 158, 171, 0.32)",
        borderRadius: 8,
        height: 44,
        paddingLeft: 20,
        alignItems: "center",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: 14,
    },
    button: {
        marginLeft: 20,
        borderRadius: 8,
        height: 44,
        paddingLeft: 20,
        paddingRight: 20,
        fontFamily: "Montserrat",
        color: "white",
        fontWeight: 700,
        fontSize: 14,
        textTransform: "none"
    }

});

export const Table = styled.table`
    font-size: 13px;
    margin-top: 42px;

    thead{
        background-color: #0c8662;
        height: 40px;

        tr{
            th{
                text-align: center;
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
            }

            th:first-child{
                border-radius: 8px 0px 0px 0px;
            }

            th:last-child{
                border-radius: 0px 8px 0px 0px;
            }
        }
    }

    tbody{
        background-color: #FFFFFFFF;
        box-shadow: 0px 3px 20px #0000000d;

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            td{
                text-align: center;
                padding: 14px;

                &:last-child{
                    .btn{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;

                        svg{
                            color: #ec632c;
                            
                            &:hover{
                                color: #0c8662;
                            }
                        }

                        &:focus{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }

                        &:active{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }
                    }

                    .dropdown-menu{
                        box-shadow: 0px 3px 20px #00000026;
                        border: 1px solid #ECECF3;
                        border-radius: 4px;
                        font-size: 13px;

                        .dropdown-item{
                            padding-left: 19px;
                            padding-right: 19px;

                            &:active{
                                background-color: #0c8662;
                            }
                        }
                    }
                }
            }

            .no-records{
                text-align: center;
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }
        }

        
    }
`;
