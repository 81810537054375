import React, { useEffect, useState, useRef, forwardRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box, InputBase, InputAdornment, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { useLocation, useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import TableLoading from '../TableLoading';
import moment from 'moment';
import MenuTableEditRemove from '../MenuTableEditRemove';
import DatePicker, { registerLocale } from 'react-datepicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { error_message, success_message } from '../Toast/index.jsx';
import { Col, Row } from 'react-bootstrap';
import { Container } from '@mui/material';
import ModalConfirmClone from '../ModalConfirmClone';

const InterpretationList = () => {
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [showModalConfirmClone, setShowModalConfirmClone] = useState(false);
    const [parameterTemplateID, setParameterTemplateID] = useState(false);

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)

        if (cancel) return
        loadTable(1, perPage, (response) => {
            if (cancel) return
            setTotalRows(response.recordsTotal)
            setList(response.data)
            setIsLoading(false)
        });

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    const removeInterpretation = async (id) => {
        setIsLoading(true)
        api.post('Interpretation/delete?id=' + id).then((result) => {
            if (result.data.statusCode == 200) {
                setIsLoading(false)
                setRefresh(true)
                loadTable(1, 10, (response) => {
                    setTotalRows(response.recordsTotal)
                    setList(response.data)
                    setIsLoading(false)
                })
            }
        }).catch((error) => {
            setIsLoading(false)
            exceptionNotificationAPI(error);
        });
    }

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const loadTable = async (page = 1, skip = 10, callback) => {
        api.get(`Interpretation/GetAll?page=${page}&&skip=${skip}&&SearchText=${searchText}
                &&startDate=${startDate ? moment(startDate).format("MM/DD/yyyy") : ""}
                &&endDate=${endDate ? moment(endDate).format("MM/DD/yyyy") : ""}`).then((result) => {
            if (result && result.data && result.data.response) {
                const options = result.data.response

                setPage(page);
                setPages(result.data.response.pages)

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const InputDate = forwardRef(({ value, onClick }, ref) => (
        <InputBase ref={ref} className={styles.search} placeholder={"Período"} style={{ background: "white", marginLeft: 30, paddingRight: 20 }} value={value} onClick={onClick}
            startAdornment={
                <InputAdornment position="start">
                    <DateRangeIcon htmlColor='#5A646E' />
                </InputAdornment>
            }
        />
    ));

    const editInterpretation = async (id) => {
        history.push({ pathname: "/interpretation/details", state: { id: id } })
    }

    const fertilizerComposition = async (id) => {
        history.push({ pathname: "/settingsComposition/details", state: { id: id } })
    }

    const cloneTemplate = async (id) => {
        setShowModalConfirmClone(true)
        setParameterTemplateID(id)
    }

    const newModalConfirmClone = async (type, description) => {
        if (description)
        {
            if (type === true) {
                setIsLoading(true)
                const result = await api.post(`Interpretation/clone?id=${parameterTemplateID}&&description=${description}`);
                if (result.data.response !== null) {
                    success_message("Parâmetro salvo com sucesso!")
                    loadTable(1, perPage, (response) => {
                        setTotalRows(response.recordsTotal)
                        setList(response.data)
                        setShowModalConfirmClone(false)
                        setIsLoading(false)
                    });
                }
            }
        }
    }

    const viewRecomendation = async (id) => {
        history.push({ pathname: "/recommendationRegister", state: { id: id } })
    }

    return (<>
            <Container>
                <Row style={{marginTop: '30px'}}>
                    <Col md={12} lg={12}>
                        <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Modelos de Interpretações"}</label>
                    </Col>
                </Row>
                <Row style={{marginTop: "40px"}}>
                    <Col md={8} lg={8} xs={12}>
                        <InputBase className={styles.search} value={searchText} placeholder={"Buscar..."} style={{ background: "white" }} onChange={(e) => {
                            setSearchText(e.target.value)
                        }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon htmlColor='#5A646E' />
                                </InputAdornment>
                            }
                        />
                    </Col>
                    <Col md={2} lg={2} xs={12}>
                        <Button className={styles.button} style={{ marginLeft: "auto" }}
                            onClick={() => {
                                setIsLoading(true)
                                loadTable(1, perPage, (response) => {
                                    setTotalRows(response.recordsTotal)
                                    setList(response.data)
                                    setIsLoading(false)
                                })
                            }}
                        >
                            Buscar
                        </Button>
                    </Col>
                    <Col md={2} lg={2} xs={12}>
                        <Button className={styles.button}
                            startIcon={
                                <AddIcon htmlColor="white" />
                            }
                            onClick={() => {
                                history.push({ pathname: "/interpretation/details" })
                            }}
                        >
                            Novo
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container className={styles.pagewhite}>
                <Row>

                    <Box className={styles.header}>
                        <label className={styles.tableLabelsHeader} style={{ width: "25%" }}>Descrição</label>
                        <label className={styles.tableLabelsHeader} style={{ width: "35%" }}>Empresa</label>
                        <label className={styles.tableLabelsHeader} style={{ width: "15%" }}>Cultura</label>
                        <label className={styles.tableLabelsHeader} style={{ width: "15%" }}>Tipo de Análise</label>
                        <label className={styles.tableLabelsHeader} style={{ width: "5%" }}>status</label>
                        <label className={styles.tableLabelsHeader} style={{ width: "5%" }}></label>
                    </Box>
                    {
                        list.map(e => {
                            return (
                                <Box key={e.parameterTemplateID} className={styles.tableRows} >
                                    <label className={styles.tableLabels} style={{ width: "25%" }}>{e.description}</label>
                                    <label className={styles.tableLabels} style={{ width: "35%" }}>{e.tenant == null ? "não cadastrado" : e.tenant.name}</label>
                                    <label className={styles.tableLabels} style={{ width: "15%" }}>{e.culture == null ? "não cadastrado" : e.culture.name}</label>
                                    <label className={styles.tableLabels} style={{ width: "15%" }}>{e.analyseType == null ? "não cadastrado" : e.analyseType.description}</label>
                                    <label className={styles.tableLabels} style={{ width: "5%" }}>{e.isActive == true ? "Ativo" : "Inativo"}</label>
                                    <MenuTableEditRemove style={{ width: "5%" }} id={e.parameterTemplateID} editFunction={editInterpretation} fertilizerComposition={fertilizerComposition} cloneTemplate={cloneTemplate} deleteFunction={removeInterpretation} isPrint={true} isFertilizerComposition={true} isClone={true} textPrint={"Recomendação"} printFunction={viewRecomendation}/>
                                </Box>
                            )
                        })
                    }
                </Row>
                <Row>
                    <div className={styles.divlabel} >
                        <button
                            className="border rounded p-1" style={{ marginRight: '5px' }}
                            onClick={() => {
                                loadTable(1, perPage, (response) => {
                                    setIsLoading(true)
                                    setTotalRows(response.recordsTotal)
                                    setList(response.data)
                                    setIsLoading(false)
                                })
                            }}
                        >
                            {'Primeira'}
                        </button>
                        <button
                            className="border rounded p-1" style={{ marginRight: '5px' }}
                            onClick={() => {
                                if (page > 1) {
                                    loadTable(page - 1, perPage, (response) => {
                                        setIsLoading(true)
                                        setTotalRows(response.recordsTotal)
                                        setList(response.data)
                                        setIsLoading(false)
                                    })
                                }
                            }}
                        >
                            {'Anterior'}
                        </button>
                        <button
                            className="border rounded p-1" style={{ marginRight: '5px' }}
                            onClick={() => {
                                if (page < pages) {
                                    loadTable(page + 1, perPage, (response) => {
                                        setIsLoading(true)
                                        setTotalRows(response.recordsTotal)
                                        setList(response.data)
                                        setIsLoading(false)
                                    })
                                }
                            }}
                        >
                            {'Próxima'}
                        </button>
                        <button
                            className="border rounded p-1" style={{ marginRight: '5px' }}
                            onClick={() => {
                                loadTable(pages, perPage, (response) => {
                                    setIsLoading(true)
                                    setTotalRows(response.recordsTotal)
                                    setList(response.data)
                                    setIsLoading(false)
                                })
                            }}
                        >
                            {'Última'}
                        </button>
                        <span className={styles.container} style={{ marginRight: '5px', marginLeft: '20px', lineHeight: '30px' }}>
                            <div>Página(s)
                                <strong style={{ marginLeft: '5px' }}>
                                    {page} de {' '}
                                    {pages}
                                </strong>
                            </div>

                        </span>
                        <select
                            style={{ border: "1px solid rgba(145, 158, 171, 0.32)", padding: '5px', borderRadius: "8px" }}
                            className={styles.container}
                            onChange={e => {
                                setPerPage(Number(e.target.value))
                                loadTable(1, Number(e.target.value), (response) => {
                                    setIsLoading(true)
                                    setTotalRows(response.recordsTotal)
                                    setList(response.data)
                                    setIsLoading(false)
                                })
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                pageSize == perPage ?
                                    <option key={perPage} value={perPage} selected="selected">
                                        Mostrar {perPage}
                                    </option>
                                    :
                                    <option key={pageSize} value={pageSize}>
                                        Mostrar {pageSize}
                                    </option>
                            ))}
                        </select>
                    </div>
                </Row>
            </Container>
            <ModalConfirmClone show={showModalConfirmClone} setShow={setShowModalConfirmClone} newModalConfirmClone={newModalConfirmClone} />
            </>
        );
}

export default InterpretationList;
