import React from 'react';
import { useHistory } from 'react-router-dom';
import AnalyseSampleDetail from '../../components/AnalyseSampleDetail';

import { Container, Card } from './styles';

const AnalyseSampleDetails = () => {
    const history = useHistory();

    return (
        <AnalyseSampleDetail/>
        
    );
}

export default AnalyseSampleDetails;
