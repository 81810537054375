import React, { useEffect, useState, } from 'react';
import { useHistory ,useLocation } from 'react-router-dom';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const FarmerForm = () => {   
    const [isTenant, setIsTenant] = useState(false);
    const location = useLocation();
    
    const activeOptions = [
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
    ];

    console.log();

    const farmers = [
        {
            label: "Nome",
            name: "name",
            type: "input",
            disabled : isTenant,
        },
        {
            label: "Localidade",
            name: "place",
            type: "input",
            disabled : isTenant,
        },
        {
            label: "Documento",
            name: "documentNumber",
            type: "input",
            disabled : isTenant,
        },
        /*{
            label: "Código externo",
            name: "externalCode",
            type: "input",
            //disabled : isTenant,
            required: false,
        },*/
        {
            label: "Identificação",
            name: "identificationNumber",
            type: "input",
        },
        {
            label: "Ativo",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ]

    const getTenant = async () => {
        Api.get('Tenant/isTenant').then((result) => {
            if (result && result.data) {
                if(!location.pathname.includes('new')){
                    setIsTenant(result.data.response);
                }                
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        getTenant();
    }, []);

    return (
        <FormCrud formName={"farmer"} title={"Produtor"} fields={farmers} />
    );
}

export default FarmerForm;
