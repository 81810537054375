import React, { useEffect, useState } from 'react';
import TableCrud from '../../components/TableCrud';
import Api, { exceptionNotificationAPI } from '../../services/api';

const BranchIndex = () => {
    const [isVisible, setIsVisible] = useState(false);

    const columns = [
        {
            label: "Descrição",
            name: "description"
        },
        {
            label: "Empresa",
            name: "tenant.name",
            visible: isVisible
        },
        {
            label: "Ativo",
            name: "isActive"
        },

    ]
    const loadData = async () => {
        Api.get('Tenant/isTenant').then((result) => {
            if (result.data.response) {
                setIsVisible(true)
            }
            else{
                setIsVisible(false)      
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
        
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <TableCrud title={"Filial"} table={"branch"} columns={columns}/>
    );
}

export default BranchIndex;
