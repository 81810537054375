import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Container, Footer } from './styles';
import { FaSort } from 'react-icons/fa';

function ModalFormOrdering({ newModalOrdering, listParameterTemplateItemHeaderLast, setShow, ...props }) {
  const [items, setItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);

  useEffect(() => {
    if (listParameterTemplateItemHeaderLast) {
      setItems(listParameterTemplateItemHeaderLast);
      setOriginalItems(listParameterTemplateItemHeaderLast); // Armazenar a ordem original dos itens
    }
  }, [listParameterTemplateItemHeaderLast]);

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("itemIndex", index);
  };

  const onDrop = (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData("itemIndex");
    const itemDragged = items[dragIndex];
    const itemsTemp = [...items];
    itemsTemp.splice(dragIndex, 1);
    itemsTemp.splice(dropIndex, 0, itemDragged);

    const updatedItems = itemsTemp.map((item, index) => ({ ...item, sequence: index + 1 }));
    setItems(updatedItems);
  };

  const handleCancel = () => {
    // Restaurar a ordem original dos itens ao clicar em "Cancelar"
    setItems(originalItems);
    setShow(false);
  };

  return (
    <Modal
      {...props}
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Container>
          <label className="title">Ordenação</label>
        </Container>
      </Modal.Header>
      <Modal.Body onDragOver={onDragOver} style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
        {items.map((item, index) => (
          <div
            key={item.id}
            draggable
            onDragStart={(e) => onDragStart(e, index)}
            onDrop={(e) => onDrop(e, index)}
            style={{ padding: '10px', margin: '5px', backgroundColor: '#f0f0f0', cursor: 'move', display: 'flex', alignItems: 'center' }}
          >
            <span style={{ marginRight: '5px' }}>{index + 1} - {item.description}</span>
            <FaSort style={{ position: 'absolute', left: 'calc(100% - 10%)' }} />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={handleCancel}>Cancelar</button>
            <button className="btn button-confirm" onClick={() => {
              newModalOrdering(items);
              setShow(false);
            }}>Confirmar</button>
          </div>
        </Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFormOrdering;
