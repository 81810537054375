import React from 'react';
import { useHistory } from 'react-router-dom';
import SettingsCompositionDetail from '../../components/SettingsCompositionDetail';

import { Container, Card } from './styles';

const SettingsCompositionDetails = () => {
    const history = useHistory();

    return (
        <SettingsCompositionDetail/>
        
    );
}

export default SettingsCompositionDetails;
