import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { Container } from './styles';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useAuth } from '../../hooks/auth';

import Api, { exceptionNotificationAPI } from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { error_message } from '../Toast';

import Button from '../Button';
import FormInput from '../FormInput';
import CancelButton from '../CancelButton';
import { ROLE } from '../../config/roles';

const FormCrud = ({ fields, title, formName, multiple = false, setSelected = null, selectedRole = null }) => {
    const history = useHistory();
    const { updateCrop } = useAuth();
    const formRef = useRef(null);
    const { id } = useParams();
    const [data, setData] = useState(null);

    const [multipleValues, setMultipleValues] = useState([]);
    const [multipleValuesBranchs, setMultipleValuesBranchs] = useState([]);
    const [selectCustomValue, setSelectCustomValue] = useState([]);
    const [selectCustomInput, setSelectCustomInput] = useState("");
    const [disabledCustomSelect, setDisabledCustomSelect] = useState(true);
    const [formUserRole, setFormUserRole] = useState(null);
    const [isNew, setIsNew] = useState(true);

    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];

            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                return "";
            }
        }

        return currentObject;
    }
    

    const loadData = async () => {
        await Api.get(`${formName}/get?id=${id}`).then((result) => {
            if (result && result.data) {
                var dataObj = {};
                var TenantOptions = [];

                if (setSelected)
                    setSelected(result.data.response);

                if (formName == "user")
                    setFormUserRole(result.data.response["role"]?.normalizedName);

                fields.forEach(f => {
                    if (f.name == "roles") {
                        var dataMultiple = result.data.response[f.name].map((sc) => {
                            var data = {
                                value: sc.id,
                                label: sc.description,
                                key: sc.name
                            };
                            return data;
                        });
                        setMultipleValues(dataMultiple);
                    }
                    else if (f.name == "branchs") {
                        var dataMultiple = result.data.response[f.name].map((sc) => {
                            var data = {
                                value: sc.branchID,
                                label: sc.description,
                                key: sc.cnpj
                            };
                            return data;
                        });
                        setMultipleValuesBranchs(dataMultiple);
                    } else if (f.name == "customOptions") {
                        if (result.data.response["customOptions"] != null) {
                            var exist = result.data.response["customOptions"].indexOf(";") > -1;
                            if (exist) {
                                var dataMultiple = result.data.response["customOptions"].split(";");
                                var newDataMulti = dataMultiple.map((e) => {
                                    var newData = {
                                        "value": e,
                                        "label": e
                                    }
                                    return newData;
                                });
                                if (result.data.response["unitMeasureID"] == 24)
                                    setDisabledCustomSelect(false);
                                else
                                    setDisabledCustomSelect(true);

                                setSelectCustomValue(newDataMulti);
                            } else {
                                if (result.data.response["unitMeasureID"] == 24)
                                    setDisabledCustomSelect(false);
                                else
                                    setDisabledCustomSelect(true);

                                setSelectCustomValue([]);
                            }
                        }
                        else {
                            if (result.data.response["unitMeasureID"] == 24)
                                setDisabledCustomSelect(false);
                            else
                                setDisabledCustomSelect(true);

                            setSelectCustomValue([]);
                        }
                    } else {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name];

                            if (formName == "user") {
                                if (f.name == "TenantID") {
                                    dataObj[f.name] = dataObj[f.name] !== null ? dataObj[f.name] : "";
                                }
                            }
                        }
                    }
                });

                formRef.current.setData(dataObj);
                setData(dataObj);

                //When access CRUD form to Edit an existent KPI
                if (formName === "user") {
                    //document.getElementById('TenantID').disabled = document.getElementById('roleID').value === "23D9D409-D7AA-4966-9047-48C04B41F0A1" /*Admin*/ ? true : false;

                    /*if (document.getElementById('roleID').value === "BA6C43EB-7334-463B-B5CB-D712BD29339D") { //InfoHolder
                        document.getElementById('TenantID').length = 0;

                        TenantOptions.map((item) => {
                            document.getElementById('TenantID').append(new Option(item.text, item.value));
                        });

                        document.getElementById('TenantID').value = dataObj['TenantID'];
                    }*/
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (data === null && id != null) {
            loadData();
        }
        else if (formName == 'bee') {
            loadBarcode(1)
        }
        else if (formName == 'thermalBag') {
            loadBarcode(2)
        }
    }, [id]);

    useEffect(() => {
        if (selectedRole == ROLE.AGRONOMIST && id != null) {
            if (data == null)
                loadData();
            else
                formRef.current.setData(data);
        }
    }, [selectedRole]);

    const loadBarcode = async (condition) => {
        Api.get('SequenceCrop/GetById?condition=' + condition).then((result) => {
            if (result && result.data && result.data.response) {

                if (formName == 'bee') {
                    document.getElementById('barcode').value = result.data.response;
                }
                else if (formName == 'thermalBag') {
                    document.getElementById('barcode').value = 'BOL' + result.data.response;
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const handleSubmit = async (data) => {
        try {
            var obj = {}
            var postObj = {};
            const submitUrl = `${formName}/${(id != null) ? "edit" : "new"}`;

            if (formName === "user")
                postObj["Id"] = id;
            else
                postObj[formName + "ID"] = id;

            fields.map(f => {
                if (f.required !== undefined && f.required === false) {
                    if (f.name === "calculation") {
                        if (data["inputCalc"] === "Calculation") {
                            var calculationFields = ["firstValue", "operation", "secondValue"];

                            calculationFields.map(item => {
                                return obj[item] = Yup.string().required(`${f.label} is required`);
                            });
                        }

                        return null;
                    } else {
                        return null;
                    }
                }
                else {
                    if (f.editable !== undefined && f.editable === false) {
                        return null;
                    } else {
                        return obj[f.name] = Yup.string().required(`${f.label} is required`);
                    }
                }
            })

            const schema = Yup.object().shape(obj);

            await schema.validate(data, {
                abortEarly: false,
            });

            fields.map(f => {
                return postObj[f.name] = data[f.name]
            })

            if (selectCustomValue.length > 0) {
                postObj.customOptions = selectCustomValue.map((e) => { return e.value }).join(';');
            }

            if (multipleValues.length > 0) {
                postObj.roles = multipleValues.map((m) => {
                    var data = {
                        ID: m.value
                    }
                    return data;
                });
            }
            if (multipleValuesBranchs.length > 0) {
                postObj.branchs = multipleValuesBranchs.map((m) => {
                    var data = {
                        BranchID: m.value
                    }
                    return data;
                });
            }

            var result = await Api.post(submitUrl, postObj);

            if (result && result.data && result.data.statusCode == 400) {
                error_message(result.data.response)
            } else {

                if (formName === "crop" && postObj && postObj.isActive == "true") {
                    await updateCrop(postObj.cropYear)
                }

                if (formName === "unitMeasure") {
                    history.push(`/answerTypeKPI`);
                } else {
                    history.push(`/${formName}`);
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);

                formRef.current.setErrors(errors);

                return;
            }

            exceptionNotificationAPI(error);
        }
    }

    return (
        <Container className="container">
            <div>
                <h3>{(id != null) ? "Editar" : "Novo"} {title}</h3>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <table className="table table-striped">
                        <tbody>
                            {
                                fields.map((f, i) => {
                                    if (f.visible == undefined || f.visible == true) {
                                        if (f.type === "selectcustom") {
                                            return <>
                                                <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                    <td>
                                                        <label>
                                                            {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <CreatableSelect
                                                            name="customOptions"
                                                            id="customOptions"
                                                            isDisabled={disabledCustomSelect}
                                                            components={{ DropdownIndicator: null }}
                                                            isClearable={true}
                                                            isMulti
                                                            menuIsOpen={false}
                                                            placeholder="Type something and press enter..."
                                                            onChange={(e) => { setSelectCustomValue(e); }}
                                                            onInputChange={(e) => setSelectCustomInput(e)}
                                                            onKeyDown={(e) => {
                                                                switch (e.key) {
                                                                    case 'Enter':
                                                                    case 'Tab':
                                                                        if (selectCustomInput == "")
                                                                            return;
                                                                        var newData = {
                                                                            "value": selectCustomInput,
                                                                            "label": selectCustomInput
                                                                        }
                                                                        var newArray = [...selectCustomValue, newData];
                                                                        setSelectCustomInput("");
                                                                        setSelectCustomValue(newArray);
                                                                        e.preventDefault();
                                                                }
                                                            }}
                                                            value={selectCustomValue}
                                                            inputValue={selectCustomInput}
                                                        />
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                        else if (f.type === "select") {
                                            return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td width="25%">
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td width="75%">
                                                    {!f.multiple ?
                                                        <FormInput
                                                            name={f.name}
                                                            id={f.name}
                                                            inputType={f.type}
                                                            placeholder={f.placeholder}
                                                            options={f.options}
                                                            disabled={f.disabled ? f.disabled : false}
                                                            onChange={(a) => {
                                                                if (f.name == "unitMeasureID" && a.target.value != 24) {
                                                                    setDisabledCustomSelect(true);
                                                                    setSelectCustomValue([]);
                                                                } else if (f.name == "unitMeasureID" && a.target.value == 24) {
                                                                    setDisabledCustomSelect(false);
                                                                    setSelectCustomValue([]);
                                                                } else {
                                                                    setDisabledCustomSelect(true);
                                                                }

                                                                if (f.onChanged != null) {
                                                                    f.onChanged(formRef.current)
                                                                }
                                                                return;
                                                            }} />
                                                        :
                                                        <Select
                                                            options={f.options.map((o) => {
                                                                var data = {
                                                                    value: o.value,
                                                                    label: o.text,
                                                                    key: o.key
                                                                }
                                                                return data;
                                                            })}
                                                            value={f.name == 'branchs' ? multipleValuesBranchs : multipleValues}
                                                            isMulti
                                                            id={f.name}
                                                            closeMenuOnSelect={false}
                                                            onChange={(a) => {
                                                                f.name == 'branchs' ? setMultipleValuesBranchs(a) : setMultipleValues(a)
                                                                if (f.onChanged != null) {
                                                                    f.onChanged(a)
                                                                }
                                                                return;
                                                            }} />}
                                                </td>
                                            </tr>
                                        }
                                        else {
                                            if (id !== undefined && f.editable !== undefined && f.editable === false) {
                                                return <></>
                                            }
                                            else {
                                                return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                    <td width="25%">
                                                        <label>
                                                            {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                        </label>
                                                    </td>
                                                    <td width="75%">
                                                        <FormInput
                                                            className="form-control"
                                                            name={f.name}
                                                            id={f.name}
                                                            disabled={f.disabled ? f.disabled : false}
                                                            inputType={f.type}
                                                            step={f.step ? f.step : 'any'} />
                                                    </td>
                                                </tr>
                                            }
                                        }
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    <div className="d-flex flex-row mt-2">
                        <CancelButton />
                        <Button type="submit">Salvar</Button>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

export default FormCrud;
