import styled from 'styled-components';

export const Container = styled.button`
  border: 0;  
  background: transparent;
  color: dark-gray;
  margin-right: 10px;

  &:hover {
    color:#EC632C;
  }

  &:focus{
    color:#EC632C;
  }

  &:active{
    color:#EC632C;
  }
`;
