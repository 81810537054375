import React from 'react';

import ApexChart from 'react-apexcharts';

function ChartByMonth(props) {

    const { rows } = props;

    const series = [{
        name: 'Amostras cadastradas no mês',
        type: 'area',
        data: [20, 23, 22, 16]//rows.map(obj => obj.totalEnvironments)
    }/*, {
        name: 'Média contratos até o mês corrente',
        type: 'line',
        data: [45, 61, 43, 20, 15, 32, 44, 61, 43]
    }*/];

    const options = {
        chart: {
            height: 350,
            type: 'line',
        },
        stroke: {
            curve: 'smooth'
        },
        fill: {
            type: 'solid',
            opacity: [0.35, 1],
        },
        labels: ['Abr', 'Mai', 'Jun', 'Jul'],//rows.map(obj => obj.monthYear),
        markers: {
            size: 0
        },
        tooltip: {
            //shared: true,
            intersect: false,
            y: {
                function(y) {
                    if (typeof y !== "undefined") {
                        return `${y.toFixed(0)} points`;
                    }
                    return y;
                }
            }
        }
    };

    return <div style={{
        width: '465px',
        marginLeft: '20px',
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '16px'
    }}>
        <label style={{ fontFamily: "Montserrat", fontWeight: 700, fontSize: 16, width: '100%' }} >Amostras cadastradas por mês</label>
        <ApexChart options={options} series={series} type="line" height={400} />
    </div>
}


export default ChartByMonth;