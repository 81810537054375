import React from 'react';
import { useHistory } from 'react-router-dom';
import CheckInList from '../../components/CheckInList';

import { Container, Card } from './styles';

const CheckIn = () => {
    const history = useHistory();

    return (
        <CheckInList/>

    );
}

export default CheckIn;
