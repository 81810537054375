import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    SidebarHeader,
    SidebarBody,
    SidebarFooter
} from './style';
import { FiX } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';

import RoutesList from '../../routes/routesList';
import MenuItem from '../../components/Menu';



const Sidebar = ({ active, setActive }) => {
    const history = useHistory();
    const { userRoles } = useAuth();

    const sidebarMenuItems = useMemo(() => {
        return RoutesList.filter(item => item.showInSidebar && item.isVisible
            && (item.allowedRoles == null || item.allowedRoles?.some(role => userRoles?.includes(role)) || item.children?.some(roleItem => userRoles?.includes(roleItem))));
    }, [userRoles]);

    return (
        <Container className={(active ? "show" : "hide")}>
            <SidebarHeader>
                <div>
                    <FiX onClick={() => { setActive(!active) }} alt="Close menu" size={20} />
                </div>
                <div>
                    <span style={{ position: "-moz-initial", alignContent: "center", fontSize: 15 }} className="profile-label">DataResults Soil</span>
                </div>
            </SidebarHeader>
            <SidebarBody>
                <ul>
                    {sidebarMenuItems.map(item => {
                        return (
                            <MenuItem item={item} setActive={setActive} key={item.label} onClick={() => { setActive(false); history.push(item.path); }}></MenuItem>
                        );
                    })}
                </ul>
            </SidebarBody>
        </Container>
    );
}

export default Sidebar;

