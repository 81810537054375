import React, { useEffect, useState, forwardRef, useRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box, Grid, Button } from '@material-ui/core';
import { useStyles } from './styles';
import TableLoading from '../TableLoading';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import { error_message, success_message } from '../Toast/index.jsx';
import Select from 'react-select';
import binRed from '../../assets/icons/binRed.png';
import pencil from '../../assets/icons/pencil1.png';
import bin from '../../assets/icons/bin.png';
import more from '../../assets/icons/more.png';
import fewer from '../../assets/icons/fewer.png';
import ModalFormParameter from '../ModalFormParameter';
import ModalColor from '../ModalColor';
import ModalConfirmInterpretation from '../ModalConfirmInterpretation';
import ModalFormOrdering from '../ModalFormOrdering';
import AddIcon from '@mui/icons-material/Add';
import { TryOutlined } from '@mui/icons-material';
import { Container } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';

const InterpretationDetail = () => {
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [culture, setCulture] = useState();
    const [cultures, setCultures] = useState();
    const [selectedCulture, setSelectedCulture] = useState();
    const [tenant, setTenant] = useState();
    const [tenants, setTenants] = useState();
    const [selectedTenant, setSelectedTenant] = useState();
    const [analyseType, setAnalyseType] = useState();
    const [analyseTypes, setAnalyseTypes] = useState();
    const [selectedAnalyseType, setSelectedAnalyseType] = useState();
    const [region, setRegion] = useState();
    const [regions, setRegions] = useState();
    const [selectedRegion, setSelectedRegion] = useState();
    const [branch, setBranch] = useState();
    const [branchs, setBranchs] = useState();
    const [selectedBranch, setSelectedBranch] = useState();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [showModalOrdering, setShowModalOrdering] = useState(false);
    const [parameterTemplateReturn, setparameterTemplateReturn] = useState();
    const [listParameterTemplateItemHeader, setListParameterTemplateItemHeader] = useState();
    const [descriptionParameter, setDescriptionParameter] = useState();
    const [lstParameterTemplateItems, setLstParameterTemplateItems] = useState();
    const [lstAnalyseTypeParameterNotAdd, setLstAnalyseTypeParameterNotAdd] = useState([]);
    const [lstAnalyseTypeParameterAdd, setLstAnalyseTypeParameterAdd] = useState([]);
    const [lstAnalyseTypeParameterAll, setLstAnalyseTypeParameterAll] = useState([]);
    const [parameterTemplateItemHeaderAux, setParameterTemplateItemHeaderAux] = useState([]);
    const [isDependency, setIsDependency] = useState([]);
    const [listParameterTemplateItemHeaderAux, setListParameterTemplateItemHeaderAux] = useState([]);
    const [showModalColor, setShowModalColor] = useState(false);
    const [showModalConfirmInterpretation, setShowModalConfirmInterpretation] = useState(false);
    const [colorElement, setColorElement] = useState([]);
    const [colorElementType, setColorElementType] = useState([]);
    const [parameterTemplateResultID, setParameterTemplateResultID] = useState();
    const [selectedStatus, setSelectedStatus] = useState();

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)

        loadInterpretation((options) => {
            if (cancel) return
            if (options) {
                setparameterTemplateReturn(options)
                setListParameterTemplateItemHeader(options.listParameterTemplateItemHeaderDto)
                setListParameterTemplateItemHeaderAux(options.listParameterTemplateItemHeaderDto)
                setDescription(options.description)
                setSelectedStatus(options.isActive)
                loadCultures((dataCulture) => {
                    setCultures(dataCulture)
                    if (options && options != undefined && options.cultureID != '00000000-0000-0000-0000-000000000000') {
                        setSelectedCulture(options.cultureID)
                    }
                    else {
                        setSelectedCulture(dataCulture[0].cultureID)
                    }
                    loadTenants((dataTenant) => {
                        setTenants(dataTenant)
                        if (options && options != undefined && options.tenantID != '00000000-0000-0000-0000-000000000000') {
                            setSelectedTenant(options.tenantID)
                        }
                        else {
                            setSelectedTenant(dataTenant[0].tenantID)
                        }
                        loadRegions((dataRegion) => {
                            setRegions(dataRegion)
                            if (options && options != undefined && options.regionID != '00000000-0000-0000-0000-000000000000') {
                                setSelectedRegion(options.regionID)
                            }
                            loadBranchs((dataBranch) => {
                                setBranchs(dataBranch)
                                if (options && options.branchID != '00000000-0000-0000-0000-000000000000') {
                                    setSelectedBranch(options.branchID)
                                }
                            })
                            loadAnalyseTypes((dataAnalyseType) => {
                                setAnalyseTypes(dataAnalyseType)
                                if (options && options != undefined && options.analyseTypeID != null && options.analyseTypeID != '00000000-0000-0000-0000-000000000000') {
                                    setSelectedAnalyseType(options.analyseTypeID)
                                }
                                setIsLoading(false)

                            })
                        })

                    })
                })
            }
        })


        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    const loadInterpretation = async (callback) => {
        console.time("loadInterpretation"); // Inicia a contagem de tempo

        try {
            const response = await api.get(`Interpretation/GetById?id=${(location.state ? location.state.id : '00000000-0000-0000-0000-000000000000')}`);
            const options = response.data.response;
            callback(options);
        } catch (error) {
            exceptionNotificationAPI(error);
        } finally {
            console.timeEnd("loadInterpretation"); // Encerra a contagem de tempo quando a chamada assíncrona é concluída
            const timeInSeconds = (console.timeEnd("loadInterpretation") / 1000).toFixed(2);
            console.log(`Tempo de execução: ${timeInSeconds} segundos`);
        }
    };

    const CreateLoadAnalyseTypes = async (type) => {
        if (lstAnalyseTypeParameterAll.length == 0)
        {
            setIsLoading(true)
            if (parameterTemplateReturn && parameterTemplateReturn != undefined && parameterTemplateReturn.analyseTypeID != null && parameterTemplateReturn.analyseTypeID != '00000000-0000-0000-0000-000000000000') {
                setSelectedAnalyseType(parameterTemplateReturn.analyseTypeID)
                allAnalyseTypeParameter(parameterTemplateReturn.analyseTypeID, parameterTemplateReturn.parameterTemplateID, (analyseTypeParameter) => {
                    if (analyseTypeParameter.item1) {
                        setLstAnalyseTypeParameterNotAdd(analyseTypeParameter.item1)
                    }
                    if (analyseTypeParameter.item2) {
                        setLstAnalyseTypeParameterAdd(analyseTypeParameter.item2)
                    }
                    if (analyseTypeParameter.item3) {
                        setLstAnalyseTypeParameterAll(analyseTypeParameter.item3)
                    }
                    setIsLoading(false)
                })
            }
            else {
                setSelectedAnalyseType(analyseTypes[0].analyseTypeID)
                allAnalyseTypeParameter(analyseTypes[0].analyseTypeID, null, (analyseTypeParameter) => {
                    if (analyseTypeParameter.item1) {
                        setLstAnalyseTypeParameterNotAdd(analyseTypeParameter.item1)
                    }
                    if (analyseTypeParameter.item2) {
                        setLstAnalyseTypeParameterAdd(analyseTypeParameter.item2)
                    }
                    if (analyseTypeParameter.item3) {
                        setLstAnalyseTypeParameterAll(analyseTypeParameter.item3)
                    }
                    setIsLoading(false)
                })
            }
            if (type === 1)
            {
                setShowModal(true)
            }
            else
            {
                setShowModalOrdering(true)
            }
        }  
        else
        {
            if (type === 1)
            {
                setShowModal(true)
            }
            else
            {
                setShowModalOrdering(true)
            }
        }
    }

    const loadCultures = async (callback) => {
        api.get(`Culture/GetAllItems`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadTenants = async (callback) => {
        api.get(`Tenant/GetAllItems`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadAnalyseTypes = async (callback) => {
        api.get(`AnalyseSample/GetAllAnalyseTypeItems?isActive=true`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadRegions = async (callback) => {
        api.get(`Region/GetAllItems`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadAnalyseTypeParameter = async (id, callback) => {
        api.get(`Interpretation/GetAllParameterTemplateItem?id=${(id)}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadBranchs = async (callback) => {
        api.get(`Branch/GetAllItems`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const allAnalyseTypeParameter = async (analyseTypeAuxID, parameterTemplateID, callback) => {
        console.time("allAnalyseTypeParameter"); // Inicia a contagem de tempo

        try {
            var url = `Interpretation/GetAllAnalyseTypeParameter?id=${(analyseTypeAuxID)}` + (parameterTemplateID ? `&idAux=${(parameterTemplateID)}` : ``);
            const response = await api.get(url);
            const options = response.data.response;
            callback(options);
        } catch (error) {
            exceptionNotificationAPI(error);
        } finally {
            console.timeEnd("allAnalyseTypeParameter"); // Encerra a contagem de tempo quando a chamada assíncrona é concluída
            const timeInSeconds = (console.timeEnd("allAnalyseTypeParameter") / 1000).toFixed(2);
            console.log(`Tempo de execução: ${timeInSeconds} segundos`);
        }
    };

    const getParameterTemplateItem = async (parameterTemplateItemHeader) => {
        setIsLoading(true)
        if (parameterTemplateItemHeader.dependencyAnalyseTypeParameterID) {
            setIsDependency(true)
        }
        else {
            setIsDependency(false)
        }
        setDescriptionParameter(parameterTemplateItemHeader.description)

        var _lstParameterTemplateItems = [];
        await listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeader.parameterTemplateItemHeaderID) {
                _lstParameterTemplateItems = _parameterTemplateItemHeader.listParameterTemplateItemDto;
            }
        });

        await listParameterTemplateItemHeader.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeader.parameterTemplateItemHeaderID) {
                _parameterTemplateItemHeader.colorBackgroundButton = '#AAD6C2';
            }
            else {
                _parameterTemplateItemHeader.colorBackgroundButton = null;
            }
        });

        if (_lstParameterTemplateItems.length > 0) {
            setParameterTemplateItemHeaderAux(parameterTemplateItemHeader)
            setLstParameterTemplateItems(_lstParameterTemplateItems)
        }
        else {

            setParameterTemplateItemHeaderAux(parameterTemplateItemHeader)
            setLstParameterTemplateItems(parameterTemplateItemHeader.listParameterTemplateItemDto);
        }
        setIsLoading(false)
    }

    const newModalAux = async (lstAnalyseTypeParameterNotAdd, lstAnalyseTypeParameterAdd) => {
        setIsLoading(true)
        var newList = [];
        lstAnalyseTypeParameterAdd.forEach(function (_analyseTypeParameterAdd) {
            if (_analyseTypeParameterAdd.analyseTypeParameterAuxID != null) {
                const parameterTemplateItemHeaderResult = listParameterTemplateItemHeader.find(obj => obj.analyseTypeParameterAuxID === _analyseTypeParameterAdd.analyseTypeParameterAuxID);
                if (parameterTemplateItemHeaderResult === undefined) {
                    api.get(`Interpretation/CreateParameterTemplateItemHeader`).then((result) => {
                        setIsLoading(true)
                        if (result && result.data) {
                            result.data.response.analyseTypeParameterID = _analyseTypeParameterAdd.analyseTypeParameterID;
                            result.data.response.analyseTypeParameterAuxID = _analyseTypeParameterAdd.analyseTypeParameterAuxID;
                            result.data.response.dependencyAnalyseTypeParameterID = _analyseTypeParameterAdd.dependencyAnalyseTypeParameterID;
                            result.data.response.description = _analyseTypeParameterAdd.description;
                            listParameterTemplateItemHeader.forEach(function (_parameterTemplateItemHeaderAux) {
                                if (result.data.response.analyseTypeParameterAuxID === _parameterTemplateItemHeaderAux.analyseTypeParameterAuxID) {
                                    result.data.response.listParameterTemplateItemDto = _parameterTemplateItemHeaderAux.listParameterTemplateItemDto;
                                }
                            });
                            newList.push(result.data.response)
                            setIsLoading(false)
                        }
                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                        setIsLoading(false)
                    });
                }
                else {
                    newList.push(parameterTemplateItemHeaderResult)
                }
            }
            else {
                const parameterTemplateItemHeaderResult = listParameterTemplateItemHeader.find(obj => obj.parameterTemplateItemHeaderID === _analyseTypeParameterAdd.parameterTemplateItemHeaderID && obj.analyseTypeParameterAuxID === undefined);
                if (parameterTemplateItemHeaderResult != undefined) {
                    newList.push(parameterTemplateItemHeaderResult)
                }
                else {
                    api.get(`Interpretation/CreateParameterTemplateItemHeader`).then((result) => {
                        setIsLoading(true)
                        if (result && result.data) {
                            result.data.response.analyseTypeParameterID = _analyseTypeParameterAdd.analyseTypeParameterID;
                            result.data.response.dependencyAnalyseTypeParameterID = _analyseTypeParameterAdd.dependencyAnalyseTypeParameterID;
                            result.data.response.description = _analyseTypeParameterAdd.description;
                            listParameterTemplateItemHeader.forEach(function (_parameterTemplateItemHeaderAux) {
                                if (result.data.response.analyseTypeParameterID === _parameterTemplateItemHeaderAux.analyseTypeParameterID) {
                                    result.data.response.listParameterTemplateItemDto = _parameterTemplateItemHeaderAux.listParameterTemplateItemDto;
                                }
                            });
                            newList.push(result.data.response)
                            setIsLoading(false)
                        }
                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                        setIsLoading(false)

                    });
                }
            }
        });
        setListParameterTemplateItemHeader(newList)
        setListParameterTemplateItemHeaderAux(newList)
        setLstAnalyseTypeParameterAdd(lstAnalyseTypeParameterAdd)
        setLstAnalyseTypeParameterNotAdd(lstAnalyseTypeParameterNotAdd)
        setIsLoading(false)
    }

    const newModalColor = async (selectColor, element) => {
        setIsLoading(true)
        if (colorElementType == 1) {
            await lstParameterTemplateItems.forEach(function (_parameterTemplateItems) {
                if (_parameterTemplateItems.parameterTemplateItemID == element.parameterTemplateItemID) {
                    _parameterTemplateItems.resultColor = selectColor;
                }
                setShowModalColor(false);
            });

            await listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
                if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                    _parameterTemplateItemHeader.listParameterTemplateItemDto.forEach(function (_parameterTemplateItems) {
                        if (_parameterTemplateItems.parameterTemplateItemID == element.parameterTemplateItemID) {
                            _parameterTemplateItems.resultColor = selectColor;
                        }
                    });
                }
            });
            setIsLoading(false)
        }
        else {
            var newList = [];
            setIsLoading(true)
            await lstParameterTemplateItems.forEach(function (_parameterTemplateItems) {
                _parameterTemplateItems.listParameterTemplateItemDto.forEach(function (_parameterTemplateItemItems) {
                    if (_parameterTemplateItemItems.parameterTemplateItemID == element.parameterTemplateItemID) {
                        _parameterTemplateItemItems.resultColor = selectColor;
                    }
                });
                newList.push(_parameterTemplateItems)
            });
            setLstParameterTemplateItems(newList);
            setLstAnalyseTypeParameterAdd(listParameterTemplateItemHeader)
            setIsLoading(false)
            setShowModalColor(false)
        }

    }

    const newModalConfirmInterpretation = async (type) => {
        if (type === true) {
            history.push({ pathname: "/recommendationRegister", state: { id: parameterTemplateResultID } })
        }
        else {
            history.goBack();
        }

    }

    const addParameterTemplateItem = async () => {
        if (parameterTemplateItemHeaderAux.dependencyAnalyseTypeParameterID) {
            setIsDependency(true)
        }
        else {
            setIsDependency(false)
        }
        if (parameterTemplateItemHeaderAux.analyseTypeParameterID) {
            var url = ``;

            if (parameterTemplateItemHeaderAux.analyseTypeParameterAuxID) {
                var url = `Interpretation/GetNewParameterTemplateItem?idAux2=${(parameterTemplateItemHeaderAux.analyseTypeParameterAuxID)}&idAux3=${(parameterTemplateItemHeaderAux.analyseTypeParameterID)}`;
            }
            else {
                var url = `Interpretation/GetNewParameterTemplateItem?id=${(parameterTemplateItemHeaderAux.analyseTypeParameterID)}`;
            }

            if (parameterTemplateItemHeaderAux.dependencyAnalyseTypeParameterID) {
                url += `&idAux=${(parameterTemplateItemHeaderAux.dependencyAnalyseTypeParameterID)}`;
            }
            else if (parameterTemplateItemHeaderAux.parameterTemplateID) {
                url += `&idAux1=${(parameterTemplateItemHeaderAux.parameterTemplateID)}`;
            }
            api.get(url).then((result) => {
                if (result && result.data) {
                    if (lstParameterTemplateItems) {
                        var newList = [...lstParameterTemplateItems]
                        newList.push(result.data.response[0])
                        setLstParameterTemplateItems(newList)
                    }
                    else {
                        setLstParameterTemplateItems(result.data.response)
                    }

                    if (listParameterTemplateItemHeaderAux.length == 0) {
                        var newList = [...listParameterTemplateItemHeaderAux]
                        newList.push(result.data.response[0].parameterTemplateItemHeader)
                        setListParameterTemplateItemHeaderAux(newList)
                    }
                    else {
                        var hasParameterTemplateItemHeader = false;
                        listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
                            if (_parameterTemplateItemHeader.analyseTypeParameterAuxID !== undefined) {
                                if (_parameterTemplateItemHeader.analyseTypeParameterAuxID == parameterTemplateItemHeaderAux.analyseTypeParameterAuxID) {
                                    _parameterTemplateItemHeader.listParameterTemplateItemDto.push(result.data.response[0])
                                    hasParameterTemplateItemHeader = true;
                                }
                            }
                            else if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                                _parameterTemplateItemHeader.listParameterTemplateItemDto.push(result.data.response[0])
                                hasParameterTemplateItemHeader = true;
                            }
                        });
                        if (hasParameterTemplateItemHeader == false) {
                            var newList = [...listParameterTemplateItemHeaderAux]
                            newList.push(result.data.response[0].parameterTemplateItemHeader)
                            setListParameterTemplateItemHeaderAux(newList)
                        }
                    }

                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            error_message("Selecione um parâmetro antes de registrar")
        }
    }

    const addItemParameterTemplateItem = async (event) => {
        if (parameterTemplateItemHeaderAux.dependencyAnalyseTypeParameterID != undefined) {
            api.get(`Interpretation/GetItemNewParameterTemplateItem?id=${(parameterTemplateItemHeaderAux.analyseTypeParameterID)}`).then((result) => {
                if (result && result.data) {
                    var _newList = [];
                    lstParameterTemplateItems.forEach(function (_parameterTemplateItems) {
                        if (_parameterTemplateItems.parameterTemplateItemID == event.parameterTemplateItemID) {
                            var newList = { ..._parameterTemplateItems };
                            var parameterTemplateItem = result.data.response[0];
                            parameterTemplateItem.parameterTemplateItemHeader = event.parameterTemplateItemHeader;
                            parameterTemplateItem.analyseTypeParameterID = event.parameterTemplateItemHeader.analyseTypeParameterID;
                            parameterTemplateItem.analyseTypeParameter = event.parameterTemplateItemHeader.analyseTypeParameter;
                            newList.listParameterTemplateItemDto.push(parameterTemplateItem)
                            _newList.push(newList);

                        } else {
                            _newList.push(_parameterTemplateItems);
                        }
                    });


                    setLstParameterTemplateItems(_newList);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            error_message("Selecione um parâmetro antes de registrar")
        }
    }

    const removeItemParameterTemplateItem = async (event) => {
        var newList = [];
        setIsLoading(true)
        await lstParameterTemplateItems.forEach(function (_parameterTemplateItems) {
            _parameterTemplateItems.listParameterTemplateItemDto.forEach(function (_parameterTemplateItemItems) {
                if (_parameterTemplateItemItems.parameterTemplateItemID == event.parameterTemplateItemID) {
                    _parameterTemplateItemItems.isDeleted = true;
                }
            });
            newList.push(_parameterTemplateItems)
        });
        setLstParameterTemplateItems(newList);
        setLstAnalyseTypeParameterAdd(listParameterTemplateItemHeader)
        setIsLoading(false)
    }

    const removeParameterTemplateItem = async (event) => {
        var newList = [];
        setIsLoading(true)
        await lstParameterTemplateItems.forEach(function (_parameterTemplateItems) {
            if (_parameterTemplateItems.parameterTemplateItemID == event.parameterTemplateItemID) {
                _parameterTemplateItems.isDeleted = true;
            }
            newList.push(_parameterTemplateItems)
        });
        setLstParameterTemplateItems(newList);
        setLstAnalyseTypeParameterAdd(listParameterTemplateItemHeader)

        await listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                _parameterTemplateItemHeader.listParameterTemplateItemDto.forEach(function (_parameterTemplateItems) {
                    if (_parameterTemplateItems.parameterTemplateItemID == event.parameterTemplateItemID) {
                        _parameterTemplateItems.isDeleted = true;
                    }
                });
                _parameterTemplateItemHeader.isDeleted = true;
            }
        });
        setIsLoading(false)
    }

    const setValues = async (event, element, type) => {
        setIsLoading(true)
        await listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                _parameterTemplateItemHeader.listParameterTemplateItemDto.forEach(function (_parameterTemplateItems) {
                    if (_parameterTemplateItems.parameterTemplateItemID == element.parameterTemplateItemID) {
                        switch (type) {
                            case 'minValue':
                                _parameterTemplateItems.minValue = event;
                                break;
                            case 'maxValue':
                                _parameterTemplateItems.maxValue = event;
                                break;
                            case 'resultDescription':
                                _parameterTemplateItems.resultDescription = event;
                                break;
                            case 'operatorMaxValue':
                                _parameterTemplateItems.operatorMaxValue = event;
                                break;
                            case 'operatorMinValue':
                                _parameterTemplateItems.operatorMinValue = event;
                                break;
                            default:
                                break;
                        }
                        setIsLoading(false)
                    }
                });
            }
        });
    }

    function funcCheckResultUsed(parameterTemplateItem) {
        var checked = document.getElementById("CheckboxResultUsed" + parameterTemplateItem.parameterTemplateItemID).checked;
        lstParameterTemplateItems.forEach(function (_parameterTemplateItems) {
            if (_parameterTemplateItems.parameterTemplateItemID == parameterTemplateItem.parameterTemplateItemID) {
                _parameterTemplateItems.resultUsed = checked;
            }
        });
        listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItem.parameterTemplateItemHeaderID) {
                _parameterTemplateItemHeader.listParameterTemplateItemDto.forEach(function (_parameterTemplateItems) {
                    if (_parameterTemplateItems.parameterTemplateItemID == parameterTemplateItem.parameterTemplateItemID) {
                        _parameterTemplateItems.resultUsed = checked;
                    }
                });
            }
        });
    }

    function funcCheckResultUsedItem(element, element1) {
        var checked = document.getElementById("CheckboxResultUsedItem" + element1.parameterTemplateItemID).checked;
        listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                _parameterTemplateItemHeader.listParameterTemplateItemDto.forEach(function (_parameterTemplateItems) {
                    if (_parameterTemplateItems.parameterTemplateItemID == element.parameterTemplateItemID) {
                        _parameterTemplateItems.listParameterTemplateItemDto.forEach(function (_parameterTemplateItem) {
                            if (_parameterTemplateItem.parameterTemplateItemID == element1.parameterTemplateItemID) {
                                _parameterTemplateItem.resultUsed = checked;
                            }
                        });
                    }
                });
            }
        });
    }

    function funcCheckIsResult() {
        listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                var checked = document.getElementById("CheckboxIsResult" + parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID).checked;
                _parameterTemplateItemHeader.isResult = checked;
            }
        });
    }

    function funcCheckIsNotReport() {
        listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                var checked = document.getElementById("CheckboxIsNotReport" + parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID).checked;
                _parameterTemplateItemHeader.isNotReport = checked;
            }
        });

    }

    const setValuesItems = async (event, element, element1, type) => {
        await listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID) {
                _parameterTemplateItemHeader.listParameterTemplateItemDto.forEach(function (_parameterTemplateItems) {
                    if (_parameterTemplateItems.parameterTemplateItemID == element.parameterTemplateItemID) {
                        _parameterTemplateItems.listParameterTemplateItemDto.forEach(function (_parameterTemplateItem) {
                            if (_parameterTemplateItem.parameterTemplateItemID == element1.parameterTemplateItemID) {
                                switch (type) {
                                    case 'minValue':
                                        _parameterTemplateItem.minValue = event;
                                        break;
                                    case 'maxValue':
                                        _parameterTemplateItem.maxValue = event;
                                        break;
                                    case 'resultDescription':
                                        _parameterTemplateItem.resultDescription = event;
                                        break;
                                    case 'operatorMaxValue':
                                        _parameterTemplateItem.operatorMaxValue = event;
                                        break;
                                    case 'operatorMinValue':
                                        _parameterTemplateItem.operatorMinValue = event;
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });
                    }
                });
            }
        });
    }


    const setValueParameterDescription = async (event) => {
        const parameterTemplateItemHeaderResult = listParameterTemplateItemHeaderAux.find(obj => obj.parameterTemplateItemHeaderID === parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID);
        if (parameterTemplateItemHeaderResult !== undefined) {
            parameterTemplateItemHeaderResult.description = event
        }
        const analyseTypeParameterAddResult = lstAnalyseTypeParameterAdd.find(obj => obj.parameterTemplateItemHeaderID === parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID);
        if (analyseTypeParameterAddResult !== undefined) {
            analyseTypeParameterAddResult.description = event
        }
    }

    const newModalOrdering = async (listParameterTemplateItemHeaderLast) => {
        // Atualizar a sequência em ambas as listas
        const updatedList = listParameterTemplateItemHeaderLast.map((item, index) => ({
            ...item,
            sequence: index + 1
        }));

        // Atualizar ListParameterTemplateItemHeader
        setListParameterTemplateItemHeader(updatedList);

        // Atualizar ListParameterTemplateItemHeaderAux
        setListParameterTemplateItemHeaderAux(updatedList);
    };


    const save = async () => {
        try {
            var hasMessage = '';
            await listParameterTemplateItemHeaderAux.forEach(function (_parameterTemplateItemHeader) {
                _parameterTemplateItemHeader.listParameterTemplateItemDto.forEach(function (_parameterTemplateItems) {
                    if (_parameterTemplateItems.isDeleted === false && (_parameterTemplateItems.minValue === null || _parameterTemplateItems.minValue === '' || _parameterTemplateItems.maxValue === null || _parameterTemplateItems.maxValue === '')) {
                        getParameterTemplateItem(_parameterTemplateItemHeader);
                        hasMessage = 'Informe um valor minimo ou maximo no item ' + _parameterTemplateItemHeader.description;
                        return;
                    }
                    else if (_parameterTemplateItems.isDeleted === false && (_parameterTemplateItems.resultDescription === null || _parameterTemplateItems.resultDescription === '')) {
                        getParameterTemplateItem(_parameterTemplateItemHeader);
                        hasMessage = 'Informe uma descrição para o item ' + _parameterTemplateItemHeader.description;
                        return;
                    }
                    else {
                        if (_parameterTemplateItems.listParameterTemplateItemDto !== null && _parameterTemplateItems.listParameterTemplateItemDto !== undefined && _parameterTemplateItems.listParameterTemplateItemDto.length > 0) {
                            _parameterTemplateItems.listParameterTemplateItemDto.forEach(function (_parameterTemplateItem) {
                                if (_parameterTemplateItem.isDeleted === false && (_parameterTemplateItem.minValue === null || _parameterTemplateItem.minValue === '' || _parameterTemplateItem.maxValue === null || _parameterTemplateItem.maxValue === '')) {
                                    getParameterTemplateItem(_parameterTemplateItemHeader);
                                    hasMessage = 'Informe um valor minimo ou máximo no Sub Item ' + _parameterTemplateItemHeader.description;
                                    return;
                                }
                                else if (_parameterTemplateItem.isDeleted === false && (_parameterTemplateItem.resultDescription === null || _parameterTemplateItem.resultDescription === '')) {
                                    getParameterTemplateItem(_parameterTemplateItemHeader);
                                    hasMessage = 'Informe uma descrição para o Sub Item ' + _parameterTemplateItemHeader.description;
                                    return;
                                }
                            });
                        }
                    }
                });
            });

            if (hasMessage) {
                error_message(hasMessage)
            }
            else if (!description) {
                error_message("Informe uma descrição")
            }
            else {
                setIsLoading(true)
                parameterTemplateReturn.description = description;
                parameterTemplateReturn.tenantID = selectedTenant;
                parameterTemplateReturn.branchID = selectedBranch;
                parameterTemplateReturn.cultureID = selectedCulture;
                parameterTemplateReturn.regionID = selectedRegion;
                parameterTemplateReturn.isActive = selectedStatus;
                parameterTemplateReturn.analyseTypeID = selectedAnalyseType;
                parameterTemplateReturn.listParameterTemplateItemHeaderDto = listParameterTemplateItemHeaderAux;
                const result = await api.post(`/Interpretation/new`, parameterTemplateReturn);
                if (result.data.response !== null) {
                    setParameterTemplateResultID(result.data.response)
                    success_message("Parâmetro salvo com sucesso!")
                    setShowModalConfirmInterpretation(true);

                }
                setIsLoading(false)
            }

        } catch (error) {
            setIsLoading(false)
            exceptionNotificationAPI(error);
        }
    }

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    return (
        <>
            {
                <>
                    <Container>
                        <Row style={{ marginTop: '30px' }}>
                            <Col md={12} lg={12} xs={12}>
                                <button className='btn' onClick={() => {
                                    history.goBack();
                                }}>
                                    <img src={ArrowLeft} className='icon' />
                                </button>
                                <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Cadastro de Interpretação"}</label>
                            </Col>
                        </Row>
                    </Container>
                    <Container className={styles.pagewhite}>
                        <Row>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Description">
                                    <Form.Label className={styles.tableLabelsHeader}>Descrição</Form.Label>
                                    <Form.Control value={description} onChange={(t) => { setDescription(t.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Culture">
                                    <Form.Label className={styles.tableLabelsHeader}>Cultura</Form.Label>
                                    <select name='cultures' value={selectedCulture} className={styles.search} style={{ width: "100%" }} onChange={(e) => { setSelectedCulture(e.target.value) }}>
                                        {
                                            cultures ?
                                                cultures.map(culture => {
                                                    return <option key={culture.cultureID} value={culture.cultureID}>{culture.name}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="AnalyseType">
                                    <Form.Label className={styles.tableLabelsHeader}>Tipo de Análise</Form.Label>
                                    {parameterTemplateReturn ?
                                        <select name='analyseTypes' value={selectedAnalyseType} className={styles.search} style={{ width: "100%" }} disabled={parameterTemplateReturn.analyseType ? true : false} onChange={(e) => {
                                            setSelectedAnalyseType(e.target.value)
                                            setIsLoading(true)
                                            allAnalyseTypeParameter(e.target.value, null, (analyseTypeParameter) => {
                                                if (analyseTypeParameter.item1) {
                                                    setLstAnalyseTypeParameterNotAdd(analyseTypeParameter.item1)
                                                }
                                                if (analyseTypeParameter.item2) {
                                                    setLstAnalyseTypeParameterAdd(analyseTypeParameter.item2)
                                                }
                                                if (analyseTypeParameter.item3) {
                                                    setLstAnalyseTypeParameterAll(analyseTypeParameter.item3)
                                                }
                                                setIsLoading(false)
                                            })
                                        }}>
                                            {
                                                analyseTypes ?
                                                    analyseTypes.map(analyseType => {
                                                        return <option key={analyseType.analyseTypeID} value={analyseType.analyseTypeID}>{analyseType.description}</option>
                                                    })
                                                    :
                                                    <></>
                                            }
                                        </select>
                                        :
                                        <select name='analyseTypes' value={selectedAnalyseType} className={styles.search} style={{ width: "100%" }} onChange={(e) => {
                                            setSelectedAnalyseType(e.target.value)
                                            loadAnalyseTypeParameter(e.target.value, (dataAnalyseTypeParameter) => {

                                            })
                                        }}>
                                            {
                                                analyseTypes ?
                                                    analyseTypes.map(analyseType => {
                                                        return <option key={analyseType.analyseTypeID} value={analyseType.analyseTypeID}>{analyseType.description}</option>
                                                    })
                                                    :
                                                    <></>
                                            }
                                        </select>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Company">
                                    <Form.Label className={styles.tableLabelsHeader}>Empresa</Form.Label>
                                    <select name='tenants' value={selectedTenant} className={styles.search} style={{ width: "100%", marginRight: "5%" }} onChange={(e) => { setSelectedTenant(e.target.value) }}>
                                        {
                                            tenants ?
                                                tenants.map(tenant => {
                                                    return <option key={tenant.tenantID} value={tenant.tenantID}>{tenant.name}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Branch">
                                    <Form.Label className={styles.tableLabelsHeader}>Filial</Form.Label>
                                    <select name='branchs' value={selectedBranch} className={styles.search} style={{ width: "100%" }} onChange={(e) => { setSelectedBranch(e.target.value) }}>
                                        <option>Selecione</option>
                                        {
                                            branchs ?
                                                branchs.map(branch => {
                                                    return <option key={branch.branchID} value={branch.branchID}>{branch.description}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col md={2} lg={2} xs={12}>
                                <Form.Group className="mb-3" controlId="Region">
                                    <Form.Label className={styles.tableLabelsHeader}>Região</Form.Label>
                                    <select name='regions' value={selectedRegion} className={styles.search} style={{ width: "100%", marginRight: "5%" }} onChange={(e) => { setSelectedRegion(e.target.value) }}>
                                        <option>Selecione</option>
                                        {
                                            regions ?
                                                regions.map(region => {
                                                    return <option key={region.regionID} value={region.regionID}>{region.name}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col md={2} lg={2} xs={12}>
                                <Form.Group className="mb-3" controlId="Status">
                                    <Form.Label className={styles.tableLabelsHeader}>Status</Form.Label>
                                    <select name='status' value={selectedStatus} className={styles.search} style={{ width: "100%", marginRight: "5%" }} onChange={(e) => { setSelectedStatus(e.target.value) }}>
                                        <option value={true}>Ativo</option>
                                        <option value={false}>Inativo</option>
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Col lg={12} md={12} xs={12}>
                                <label style={{ fontFamily: "Montserrat", fontWeight: 700, fontSize: 20, color: "#0C8662" }} >{"Parâmetros da Análise"}</label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Col lg={6} md={6} xs={12}>
                                <Button variant='text' className={styles.buttonParameter}
                                    startIcon={
                                        <AddIcon className={styles.buttonParameter} />
                                    }
                                    onClick={() => {
                                        CreateLoadAnalyseTypes(1)
                                    }}>
                                    Clique para selecionar os parâmetros da sua análise
                                </Button>
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <Button variant='text' className={styles.buttonParameter}
                                    startIcon={
                                        <AddIcon className={styles.buttonParameter} />
                                    }
                                    onClick={() => {
                                        CreateLoadAnalyseTypes(2)
                                    }}>
                                    Ordenar
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            {
                                listParameterTemplateItemHeader ?
                                    listParameterTemplateItemHeader
                                        .sort((a, b) => {
                                            // Verifica se ambas as sequências são definidas
                                            if (a.sequence !== null && b.sequence !== null) {
                                                return a.sequence - b.sequence; // Ordena pelos valores de sequência
                                            } else if (a.sequence !== null) {
                                                return -1; // Mantém 'a' antes de 'b' se 'a' tiver sequência definida
                                            } else if (b.sequence !== null) {
                                                return 1; // Move 'b' antes de 'a' se 'b' tiver sequência definida
                                            } else {
                                                return 0; // Mantém a ordem atual se ambas as sequências forem nulas
                                            }
                                        })
                                        .map(e => (
                                            e.colorBackgroundButton ?
                                                <Col lg={2} md={2} xs={4} className={styles.boxParameter} style={{ background: e.colorBackgroundButton }}>
                                                    <Button
                                                        style={{ width: "100%", height: "100%", color: "#737D86", background: e.colorBackgroundButton }}
                                                        onClick={() => getParameterTemplateItem(e)}
                                                    >
                                                        {e.description}
                                                    </Button>
                                                </Col>
                                                :
                                                <Col lg={2} md={2} xs={4} className={styles.boxParameter}>
                                                    <Button
                                                        style={{ width: "100%", height: "100%", color: "#737D86", background: e.colorBackgroundButton }}
                                                        onClick={() => getParameterTemplateItem(e)}
                                                    >
                                                        {e.description}
                                                    </Button>
                                                </Col>
                                        ))
                                    :
                                    <></>
                            }
                        </Row>
                        <Row>
                            {
                                descriptionParameter ?
                                    <Col lg={12} md={12} xs={12} style={{ marginTop: "30px" }}>
                                        <img src={pencil} className='icon' />
                                        <input className={styles.inputDescription} style={{ width: "90%", fontFamily: "Montserrat", fontWeight: 700, fontSize: 22, color: "#161C24" }} Value={descriptionParameter} onChange={(t) => { setValueParameterDescription(t.target.value) }}  ></input>
                                        <Box style={{ marginTop: "10px" }}></Box>
                                    </Col>
                                    :
                                    <></>
                            }
                        </Row>
                        {
                            lstParameterTemplateItems ? lstParameterTemplateItems.filter(function (element) { return element.isDeleted == false; }).map(e => {
                                return (
                                    <Box style={{ marginTop: "5px", background: "#F2F5F8", border: "1px solid #F2F5F8", borderRadius: "8px" }}>
                                        <Box >
                                            <label className={styles.tableLabels} style={{ width: "7%", marginLeft: "3%" }}>Se Valor</label>
                                            <label className={styles.tableLabels} style={{ width: "13%", marginRight: "1%", color: "#000000", marginTop: "1%", textAlign: "center" }}>{e.parameterTemplateItemHeader && e.parameterTemplateItemHeader.dependencyAnalyseTypeParameter ? e.parameterTemplateItemHeader.dependencyAnalyseTypeParameter.parameter.description : e.parameterTemplateItemHeader.analyseTypeParameter.parameter.description}</label>
                                            <select name='levels' defaultValue={e.operatorMinValue} className={styles.search} style={{ width: "5%", marginRight: "1%" }} onChange={(t) => { setValues(t.target.value, e, 'operatorMinValue') }}>
                                                {
                                                    e.listOperator ?
                                                        e.listOperator.map(operator => {
                                                            return <option key={operator} value={operator}>{operator}</option>
                                                        })
                                                        :
                                                        <></>
                                                }
                                            </select>
                                            <input type={"number"} style={{ width: "7%", marginRight: "1%" }} Value={e.minValue} onChange={(t) => { setValues(t.target.value, e, 'minValue') }} className={styles.input} ></input>
                                            <label className={styles.tableLabels} style={{ width: "1%", marginRight: "1%" }}>e</label>
                                            <select name='levels' defaultValue={e.operatorMaxValue} className={styles.search} style={{ width: "5%", marginRight: "1%" }} onChange={(t) => { setValues(t.target.value, e, 'operatorMaxValue') }}>
                                                {
                                                    e.listOperator ?
                                                        e.listOperator.map(operator => {
                                                            return <option key={operator} value={operator}>{operator}</option>
                                                        })
                                                        :
                                                        <></>
                                                }
                                            </select>
                                            <input type={"number"} style={{ width: "7%", marginRight: "1%" }} Value={e.maxValue} onChange={(t) => { setValues(t.target.value, e, 'maxValue') }} className={styles.input} ></input>
                                            <input style={{ width: "15%", marginRight: "1%" }} Value={e.resultDescription} onChange={(t) => { setValues(t.target.value, e, 'resultDescription') }} className={styles.input} ></input>

                                            <Box style={{ position: "relative", width: "3%", marginRight: "1%", justifyContent: "flex-start", display: "inline-block", marginRight: "1%" }} onClick={() => {
                                                setColorElement(e)
                                                setShowModalColor(true)
                                                setColorElementType(1)
                                            }} >
                                                <input style={{ position: "absolute", marginLeft: "10%", marginTop: "13px", outline: "none", borderRadius: "5px", border: "1px solid " + e.resultColor, width: "80%", height: "45%", background: e.resultColor }} readOnly ></input>
                                                <input className={styles.input} style={{ width: "100%" }} ></input>
                                            </Box>
                                            {
                                                isDependency ?
                                                    <button style={{ marginRight: "5%", marginRight: "18%" }} className='btn' onClick={() => {
                                                        addItemParameterTemplateItem(e);
                                                    }}>
                                                        <img style={{ display: "flex", width: "30px", height: "30px" }} src={more} className='icon' />
                                                    </button>
                                                    :
                                                    <input id={"CheckboxResultUsed" + e.parameterTemplateItemID} type="checkbox" defaultChecked={e.resultUsed ? e.resultUsed : false} style={{ marginRight: "1%", marginTop: "3%" }} onClick={() => funcCheckResultUsed(e)} ></input>
                                            }
                                            {
                                                isDependency ?
                                                    <></>
                                                    :
                                                    <label className={styles.tableLabels} style={{ marginRight: "10%", fontWeight: 600, fontSize: 12, color: "black" }}>intervalo padrão</label>
                                            }
                                            <button className='btn' onClick={() => {
                                                removeParameterTemplateItem(e)
                                            }}>
                                                <img src={binRed} className='icon' />
                                            </button>
                                        </Box>
                                        <Box style={{ borderBottom: "1px solid #CDD5DF", height: "10px", marginLeft: "3%", marginRight: "25%" }}></Box>
                                        {
                                            e.listParameterTemplateItemDto ? e.listParameterTemplateItemDto.filter(function (element) { return element.isDeleted == false; }).map(p => {
                                                return (
                                                    <Box style={{ marginTop: "10px", justifyContent: "flex-start", display: "flex", background: "#f2f5f8" }}>
                                                        <label className={styles.tableLabels} style={{ width: "7%", marginLeft: "3%", marginTop: "1%" }}>Se Valor</label>
                                                        <label className={styles.tableLabels} style={{ width: "13%", marginRight: "1%", color: "#000000", marginTop: "1%", textAlign: "center" }}>{p.parameterTemplateItemHeader.analyseTypeParameter.parameter.description}</label>
                                                        <select name='levels' defaultValue={p.operatorMinValue} className={styles.search} style={{ width: "5%", marginRight: "1%" }} onChange={(t) => { setValuesItems(t.target.value, e, p, 'operatorMinValue') }}>
                                                            {
                                                                p.listOperator ?
                                                                    p.listOperator.map(operator => {
                                                                        return <option key={operator} value={operator}>{operator}</option>
                                                                    })
                                                                    :
                                                                    <></>
                                                            }
                                                        </select>
                                                        <input type={"number"} Value={p.minValue} className={styles.input} style={{ width: "7%", marginRight: "1%" }} onChange={(t) => { setValuesItems(t.target.value, e, p, 'minValue') }}></input>
                                                        <label className={styles.tableLabels} style={{ width: "1%", marginRight: "1%" }}>e</label>
                                                        <select name='levels' defaultValue={p.operatorMaxValue} className={styles.search} style={{ width: "5%", marginRight: "1%" }} onChange={(t) => { setValuesItems(t.target.value, e, p, 'operatorMaxValue') }}>
                                                            {
                                                                p.listOperator ?
                                                                    p.listOperator.map(operator => {
                                                                        return <option key={operator} value={operator}>{operator}</option>
                                                                    })
                                                                    :
                                                                    <></>
                                                            }
                                                        </select>
                                                        <input type={"number"} Value={p.maxValue} className={styles.input} style={{ width: "7%", marginRight: "1%" }} onChange={(t) => { setValuesItems(t.target.value, e, p, 'maxValue') }}></input>
                                                        <input style={{ width: "15%", marginRight: "1%" }} Value={p.resultDescription} className={styles.input} onChange={(t) => { setValuesItems(t.target.value, e, p, 'resultDescription') }}></input>

                                                        <Box style={{ position: "relative", width: "3%", marginRight: "1%", justifyContent: "flex-start", display: "inline-block" }} onClick={() => {
                                                            setColorElement(p)
                                                            setShowModalColor(true)
                                                            setColorElementType(2)
                                                        }} >
                                                            <input style={{ position: "absolute", marginLeft: "10%", marginTop: "13px", outline: "none", borderRadius: "5px", border: "1px solid " + p.resultColor, width: "80%", height: "45%", background: p.resultColor }} readOnly ></input>
                                                            <input className={styles.input} style={{ width: "100%" }} ></input>
                                                        </Box>

                                                        <button style={{ marginRight: "5%", marginRight: "1%" }} className='btn' onClick={() => {
                                                            removeItemParameterTemplateItem(p)
                                                        }}>
                                                            <img style={{ display: "flex", width: "30px", height: "30px" }} src={fewer} className='icon' />
                                                        </button>

                                                        <input id={"CheckboxResultUsedItem" + p.parameterTemplateItemID} type="checkbox" defaultChecked={p.resultUsed ? p.resultUsed : false} style={{ marginRight: "1%" }} onClick={() => funcCheckResultUsedItem(e, p)} ></input>
                                                        <label className={styles.tableLabels} style={{ width: "10%", marginTop: "1%", marginRight: "10%", fontWeight: 600, fontSize: 12, color: "black" }}>intervalo padrão</label>
                                                    </Box>
                                                )
                                            })
                                                :
                                                <></>
                                        }
                                    </Box>
                                )
                            })
                                :
                                <></>
                        }
                        {
                            descriptionParameter ?
                                <Row>
                                    <Box className={styles.block} >
                                        <Button className={styles.buttonBlue} onClick={() => {
                                            addParameterTemplateItem();
                                        }}>
                                            <label className={styles.labelweight} style={{ color: "#FFFFFF" }}>Registrar Regra</label>
                                        </Button>
                                        {
                                            isDependency ?
                                                <Box style={{ marginLeft: "60%", marginRight: "1%", marginTop: "1%", width: "50%" }}>
                                                    <label style={{ marginRight: "1%", marginTop: "1%" }}>Não Vísivel</label>
                                                    <input id={"CheckboxIsNotReport" + parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID} type="checkbox" defaultChecked={parameterTemplateItemHeaderAux && parameterTemplateItemHeaderAux.isNotReport ? parameterTemplateItemHeaderAux.isNotReport : false} style={{ marginRight: "1%" }} onClick={() => funcCheckIsNotReport()} ></input>
                                                </Box>
                                                :
                                                <Box style={{ marginLeft: "60%", marginRight: "1%", marginTop: "1%", width: "50%" }}>
                                                    <label style={{ marginRight: "1%", marginTop: "1%" }}>Mostrar apenas valor bruto</label>
                                                    <input id={"CheckboxIsResult" + parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID} type="checkbox" defaultChecked={parameterTemplateItemHeaderAux && parameterTemplateItemHeaderAux.isResult ? parameterTemplateItemHeaderAux.isResult : false} style={{ marginRight: "8%" }} onClick={() => funcCheckIsResult()} ></input>
                                                    <label style={{ marginRight: "1%", marginTop: "1%" }}>Não Vísivel</label>
                                                    <input id={"CheckboxIsNotReport" + parameterTemplateItemHeaderAux.parameterTemplateItemHeaderID} type="checkbox" defaultChecked={parameterTemplateItemHeaderAux && parameterTemplateItemHeaderAux.isNotReport ? parameterTemplateItemHeaderAux.isNotReport : false} style={{ marginRight: "1%" }} onClick={() => funcCheckIsNotReport()} ></input>
                                                </Box>
                                        }

                                    </Box>
                                </Row>
                                :
                                <></>
                        }
                        {
                            <Box style={{ display: "flex", justifyContent: "flex-end" }} >
                                <Button className={styles.buttonWeight} onClick={() => {
                                    save()
                                }}>
                                    <label className={styles.labelweight}>Salvar</label>
                                </Button>
                            </Box>
                        }
                    </Container>

                    <ModalFormParameter show={showModal} setShow={setShowModal} newModal={newModalAux} _left={lstAnalyseTypeParameterNotAdd} _right={lstAnalyseTypeParameterAdd} _lstAnalyseTypeParameterAll={lstAnalyseTypeParameterAll} />
                    <ModalColor show={showModalColor} setShow={setShowModalColor} colorElement={colorElement} newModalColor={newModalColor} />
                    <ModalConfirmInterpretation show={showModalConfirmInterpretation} setShow={setShowModalConfirmInterpretation} newModalConfirmInterpretation={newModalConfirmInterpretation} />
                    <ModalFormOrdering show={showModalOrdering} setShow={setShowModalOrdering} newModalOrdering={newModalOrdering} listParameterTemplateItemHeaderLast={listParameterTemplateItemHeader} />
                </>
            }
        </>
    );
}

export default InterpretationDetail;
