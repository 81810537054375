import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import api, { exceptionNotificationAPI } from '../../services/api';
import { success_message } from '../../components/Toast/index.jsx';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Container, PasswordInput } from './styles';

const ForgotPassword = () => {
    const { signOut } = useAuth();
    const history = useHistory();
    const { token, id } = useParams();
    const [password, setPassword] = useState();
    const [passwordCfr, setPasswordCfr] = useState();

    const handleSubmit = async () => {
        try {
            const userPasswordRequest = {Id: id, Password:password, ConfirmPassword:passwordCfr, Token: token}

            const result = await api.post('/forgotPassword', userPasswordRequest);

            if(result.data.response){
                success_message("Senha atualizada com sucesso");
                setTimeout(() => {
                    signOut(true)
                }, 1000);
                history.push("/");

            }
            
        } catch (error) {
            exceptionNotificationAPI(error);
        }
    };

    const onEnterPress = (e) => {
        if(e.key == 'Enter'){
            handleSubmit();
        }
    }

    return (
        <Container>
            <div className="main-div container" align="left">
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                        <label>Nova Senha</label>
                            <PasswordInput type="password" name="Password" id="newPassword" 
                                onChange={(e) => {setPassword(e.target.value);}}></PasswordInput>
                            <PasswordStrengthBar password={password} minLength={10} />
                        </div>                   
                        <div className="form-group">
                        <label>Confirme a nova senha</label>
                            <PasswordInput type="password" name="ConfirmPassword" id="confirmNewPassword"
                              onChange={(e) => {
                                  setPasswordCfr(e.target.value);}}></PasswordInput>
                            <PasswordStrengthBar password={passwordCfr} minLength={10} />
                        </div>                   
                        <button className="btn" onClick={handleSubmit}>Alterar a senha</button>
                    </div>
                </div>              
            </div>
        </Container>
    );
};

export default ForgotPassword;
