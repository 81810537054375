import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { Box,} from '@material-ui/core';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';

const AnalyseSampleDetailItemResult = ({list}) => {
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [digit, setDigit] = useState("");

    useEffect(() => {
        let cancel = false;

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    useEffect(() => {
        let cancel = false;

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, [list]);

    const itemList = async (analyseResult, type) => {
        list.forEach(function (item) {
            if (item.item1 != null && item.item1.analyseSampleResultID === analyseResult.analyseSampleResultID) {
                if (location.state.isCreate && digit != '' && type == 0) {
                    item.item1.valueString = digit.replace(",", ".")
                    setDigit('')
                }
                else if (location.state.isCreate && digit != '' && type == 1) {
                    item.item1.valueDependency = digit.replace(",", ".")
                    setDigit('')
                }
                else if (item.item1.value !== null && digit != '' && type == 0) {
                    item.item1.value = digit.replace(",", ".")
                    setDigit('')
                }
                else if (item.item1.valueDependency !== null && digit != '' && type == 1) {
                    item.item1.valueDependency = digit.replace(",", ".")
                    setDigit('')
                }
                else if (item.item1.valueString !== null && digit != '') {
                    item.item1.valueString = digit
                    setDigit('')
                }
            }
            if (item.item2 != null && item.item2.analyseSampleResultID === analyseResult.analyseSampleResultID) {
                if (location.state.isCreate && digit != '' && type == 0) {
                    item.item2.valueString = digit
                    setDigit('')
                }
                else if (location.state.isCreate && digit != '' && type == 1) {
                    item.item2.valueDependency = digit
                    setDigit('')
                }
                else if (item.item2.value !== null && digit != '' && type == 0) {
                    item.item2.value = digit
                    setDigit('')
                }
                else if (item.item2.valueDependency !== null && digit != '' && type == 1) {
                    item.item2.valueDependency = digit
                    setDigit('')
                }
                else if (item.item2.valueString !== null && digit != '') {
                    item.item2.valueString = digit
                    setDigit('')
                }
            }
            if (item.item3 != null && item.item3.analyseSampleResultID === analyseResult.analyseSampleResultID) {
                if (location.state.isCreate && digit != '' && type == 0) {
                    item.item3.valueString = digit
                    setDigit('')
                }
                else if (location.state.isCreate && digit != '' && type == 1) {
                    item.item3.valueDependency = digit
                    setDigit('')
                }
                if (item.item3.value !== null && digit != '' && type == 0) {
                    item.item3.value = digit
                    setDigit('')
                }
                else if (item.item3.valueDependency !== null && digit != '' && type == 1) {
                    item.item3.valueDependency = digit
                    setDigit('')
                }
                else if (item.item3.valueString !== null && digit != '') {
                    item.item3.valueString = digit
                    setDigit('')
                }
            }
            if (item.item4 != null && item.item4.analyseSampleResultID === analyseResult.analyseSampleResultID) {
                if (location.state.isCreate && digit != '' && type == 0) {
                    item.item4.valueString = digit
                    setDigit('')
                }
                else if (location.state.isCreate && digit != '' && type == 1) {
                    item.item4.valueDependency = digit
                    setDigit('')
                }
                if (item.item4.value !== null && digit != '' && type == 0) {
                    item.item4.value = digit
                    setDigit('')
                }
                else if (item.item4.valueDependency !== null && digit != '' && type == 1) {
                    item.item4.valueDependency = digit
                    setDigit('')
                }
                else if (item.item4.valueString !== null && digit != '') {
                    item.item4.valueString = digit
                    setDigit('')
                }
            }
        });
    }

    return (
            <Box style={{marginTop: '20px'}}>
            {
                list.map(e => {
                    return (
                            <Row>
                                <Col md={3} lg={3} xs={6}>
                                    <Form.Group className="mb-3" controlId={e.item1 ? e.item1.parameter.description : ""}>
                                        <Form.Label className={styles.tableLabelsHeader}>{e.item1 ? e.item1.parameter.description : ""}</Form.Label>
                                        <Form.Control onChange={(t) => { setDigit(t.target.value) }} onBlur={() => { itemList(e.item1, 0) }} type={e.item1 ? e.item1.value ? "number" : "text" : "text"} style={{ visibility: e.item1 ? "visibility" : "hidden" }} defaultValue={e.item1 ? e.item1.value == 0 ? 0 : e.item1.value ? e.item1.value : e.item1.valueString ? e.item1.valueString : "" : ""} />
                                    </Form.Group>
                                </Col>
                                <Col md={3} lg={3} xs={6}>
                                    <Form.Group className="mb-3" controlId={e.item2 ? e.item2.parameter.description : ""}>
                                        <Form.Label className={styles.tableLabelsHeader}>{e.item2 ? e.item2.parameter.description : ""}</Form.Label>
                                        <Form.Control onChange={(t) => { setDigit(t.target.value) }} onBlur={() => { itemList(e.item2, 0) }} type={e.item2 ? e.item2.value ? "number" : "text" : "text"} style={{ visibility: e.item2 ? "visibility" : "hidden" }} defaultValue={e.item2 ? e.item2.value == 0 ? 0 : e.item2.value ? e.item2.value : e.item2.valueString ? e.item2.valueString : "" : ""} />
                                    </Form.Group>
                                </Col>
                                <Col md={3} lg={3} xs={6}>
                                    <Form.Group className="mb-3" controlId={e.item3 ? e.item3.parameter.description : ""}>
                                        <Form.Label className={styles.tableLabelsHeader}>{e.item3 ? e.item3.parameter.description : ""}</Form.Label>
                                        <Form.Control onChange={(t) => { setDigit(t.target.value) }} onBlur={() => { itemList(e.item3, 0) }} type={e.item3 ? e.item3.value ? "number" : "text" : "text"} style={{ visibility: e.item3 ? "visibility" : "hidden" }} defaultValue={e.item3 ? e.item3.value == 0 ? 0 : e.item3.value ? e.item3.value : e.item3.valueString ? e.item3.valueString : "" : ""} />
                                    </Form.Group>
                                </Col>
                                <Col md={3} lg={3} xs={6}>
                                    <Form.Group className="mb-3" controlId={e.item4 ? e.item4.parameter.description : ""}>
                                        <Form.Label className={styles.tableLabelsHeader}>{e.item4 ? e.item4.parameter.description : ""}</Form.Label>
                                        <Form.Control onChange={(t) => { setDigit(t.target.value) }} onBlur={() => { itemList(e.item4, 0) }} type={e.item4 ? e.item4.value || e.item4.value == 0 ? "number" : "text" : "text"} style={{visibility: e.item4 ? "visibility" : "hidden" }} defaultValue={e.item4 ? e.item4.value == 0 ? 0 : e.item4.value ? e.item4.value : e.item4.valueString ? e.item4.valueString : "" : ""} />
                                    </Form.Group>
                                </Col>
                            </Row>
                    )
                })
            }
        </Box>

    );
}

export default AnalyseSampleDetailItemResult;
