import React, { useEffect, useState, forwardRef, useRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box, Container, InputBase, InputAdornment, Button, Checkbox, Grid, styled } from '@material-ui/core';
import { useStyles } from './styles';
import TableLoading from '../TableLoading';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import { error_message, success_message } from '../Toast/index.jsx';
import Select from 'react-select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AnalyseSampleDetailItemResult from '../AnalyseSampleDetailItemResult';
import AnalyseSampleDetailItemInterpretation from '../AnalyseSampleDetailItemInterpretation';
import { Col, Form, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';

const AnalyseSampleDetail = () => {
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [analyseSampleReturn, setAnalyseSampleReturn] = useState([]);
    const [list, setList] = useState([]);
    const [listItens, setListItens] = useState([]);
    const [selectedFarmer,  setSelectedFarmer] = useState();
    const [farmers, SetFarmers] = useState([]);
    const [farmer, setFarmer] = useState();
    const [selectedBranch,  setSelectedBranch] = useState();
    const [branchs, SetBranchs] = useState([]);
    const [branch, setBranch] = useState();

    const [selectedInstructor,  setSelectedInstructor] = useState();
    const [instructors, SetInstructors] = useState([]);
    const [instructor, setInstructor] = useState();
    
    const [cultures, SetCultures] = useState([]);
    const [selectedCultures, setSelectedCultures] = useState([]);

    const [sampleNumber, setSampleNumber] = useState("");
    const [friendlyCode, setFriendlyCode] = useState("");
    const [dateSample, setDateSample] = useState(Date().toLocaleDateString);
    const [dateEmission, setDateEmission] = useState("");
    const [yearOS, setYearOS] = useState("");
    const [numberOS, setNumberOS] = useState("");
    const [observation, setObservation] = useState("");
    const [valueTab, setValueTab] = useState(0);
    const { userRoles } = useAuth();

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)

        if(location.state.isInterpretation){
            setValueTab(1);
        }

        getAnalyseSample((options) => {
            if (options) {              
                setAnalyseSampleReturn(options)
                setList(options.listItemAnalyseSampleResultDto)
                setListItens(options.listAnalyseSampleResultDto)
                setSampleNumber(options.sampleNumber)
                setFriendlyCode(options.friendlyCode)
                setYearOS(options.yearOS)
                setNumberOS(options.numberOS)

                loadBranchs((optionsBranch) => {
                    SetBranchs(optionsBranch)

                    if (optionsBranch && optionsBranch.length > 0) {
                        if (options.farmerCultureRegion && options.farmerCultureRegion.branch) {                                
                            setSelectedBranch(options.farmerCultureRegion.branchID)  
                            if(options.farmerCultureRegion.branch){
                                setBranch(options.farmerCultureRegion.branch.description)
                            }                              
                            
                        }
                        setIsLoading(false)
                    }
        
                });

                loadInstructors((optionsInstructor) => {
                    SetInstructors(optionsInstructor)

                    if (optionsInstructor && optionsInstructor.length > 0) {
                        if (options.instructor) {                                
                            setSelectedInstructor(options.instructorID)                                
                            setInstructor(options.instructor.externalCode + ' - ' + options.instructor.name)
                        }
                        setIsLoading(false)
                    }
        
                });

                loadCultures();

                if (options.listAnalyseSampleCultureDto != null){       
                    const selectedValues = options.listAnalyseSampleCultureDto.map(item => ({ value: item.cultureID, label: `${item.culture.name} ` }));
                    setSelectedCultures(selectedValues);                    
                }

                if (options.farmerCultureRegion) {
                    setSelectedBranch(options.farmerCultureRegion.branchID)
                    loadfarmerCultureRegion(options.farmerCultureRegionID, (optionsFarmer) => {
                        if (cancel) return
                        SetFarmers(optionsFarmer)
                        if (optionsFarmer && optionsFarmer.length > 0) {
                            if (options.farmerCultureRegion) {                                
                                setSelectedFarmer(options.farmerCultureRegionID)                                
                                setFarmer(options.farmerCultureRegion.externalCode + " - " + options.farmerCultureRegion.farmer.name )//+ " (" + options.instructor.externalCode + ")")
                            }
                            setIsLoading(false)
                        }
                    });
                }
                setIsLoading(false)
            }
        });

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const getAnalyseSample = async (callback) => {
        api.get(`AnalyseSample/GetById?id=${(location.state.analyseSampleID ? location.state.analyseSampleID : location.state.analyseTypeID)}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadfarmerCultureRegion = async (id, callback) => {
        api.get(`Farmer/GetAllFarmerCultureRegionItems?id=` + (id ? id : '')).then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.farmerCultureRegionID, text: `${item.externalCode + " - "} ${item.farmer.name} ` }));

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const searchFarmers = async (event) => {
        if (event.ctrlKey || event.altKey || event.shiftKey) {
            return false;
        }
        else if (event.key == 'ArrowDown' || event.key == 'ArrowUp') {
            return false;
        }
        else {
            var text = '';
            if (event.key == 'Backspace') {
                text = event.nativeEvent.target.defaultValue.slice(0, -1)
            }
            else {
                var text = event.nativeEvent.target.defaultValue + event.key;
            }

            api.get(`Farmer/GetAllFarmerCultureRegionItems?SearchText=` + text + `&idAux3=` + selectedBranch).then((result) => {
                if (result && result.data) {                    
                    SetFarmers(result.data.response.map(item => ({ value: item.farmerCultureRegionID, text: `${item.externalCode + " - "} ${item.farmer.name }` })));
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadBranchs = async (callback) => {
        api.get(`Branch/GetAllBranchs`).then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.branchID, text: `${item.description} ` }));                

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }    

    const loadInstructors = async (callback) => {
        api.get(`AnalyseSample/GetAllInstructors`).then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.instructorID, text: `${item.externalCode + " - "} ${item.name} ` }));                

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }    

    const loadCultures = async (callback) => {
        await api.get(`Culture/getAll?page=1&skip=99999`).then((result) => {
            if (result && result.data) {    
                const options = result.data.response.data.map(item => ({ value: item.cultureID, text: `${item.name} ` }));                
                SetCultures(options);       
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }    

    const endAnalyseSample = async () => {
        setIsLoading(true)
        var send = true

        if (location.state.isCreate == false) {
            await listItens.forEach(function (sampleResult) {
                if (sampleResult.item1 && !sampleResult.item1.value && !sampleResult.item1.value != 0 && !sampleResult.item1.valueString) {
                    send = false;
                }
                else if (sampleResult.item2 && !sampleResult.item2.value && !sampleResult.item1.value != 0 && !sampleResult.item2.valueString) {
                    send = false;
                }
                else if (sampleResult.item3 && !sampleResult.item3.value && !sampleResult.item1.value != 0 && !sampleResult.item3.valueString) {
                    send = false;
                }
                else if (sampleResult.item4 && !sampleResult.item4.value && !sampleResult.item1.value != 0 && !sampleResult.item4.valueString) {
                    send = false;
                }
            });
        }
        if (sampleNumber == '' || sampleNumber == undefined) {
            error_message("informe a amostra")
        }
        else if (selectedBranch == '' || selectedBranch == undefined) {
            error_message("informe a filial")
        }
        // else if (selectedCulture == '' || selectedCulture == undefined) {
        //     error_message("informe a Cultura")
        // }
        
        else if (selectedFarmer == '' || selectedFarmer == undefined) {
            error_message("informe o produtor")
        }
        else if (selectedInstructor == '' || selectedInstructor == undefined) {
            error_message("informe o instrutor")
        }
        else if (!send) {
            error_message("informe os valores")
        }
        else {
            analyseSampleReturn.branchID = selectedBranch;
            analyseSampleReturn.instructorID = selectedInstructor;
            analyseSampleReturn.farmerCultureRegionID = selectedFarmer;
            analyseSampleReturn.analyseTypeID = location.state.analyseTypeID;
            analyseSampleReturn.sampleNumber = sampleNumber;
            analyseSampleReturn.friendlyCode = friendlyCode;
            analyseSampleReturn.numberOS = numberOS;
            analyseSampleReturn.yearOS = yearOS;

            if (dateSample != '' && dateSample != undefined) {
                analyseSampleReturn.dateSample = dateSample;
            }
            if (dateEmission != '' && dateEmission != undefined) {
                analyseSampleReturn.dateEmission = dateEmission;
            }
            if (observation != '' && observation != undefined) {
                analyseSampleReturn.observation = observation;
            }
            analyseSampleReturn.listItemAnalyseSampleResultDto = [null];
            analyseSampleReturn.listItemAnalyseSampleResultDto = list;
            analyseSampleReturn.listAnalyseSampleCultureDto = selectedCultures.map(item => ({cultureID: item.value}));
            try {
                const result = await api.post(`/AnalyseSample/Save`, analyseSampleReturn);
                if (result.data.response == true) {
                    success_message("Análise salva com sucesso!")
                    retunrAnalyse();
                }

            } catch (error) {
                setIsLoading(false)
                exceptionNotificationAPI(error);

            }
        }
        setIsLoading(false)
    }

    const retunrAnalyse = async () => {
        history.push({ pathname: "/analyseSample" })
    }

    const StyledTabs = styled(Tabs)({
        '& .MuiTabs-indicator': {
            backgroundColor: '#0C8662',
        },
        '&.Mui-selected': {
            color: '#0C8662',
        },
    });

    const StyledTab = styled((props) => <Tab {...props} />)(
        ({ theme }) => ({
            '&:hover': {
                color: '#0C8662',
                opacity: 1,
            },
            '&.Mui-selected': {
                color: '#0C8662 !important',
                fontWeight: '800 !important',
            },
        })
    );

    return (
        <>
            {
                <>
                    <Container>
                        <Row style={{ marginTop: '30px' }}>
                            <Col md={12} lg={12}>
                                <button className='btn' onClick={() => {
                                    retunrAnalyse();
                                }}>
                                    <img src={ArrowLeft} className='icon' />
                                </button>
                                <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Análise"}</label>
                                <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{analyseSampleReturn ? analyseSampleReturn.text : ''}</label>
                            </Col>
                        </Row>
                    </Container>
                    <Container className={styles.pagewhite}>
                        <Row>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="SampleNumber">
                                    <Form.Label className={styles.tableLabelsHeader}>Cód. Cliente</Form.Label>
                                    <Form.Control readOnly={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false} onChange={(t) => { setSampleNumber(t.target.value) }} type="number" defaultValue={analyseSampleReturn ? analyseSampleReturn.sampleNumber : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={2} lg={2} xs={12}>
                                <Form.Group className="mb-3" controlId="DateEmission">
                                    <Form.Label className={styles.tableLabelsHeader}>Data da Emissão</Form.Label>
                                    <Form.Control readOnly={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false} onChange={(t) => { setDateEmission(t.target.value) }} type="date" defaultValue={analyseSampleReturn ? analyseSampleReturn.formattedDateEmission : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={2} lg={2} xs={12}>
                                <Form.Group className="mb-3" controlId="DateSample">
                                    <Form.Label className={styles.tableLabelsHeader}>Data da Análise</Form.Label>
                                    <Form.Control readOnly={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false} onChange={(t) => { setDateSample(t.target.value) }} type="date" defaultValue={analyseSampleReturn ? analyseSampleReturn.formattedDateSample : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={2} lg={2} xs={12}>
                                <Form.Group className="mb-3" controlId="numberOS">
                                    <Form.Label className={styles.tableLabelsHeader}>Ordem</Form.Label>
                                    <Form.Control readOnly={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false} onChange={(t) => { setNumberOS(t.target.value) }} type="number" defaultValue={analyseSampleReturn ? analyseSampleReturn.numberOS : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={2} lg={2} xs={12}>
                                <Form.Group className="mb-3" controlId="yearOS">
                                    <Form.Label className={styles.tableLabelsHeader}>Ano Ordem</Form.Label>
                                    <Form.Control readOnly={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false} onChange={(t) => { setYearOS(t.target.value) }} type="number" defaultValue={analyseSampleReturn ? analyseSampleReturn.yearOS : ''} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="FriendlyCode">
                                    <Form.Label className={styles.tableLabelsHeader}>Amostra</Form.Label>
                                    <Form.Control readOnly={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false} onChange={(t) => { setFriendlyCode(t.target.value) }} type="text" maxLength={20} defaultValue={analyseSampleReturn ? analyseSampleReturn.friendlyCode : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Branch">
                                    <Form.Label className={styles.tableLabelsHeader}>Filial</Form.Label>

                                    <Select value={{ label: branch, value: selectedBranch }} isDisabled={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false}
                                        options={branchs.map((o) => {
                                            var data = {
                                                value: o.value,
                                                label: o.text
                                            }
                                            return data;
                                        })}                                                                    
                                        name='branchs' onChange={(e) => { setBranch(e.label); setSelectedBranch(e.value) }} className={styles.fieldResponsible} style={{ width: "100%" }}>
                                    </Select>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Culture">
                                    <Form.Label className={styles.tableLabelsHeader}>Cultura</Form.Label>
                                    <Select isDisabled={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false} isMulti defaultValue={selectedCultures}
                                            options={cultures.map((o) => {
                                                var data = {
                                                    value: o.value,
                                                    label: o.text
                                                }
                                                return data;
                                            })}     
                                        closeMenuOnSelect={false}                                                               
                                        name='cultures' className={styles.fieldResponsible} onChange={(e) => { setSelectedCultures(e) }}  style={{ width: "100%" }}>
                                    </Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} lg={8} xs={12}>
                                <Form.Group className="mb-3" controlId="Farmer">
                                    <Form.Label className={styles.tableLabelsHeader}>Produtor</Form.Label>
                                    <Select isClearable value={{ label: farmer, value: selectedFarmer }} isDisabled={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false}
                                        options={farmers.map((o) => {
                                            var data = {
                                                value: o.value,
                                                label: o.text
                                            }
                                            return data;
                                        })}
                                        onKeyDown={(e) => { searchFarmers(e) }}
                                        name='farmers' onChange={(e) => { setFarmer(e.label); setSelectedFarmer(e.value) }} className={styles.fieldResponsible} style={{ width: "100%" }}>
                                    </Select>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Instructor">
                                        <Form.Label className={styles.tableLabelsHeader}>Instrutor</Form.Label>

                                        <Select value={{ label: instructor, value: selectedInstructor }} isDisabled={ analyseSampleReturn && analyseSampleReturn.sampleNumber ? true : false}
                                            options={instructors.map((o) => {
                                                var data = {
                                                    value: o.value,
                                                    label: o.text
                                                }
                                                return data;
                                            })}                                                                    
                                            name='instructors' onChange={(e) => { setInstructor(e.label); setSelectedInstructor(e.value) }} className={styles.fieldResponsible} style={{ width: "100%" }}>
                                        </Select>
                                    </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={12} xs={12}>
                                <Form.Group className="mb-3" controlId="Observation">
                                    <Form.Label className={styles.tableLabelsHeader}>Observações</Form.Label>
                                    <Form.Control as="textarea" rows={5} onChange={(t) => { setObservation(t.target.value) }} defaultValue={analyseSampleReturn ? analyseSampleReturn.observation : ''} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} lg={12} xs={12}>
                                {
                                    analyseSampleReturn.analyseSampleID !== '00000000-0000-0000-0000-000000000000' ?
                                        <StyledTabs
                                            value={valueTab ? valueTab : 0}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons={false}
                                            aria-label="harvest-itens"
                                            style={{ marginTop: 20 }}
                                        >
                                            <StyledTab key={1} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={0} label={"Resultado"} />
                                            <StyledTab key={2} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={1} label={"Interpretações"} />
                                        </StyledTabs>
                                        :
                                        <StyledTab
                                            value={valueTab}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons={false}
                                            aria-label="harvest-itens"
                                            style={{ marginTop: 20 }}
                                        >
                                            <Tab key={1} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={0} label={"Resultado"} />
                                        </StyledTab>

                                }
                            </Col>
                        </Row>
                        <Row>
                            {
                                valueTab != null && valueTab == 0 ?
                                    <AnalyseSampleDetailItemResult list={list} />
                                    :
                                    <AnalyseSampleDetailItemInterpretation sampleData={analyseSampleReturn} list={list} listItens={listItens} analyseSampleID={analyseSampleReturn.analyseSampleID} place={1} />
                            }
                        </Row>


                    </Container>
                    <Container style={{ marginTop: "20px" }}>
                        <Row>
                            {
                                valueTab != null && valueTab == 0 ?
                                    <>
                                        <Col md={2} lg={2} xs={12}>
                                            <Button variant="outlined" className={styles.button} onClick={() => {
                                                retunrAnalyse();
                                            }}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col md={{ span: 2, offset: 8 }} lg={{ span: 2, offset: 8 }} xs={12}>
                                            {
                                                userRoles.includes("ROLESOILANALYSISREPORT") ?
                                                    <Button variant="contained" className={styles.button} onClick={() => {
                                                        retunrAnalyse()
                                                    }}>
                                                        Voltar
                                                    </Button>
                                                    :
                                                    <Button variant="contained" className={styles.button} style={{ background: "#0c8662", color: "#FFF" }} onClick={() => {
                                                        endAnalyseSample()
                                                    }}>
                                                        Salvar
                                                    </Button>
                                            }

                                        </Col>
                                    </>
                                    :
                                    <Col md={2} lg={2} xs={12}>
                                        <Button variant="outlined" className={styles.button} onClick={() => {
                                            retunrAnalyse();
                                        }}>
                                            Voltar
                                        </Button>
                                    </Col>
                            }
                        </Row>
                    </Container>
                </>
            }
        </>
    );
}

export default AnalyseSampleDetail;
