import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './route';
import RoutesList from './routesList';

const Routes = () => (
    <Switch>
        {RoutesList.map((route, i) => (
            <Route
                key={route.path + i}
                path={route.path}
                exact
                component={route.component}
                isPrivate={route.isPrivate}
                allowedRoles={route.allowedRoles} />
        ))}
    </Switch>
);

export default Routes;
