import Package from "../../../../package.json";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiBell, FiChevronDown } from 'react-icons/fi';
import { BiLogOut, BiUser, BiUpload } from 'react-icons/bi';
import { useAuth } from '../../../hooks/auth';
import ModalProfile from '../../../components/ModalProfile';
import { useHistory } from 'react-router-dom'

import Sidebar from '../../../components/Sidebar';
import logoFooter from '../../../assets/images/detalhe_top.jpg';
import { Container } from './styles';
import Dropdown from 'react-bootstrap/Dropdown';
import SessionTimeOut from '../../../components/SessionTimeout';

const Authorized = ({ children }) => {
    const [active, setActive] = useState(false);
    const { signOut, user, changePassword } = useAuth();
    const [userObject, setUserObject] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const history = useHistory()
    const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem('@SoilAnalysis:user')));

    useEffect(() => {
        setUserObject(typeof (user) == "string" ? JSON.parse(user) : user);
    }, []);

    useEffect(() => {
        const userStoraged = JSON.parse(localStorage.getItem('@SoilAnalysis:user'));
    }, [history]);

    return (
        <Container>
            {
                document.location.pathname.lastIndexOf("/soilanalysis/") == -1 &&
                <SessionTimeOut />
            }
            <header className="main-header">
                <ul>
                    <li className="li-menu">
                        <FiMenu onClick={() => { setActive(!active) }} size={23} />
                    </li>

                    {/* <li className="nav-item margin-itens ml-auto">
                        <input type="button"
                            style="width: 117px;background: #00b1eb;color: #fff;padding-left: 3px; border-radius: 8px; text-align: center;border: none;margin-top:-4px; font-size: 22px; cursor:pointer"
                            id="yearparam"
                            className="datepicker-here"
                            data-language='en'
                            data-min-view="years"
                            data-view="years"
                            data-date-format="yyyy" />
                    </li> */}

                    <li style={{ width: "auto", marginLeft: "auto", alignItems: "center", display: "flex" }}>
                    </li>

                    <li className="li-notification">
                        <FiBell size={20} />
                    </li>

                    <li className="li-profile">
                        <div className="col-md-12">
                            <Dropdown className={`col-md-12 user-name ${userObject && userObject.TenantID ? "has-Tenant" : ""}`}>
                                <Dropdown.Toggle>
                                    <span>Olá, {userObject ? userObject.firstName : ""}</span>
                                    <FiChevronDown size={20} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="label-line">
                                        <div className="hrdivider">
                                            <hr />
                                            <span className="profile-label">Perfil</span>
                                        </div>
                                    </div>
                                    <div className="label-name">
                                        <div className="profile-info">
                                            <p>Nome: <span>{userObject ? userObject.firstName : ""}</span></p>
                                            <p>E-mail: <span>{userObject ? userObject.email : ""}</span></p>
                                            {userObject && userObject.TenantID ? <p>Tenant: <span>{userObject && userObject.TenantID ? userObject.Tenant.name : "Global"}</span></p> : ""}
                                        </div>
                                    </div>
                                    <div className="label-line">
                                        <div className="hrdivider options">
                                            <hr />
                                            <span className="profile-label">Opções</span>
                                        </div>
                                    </div>
                                    <div className="label-name">
                                        <div className="profile-info">
                                            <Dropdown.Item onClick={() => setModalShow(true)} ><BiUser /> Mudar senha</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                history.push({ pathname: "/signin", state: { user: userRole } })
                                            }}
                                            ><BiUpload /> Alterar Função do usuario</Dropdown.Item>
                                            <Dropdown.Item onClick={() => signOut()} ><BiLogOut /> Sair</Dropdown.Item>
                                        </div>
                                    </div>
                                    <div className="label-line">
                                        <div className="hrdivider options">
                                            <hr />
                                            <span className="profile-label">Versão</span>
                                        </div>
                                    </div>
                                    <div className="label-name">
                                        <div className="profile-info">
                                            <p><small style={{ color: "#929292" }}>soilanalysis {Package.version}</small></p>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="user-infos">
                                {userObject && userObject.TenantID ? <span className="col-md-12">{`${userObject.Tenant.name}`}</span> : <></>}
                            </div>
                        </div>
                    </li>
                </ul>
            </header>

            <ModalProfile
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
            />

            <Sidebar active={active} setActive={setActive} />

            <div className="main-body">
                <main role="main">
                    {children}
                </main>
            </div>
        </Container>
    );
}

export default Authorized;
