import React, { useState, useEffect } from 'react';

import { Box, Grid, Button, styled } from '@material-ui/core';
import { useStyles } from './styles';
import { useLocation, useHistory } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableLoading from '../../components/TableLoading';
import tashIcon from '../../assets/icons/trash_red.png';
import { Container } from '@mui/material';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import above from '../../assets/icons/above.png';
import below from '../../assets/icons/below.png';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Col, Form, Row } from 'react-bootstrap';
import { success_message } from '../../components/Toast/index.jsx';

function RecomendationRegister() {
    const history = useHistory();
    const location = useLocation();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [recommendationList, setRecommendationList] = useState();
    const [selectedParameter, setSelectedParameter] = useState();
    const [selectedInterpretationID, setSelectedInterpretationID] = useState('');
    const [interpretationList, setInterpretationList] = useState()
    const [actualParamenterRecommendationList, setActualParamenterRecommendationList] = useState([])
    const [observationList, setObservationList] = useState([]);
    const [counterNewRecommendation, setCounterNewRecommendation] = useState(0);
    const [counterNewObservation, setCounterNewObservation] = useState(0);
    const [tabValue, setTabValue] = useState(1);
    const [listParameterTemplateItemHeader, setListParameterTemplateItemHeader] = useState();
    const savedLocationStateId = JSON.parse(localStorage.getItem('locationState'));


    useEffect(() => {
        let cancel = false;
        setIsLoading(false)

        if (savedLocationStateId === null && location.state && location.state.id) {
            localStorage.setItem('locationState', JSON.stringify(location.state.id));
        }
        else if (location.state && location.state.id) {
            if (savedLocationStateId !== location.state.id) {
                localStorage.setItem('locationState', JSON.stringify(location.state.id));
            }
        }

        if (savedLocationStateId) {

            setIsLoading(true)

            async function fetchData() {
                api.get(`Interpretation/Recommendation/LoadObservationList?parameterTemplateID=${location.state.id ? location.state.id : savedLocationStateId}`).then((result) => {
                    if (result && result.data) {
                        const observationListFormatted = result.data.response.map((item) => {
                            return { ...item, parameterTemplateDescriptionIDControlled: item.parameterTemplateDescriptionID }; // tratamento para manipulação no front
                        });

                        setObservationList(observationListFormatted);
                    }
                }).finally(() => {
                    api.get(`Interpretation/GetById?id=${(location.state.id ? location.state.id : savedLocationStateId)}`).then((result) => {
                        if (result.data) {
                            setListParameterTemplateItemHeader(result.data.response.listParameterTemplateItemHeaderDto);
                        }
                    }).finally(() => {
                        async function fetchListInterpretation() {
                            api.get(`Interpretation/GetAll?page=${1}&&skip=${99999}`).then((result) => {
                                if (result && result.data.response) {
                                    const interpretationListFormatted = result.data.response.data.map(e => { return { id: e.parameterTemplateID, name: e.description } })
                                    setInterpretationList(interpretationListFormatted);
                                }
                            }).finally(() => {
                                async function fetchData() {
                                    api.get('Recommendation/getAllItems').then((result) => {
                                        if (result.data)
                                            setRecommendationList(result.data.response);
                                    }).finally(() => {
                                        setIsLoading(false);
                                    }).catch((error) => {
                                        exceptionNotificationAPI(error);
                                    });
                                }
                                fetchData();
                            }).catch((error) => {
                                exceptionNotificationAPI(error);
                            });
                        }
                        fetchListInterpretation();
                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                    });
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
            fetchData();
        }

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    // carrega todas as interpretações (para o seletor)
    useEffect(() => {

    }, []);

    useEffect(() => {

    }, []);



    const handleSaveForm = () => {
        if (location.state.id ? location.state.id : savedLocationStateId) {
            cleanValues()
            setSelectedParameter(null)
            async function saveData() {
                setIsLoading(true);
                var checkboxElement = document.getElementById('CheckboxIsReportRecommendation');
                var isReportRecommendation;
                if (checkboxElement) {
                    if (selectedParameter) {
                        selectedParameter.isReportRecommendation = document.getElementById('CheckboxIsReportRecommendation').checked
                        isReportRecommendation = document.getElementById('CheckboxIsReportRecommendation').checked
                    }
                } else {
                    if (selectedParameter) {
                        selectedParameter.isReportRecommendation = false;
                        isReportRecommendation = false
                    }
                }

                api.post('Interpretation/SaveItemRecommendation', {
                    listParameterTemplateDescription: observationList ?? [],
                    listParameterTemplateItemRecommendationDtos: actualParamenterRecommendationList ?? [],
                    isReportRecommendation: isReportRecommendation
                })
                    .then((result) => {
                        if (result.status === 200) {
                            success_message("Recomendação salva com sucesso!")

                            api.get(`Interpretation/GetById?id=${(location.state.id ? location.state.id : savedLocationStateId)}`).then((resultInterpretation) => {
                                if (resultInterpretation.data) {
                                    setListParameterTemplateItemHeader(resultInterpretation.data.response.listParameterTemplateItemHeaderDto);
                                    setIsLoading(false);
                                }
                            }).catch((error) => {
                                exceptionNotificationAPI(error);
                            });
                        }
                    })
                    .catch((error) => {
                        exceptionNotificationAPI(error);
                        setIsLoading(false);
                    });
            }

            if (!actualParamenterRecommendationList.some(row => !row.recommendationID))
                saveData();
            else
                alert('Necessário informar o INSUMO A SER UTILIZADO em todas as recomendações!')
        }
    }

    function cleanValues() {
        setActualParamenterRecommendationList([]);
    }

    // carrega as recomendações para o parâmetro escolhido
    useEffect(() => {
        if (selectedParameter) {
            setIsLoading(true);
            async function fetchData() {
                api.get(`Interpretation/Recommendation/getParameterTemplateItemRecommendation?parameterTemplateID=${selectedParameter.parameterTemplateID}&parameterTemplateItemHeaderID=${selectedParameter.parameterTemplateItemHeaderID}${selectedParameter.dependencyAnalyseTypeParameterID ? `&dependencyAnalyseTypeParameterID=${selectedParameter.dependencyAnalyseTypeParameterID}` : ''}`).then((result) => {
                    if (result.data) {
                        let parameterTemplateItemRecommendationFormatted = result.data.response.map((item) => {
                            return { ...item, parameterTemplateItemRecommendationIDControlled: item.parameterTemplateItemRecommendationID }; // tratamento para manipulação no front
                        });

                        parameterTemplateItemRecommendationFormatted.push.apply(parameterTemplateItemRecommendationFormatted, actualParamenterRecommendationList);
                        setActualParamenterRecommendationList(parameterTemplateItemRecommendationFormatted);
                    }
                })
                    .finally(() => setIsLoading(false))
                    .catch((error) => {
                        exceptionNotificationAPI(error);
                    });
            }
            fetchData();
        } else {
            setTabValue(1);
        }

    }, [selectedParameter]);


    const handleConfigurationRecomendationDetails = (recommendationDto) => {
        return (
            <div key={recommendationDto.parameterTemplateItemRecommendationIDControlled}>
                <div style={{ width: "100%", marginLeft: '15px', display: 'flex', padding: '16px', borderRadius: '5px' }}>
                    <div style={{ width: '25%' }}>
                        <label style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold' }}>Título da Recomendação</label>
                        <div style={{ paddingTop: '10px', width: '95%' }}>
                            <input placeholder='Digite...' name='Title' onChange={(event) => handleChangeRecommendationDetail(event, recommendationDto.parameterTemplateItemRecommendationIDControlled)} defaultValue={recommendationDto?.title} className={styles.inputRecomendation} />
                        </div>
                    </div>
                    <div style={{ width: '25%' }}>
                        <label style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold' }}>Insumo a ser utilizado</label>
                        <div style={{ paddingTop: '10px', width: '95%' }}>
                            <select className={styles.selectorStyled} name='recommendationID' onChange={(event) => handleChangeRecommendationDetail(event, recommendationDto.parameterTemplateItemRecommendationIDControlled)} defaultValue={recommendationDto?.recommendationID} placeholder="Insumo a ser utilizado" >
                                <option key='' value=''>Selecione</option>
                                {recommendationList && recommendationList.length > 0
                                    ? recommendationList.map((option) => <option key={option.recommendationID} value={option.recommendationID}>{option.description}</option>)
                                    : <></>}
                            </select>
                        </div>
                    </div>
                    <div style={{ width: '25%' }}>
                        <label style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold' }}>Quantidade</label>
                        <div style={{ paddingTop: '10px', width: '95%' }}>
                            <input type='number' name='Amount' onChange={(event) => handleChangeRecommendationDetail(event, recommendationDto.parameterTemplateItemRecommendationIDControlled)} defaultValue={recommendationDto?.amount} className={styles.inputRecomendation} />
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", marginLeft: '15px', display: 'flex', padding: '16px', borderRadius: '5px' }}>
                    <div style={{ width: '50%' }}>
                        <label style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold' }}>Instruções de Dosagem</label>
                        <div style={{ paddingTop: '10px', width: '97.5%' }}>
                            <textarea placeholder='Digite...' name='Instruction' onChange={(event) => handleChangeRecommendationDetail(event, recommendationDto.parameterTemplateItemRecommendationIDControlled)} defaultValue={recommendationDto?.instruction ?? ''} className={styles.textAreaRecomendation}>
                            </textarea>
                        </div>
                    </div>
                    <div style={{ width: '50%' }}>
                        <label style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold' }}>Observações</label>
                        <div style={{ paddingTop: '10px', width: '97.5%' }}>
                            <textarea placeholder='Digite...' name='Observation' onChange={(event) => handleChangeRecommendationDetail(event, recommendationDto.parameterTemplateItemRecommendationIDControlled)} defaultValue={recommendationDto?.observation ?? ''} className={styles.textAreaRecomendation}>
                            </textarea>
                        </div>
                    </div>
                </div>
                <button
                    style={{ background: 'transparent', border: 'none', color: '#FF3541', fontWeight: 'bold', marginLeft: '15px', marginBottom: 20 }}
                    onClick={() => handleRemoveRecommendationDetail(recommendationDto?.parameterTemplateItemID, recommendationDto?.parameterTemplateItemRecommendationIDControlled)}
                >
                    <img src={tashIcon} style={{ width: '12%', color: 'red', paddingRight: '2px' }} className='icon' />
                    Excluir esta recomendação
                </button>
            </div>
        );
    }

    const handleChangeRecommendationDetail = (event, id) => {
        const recommendationListFormatted = actualParamenterRecommendationList.map((item) => {
            if (item.parameterTemplateItemRecommendationIDControlled === id) {
                return {
                    ...item,
                    [event.target.name]: event.target.value
                };
            }
            return item;
        });

        setActualParamenterRecommendationList(recommendationListFormatted);
    }

    const handleAddRecommendationDetail = (parameterTemplateItemID) => {
        let recommendationListFormatted = [...actualParamenterRecommendationList];

        recommendationListFormatted.push({
            parameterTemplateItemID: parameterTemplateItemID,
            parameterTemplateID: location.state && location.state.id ? location.state.id : null,
            parameterTemplateItemRecommendationIDControlled: counterNewRecommendation.toString(),
            parameterTemplateItemRecommendationID: '',
            amount: 0,
            instruction: '',
            observation: '',
            recommendationID: null,
            title: '',
            isNew: true,
            isActive: true,
            isDeleted: false,
        });

        setCounterNewRecommendation(counterNewRecommendation + 1); // para controle das rows novas (apenas para tratamento no front)
        setActualParamenterRecommendationList(recommendationListFormatted);

    }

    const handleRemoveRecommendationDetail = (parameterTemplateItemID, parameterTemplateItemRecommendationIDControlled) => {

        const recommendationListFormatted = actualParamenterRecommendationList.map((item) => {
            if (item.parameterTemplateItemID === parameterTemplateItemID && item.parameterTemplateItemRecommendationIDControlled === parameterTemplateItemRecommendationIDControlled) {
                return { ...item, isDeleted: true, isActive: false };
            }
            return item;
        });

        setActualParamenterRecommendationList(recommendationListFormatted);
    }

    const handleRecomendationLevelByColor = (parameterTemplateItem) => {
        return (
            <>
                <div key={`${parameterTemplateItem.parameterTemplateItemID}`} style={{ width: "100%", display: 'flex', padding: '16px', marginLeft: '15px', borderRadius: '5px' }}>
                    <label style={{ color: "#3B454F" }} className={styles.labelEdit}>{'Se'} <b>{parameterTemplateItem.parameterTemplateItemHeader.description}</b></label>
                    <input value={parameterTemplateItem.resultDescription} className={styles.inputLevel} disabled />
                    <div className={styles.colorBox} style={{ backgroundColor: `${parameterTemplateItem.resultColor}` }}> </div>
                    <button
                        style={{ background: 'transparent', border: 'none' }}
                        onClick={() => handleAddRecommendationDetail(parameterTemplateItem.parameterTemplateItemID)}
                    >
                        <a className={styles.addRecomendation}>+ Adicionar recomendação </a>
                    </button>
                </div>
                {actualParamenterRecommendationList && actualParamenterRecommendationList.find(rowRecommend => rowRecommend.parameterTemplateItemID === parameterTemplateItem.parameterTemplateItemID && !rowRecommend.isDeleted)
                    ? actualParamenterRecommendationList.map(rowRecommend => {
                        return rowRecommend.parameterTemplateItemID === parameterTemplateItem.parameterTemplateItemID && !rowRecommend.isDeleted ? handleConfigurationRecomendationDetails(rowRecommend) : <></>
                    })
                    : <></>
                }
            </>
        )
    };

    function funcCollapse(parameterTemplateItem) {
        var display = document.getElementById("BoxSub" + parameterTemplateItem.parameterTemplateItemID).style.display;
        if (display == 'none') {
            document.getElementById("BoxSub" + parameterTemplateItem.parameterTemplateItemID).style.display = 'block';
            document.getElementById("img" + parameterTemplateItem.parameterTemplateItemID).src = below;
        }
        else {
            document.getElementById("BoxSub" + parameterTemplateItem.parameterTemplateItemID).style.display = 'none';
            document.getElementById("img" + parameterTemplateItem.parameterTemplateItemID).src = above;
        }
    }

    const handleRecomendationLevelByColorDependency = (parameterTemplateItem, descriptionParameter) => {

        return (
            <>
                <Box key={`${parameterTemplateItem.parameterTemplateItemID}`} style={{ width: "100%", padding: '16px', marginLeft: '15px', borderRadius: '5px' }}>
                    <label style={{ color: "#3B454F" }} className={styles.labelEdit}>{'Se'} <b>{descriptionParameter}</b></label>
                    <input style={{ width: "100px" }} value={parameterTemplateItem.operatorMinValue} className={styles.inputLevel} disabled />
                    <input style={{ width: "100px" }} value={parameterTemplateItem.minValue} className={styles.inputLevel} disabled />
                    <label style={{ color: "#3B454F", marginLeft: "1%" }} className={styles.labelEdit}>{' e '}</label>
                    <input style={{ width: "100px" }} value={parameterTemplateItem.maxValue} className={styles.inputLevel} disabled />
                    <input style={{ width: "100px" }} value={parameterTemplateItem.operatorMaxValue} className={styles.inputLevel} disabled />
                    <button className='btn' onClick={() => funcCollapse(parameterTemplateItem)}>
                        <img id={"img" + parameterTemplateItem.parameterTemplateItemID} src={below} className='icon' />
                    </button>
                    <Box id={"BoxSub" + parameterTemplateItem.parameterTemplateItemID} style={{ display: 'none' }}>
                        {
                            parameterTemplateItem.listParameterTemplateItemDto ? parameterTemplateItem.listParameterTemplateItemDto.filter(function (element) { return element.isDeleted == false; }).map(p => {
                                return (
                                    <Box>
                                        <div key={`${p.parameterTemplateItemID}`} style={{ width: "100%", display: 'flex', padding: '16px', marginLeft: '15px', borderRadius: '5px' }}>
                                            <label style={{ color: "#3B454F" }} className={styles.labelEdit}>{'Se'} <b>{p.parameterTemplateItemHeader.analyseTypeParameter.parameter.description}</b></label>
                                            <input value={p.resultDescription} className={styles.inputLevel} disabled />
                                            <div className={styles.colorBox} style={{ backgroundColor: `${p.resultColor}` }}> </div>
                                            <button
                                                style={{ background: 'transparent', border: 'none' }}
                                                onClick={() => handleAddRecommendationDetail(p.parameterTemplateItemID)}
                                            >
                                                <a className={styles.addRecomendation}>+ Adicionar recomendação </a>
                                            </button>
                                        </div>
                                        {actualParamenterRecommendationList && actualParamenterRecommendationList.find(rowRecommend => rowRecommend.parameterTemplateItemID === p.parameterTemplateItemID && !rowRecommend.isDeleted)
                                            ? actualParamenterRecommendationList.map(rowRecommend => {
                                                return rowRecommend.parameterTemplateItemID === p.parameterTemplateItemID && !rowRecommend.isDeleted ? handleConfigurationRecomendationDetails(rowRecommend) : <></>
                                            })
                                            : <></>
                                        }
                                    </Box>

                                )
                            })
                                :
                                <></>
                        }
                    </Box>
                </Box>
            </>
        )
    };

    const handleAddNewInterpretationObservation = () => {
        let observationListFormatted = [...observationList];

        observationListFormatted.push({
            parameterTemplateID: location.state && location.state.id ? location.state.id : null,
            parameterTemplateDescriptionID: '',
            parameterTemplateDescriptionIDControlled: counterNewObservation.toString(),
            description: '',
            isNew: true,
            isActive: true,
            isDeleted: false,
        });

        setCounterNewObservation(counterNewObservation + 1); // para controle das rows novas (apenas para tratamento no front)
        setObservationList(observationListFormatted);
    }

    const handleChangeObservation = (row, isDeleted, isActive) => {
        const observationListFormatted = observationList.map((item) => {
            if (item.parameterTemplateID === row.parameterTemplateID && item.parameterTemplateDescriptionIDControlled === row.parameterTemplateDescriptionIDControlled) {
                return { ...item, description: isDeleted ? item.description : row.description, isDeleted: isDeleted, isActive: isActive };
            }
            return item;
        });

        setObservationList(observationListFormatted);
    }

    const handleLoadInterpretationObservationList = () => {

        return observationList.map(row => !row.isDeleted &&
            <Box key={row?.parameterTemplateDescriptionIDControlled}>
                <div style={{ width: "100%", marginLeft: '15px', display: 'flex', padding: '16px', borderRadius: '5px' }}>
                    <div style={{ width: '90%' }}>
                        <label style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold' }}>Observação</label>
                        <div style={{ paddingTop: '10px', width: '97.5%' }}>
                            <textarea placeholder='Digite...' onChange={(e) => handleChangeObservation({ ...row, description: e.target.value ?? row.description }, false, true)} defaultValue={row.description} className={styles.textAreaRecomendation}>
                            </textarea>
                        </div>
                    </div>
                </div>
                <button
                    style={{ background: 'transparent', border: 'none', color: '#FF3541', fontWeight: 'bold', marginLeft: '15px', marginBottom: 20 }}
                    onClick={() => handleChangeObservation(row, true, false)}
                >
                    <img src={tashIcon} style={{ width: '12%', color: 'red', paddingRight: '2px' }} className='icon' />
                    Excluir esta observação
                </button>
            </Box>
        )
    }

    const handleChangeTab = () => setTabValue(tabValue === 1 ? 2 : 1);



    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const StyledTabs = styled(Tabs)({
        '& .MuiTabs-indicator': {
            backgroundColor: '#0C8662',
        },
        '&.Mui-selected': {
            color: '#0C8662',
        },
    });

    const StyledTab = styled((props) => <Tab {...props} />)(
        ({ theme }) => ({
            '&:hover': {
                color: '#0C8662',
                opacity: 1,
            },
            '&.Mui-selected': {
                color: '#0C8662 !important',
                fontWeight: '800 !important',
            },
        })
    );

    const getColorBackgroundButton = async (parameterTemplateItemHeader) => {
        await listParameterTemplateItemHeader.forEach(function (_parameterTemplateItemHeader) {
            if (_parameterTemplateItemHeader.parameterTemplateItemHeaderID == parameterTemplateItemHeader.parameterTemplateItemHeaderID) {
                _parameterTemplateItemHeader.colorBackgroundButton = '#AAD6C2';
            }
            else {
                _parameterTemplateItemHeader.colorBackgroundButton = null;
            }
        });
    }


    return (
        <>
            <Container>
                <Row style={{ marginTop: '30px' }}>
                    <Col md={12} lg={12} xs={12}>
                        <button className='btn' onClick={() => {
                            history.goBack();
                        }}>
                            <img src={ArrowLeft} className='icon' />
                        </button>
                        <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Cadastro de Recomendações"}</label>
                    </Col>
                </Row>
            </Container>
            <Container className={styles.pagewhite}>
                <Row style={{ marginLeft: "4%" }}>
                    <StyledTabs
                        value={tabValue ? tabValue : 0}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="harvest-itens"
                        style={{ marginTop: 20 }}
                    >
                        <StyledTab key={1} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={1} label='Recomendações do parâmetro' />
                        <StyledTab key={2} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={2} label='Observações da interpretação' />
                    </StyledTabs>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    {
                        tabValue && tabValue === 1 ?
                            <>
                                {
                                    listParameterTemplateItemHeader ?
                                        listParameterTemplateItemHeader.map(e => {
                                            return (
                                                e.colorBackgroundButton ?
                                                    <Col lg={2} md={2} xs={4} className={styles.boxParameter} style={{ background: e.colorBackgroundButton ? e.colorBackgroundButton : "transparent" }}>
                                                        <Button style={{ width: "100%", height: "100%", color: "#737D86", background: e.colorBackgroundButton ? e.colorBackgroundButton : "transparent" }} onClick={() => {
                                                            cleanValues()
                                                            setSelectedParameter(e)
                                                            getColorBackgroundButton(e)
                                                        }}>
                                                            {e.description}
                                                        </Button>
                                                    </Col>
                                                    :
                                                    <Col lg={2} md={2} xs={4} className={styles.boxParameter} >
                                                        <Button style={{ width: "100%", height: "100%", color: "#737D86", background: e.colorBackgroundButton ? e.colorBackgroundButton : "transparent" }} onClick={() => {
                                                            cleanValues()
                                                            setSelectedParameter(e)
                                                            getColorBackgroundButton(e)
                                                        }}>
                                                            {e.description}
                                                        </Button>
                                                    </Col>
                                            )
                                        })
                                        :
                                        <></>
                                }
                                {
                                    <Row style={{ marginTop: "20px" }}>
                                        <label style={{ color: "#3B454F" }} className={styles.labelSelected}>{selectedParameter ? selectedParameter.description : ''}</label>
                                        {
                                            selectedParameter ?
                                                selectedParameter.listParameterTemplateItemDto.map(e => {
                                                    return (
                                                        selectedParameter.dependencyAnalyseTypeParameterID ?
                                                            handleRecomendationLevelByColorDependency(e, selectedParameter.dependencyAnalyseTypeParameter.parameter.description)
                                                            :
                                                            handleRecomendationLevelByColor(e)
                                                    )
                                                })
                                                :
                                                <></>
                                        }
                                        {
                                            selectedParameter ?
                                                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%' }}>
                                                    <span style={{ color: "#3B454F", marginLeft: '5px' }}>Visível no relatório</span>
                                                    <div style={{ marginLeft: '5px', marginTop: '-15px' }}>
                                                        <input id={"CheckboxIsReportRecommendation"} style={{ color: "#3B454F" }} type="checkbox" defaultChecked={selectedParameter.isReportRecommendation}></input>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                        }
                                    </Row>
                                }
                            </>
                            :
                            <>
                                <Box>
                                    <button
                                        style={{ background: 'transparent', border: 'none', paddingBottom: '10px' }}
                                        onClick={() => handleAddNewInterpretationObservation()}
                                    >
                                        <a className={styles.addRecomendation}>+ Adicionar observação </a>
                                    </button>
                                </Box>
                                <Box>
                                    {observationList ? handleLoadInterpretationObservationList : <></>}
                                </Box>
                            </>
                    }


                </Row>
                <Grid style={{ float: 'right', marginTop: '25px', paddingRight: '5%' }}>
                    <Button className={styles.button}
                        onClick={handleSaveForm}>
                        <label style={{ height: "auto", cursor: "pointer", color: 'white' }}>Salvar Alterações</label>
                    </Button>
                </Grid>
            </Container>
        </>
    );
}

export default RecomendationRegister;