import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';

import data_results_logo from '../../assets/images/data_results_logo.png';
import image_men from '../../assets/images/image_men.png';

import ChartByMonth from './Components/ChartByMonth';

const Dashboard = () => {
    const { user } = useAuth();
    const [userObject, setUserObject] = useState(null);
    const styles = useStyles();

    useEffect(() => {
        setUserObject(typeof (user) == "string" ? JSON.parse(user) : user);
    }, []);

    const handleDataBox = () =>
        <Box style={{ borderRadius: '16px', padding: '15px', backgroundColor: 'white', display: "flex", width: "250px", margin: '8px', height: '150px' }}>
            ssssssss
        </Box>

    return (
        <>
            <Box className={styles.block1} style={{ margin: '0 auto', maxWidth: "1000px", paddingLeft: 0, marginTop: '3%' }} >
                <Box style={{ display: "flex", width: "500px", textAlign: 'right' }}>
                    <img src={data_results_logo} style={{ height: '40px' }} />
                </Box>
                <Box style={{ display: "flex", width: "500px" }}>
                    <label style={{ fontFamily: "Montserrat", fontWeight: 700, fontSize: 24, width: '100%', textAlign: 'right', color: "#5A646E" }} >Bem vindo, {userObject ? userObject.firstName : ""} </label>
                </Box>
            </Box>
            <Box className={styles.block1} style={{ margin: '0 auto', maxWidth: "1000px", padding: '2px', marginTop: '1%', display: 'none' }}>
                {handleDataBox()}
                {handleDataBox()}
                {handleDataBox()}
                {handleDataBox()}
            </Box>
            <Box className={styles.block1} style={{ margin: '0 auto', maxWidth: "1000px", padding: '2px' }}>
                <Box style={{ borderRadius: '16px', padding: '15px', display: "flex", width: "370px", margin: '100px auto' }}>
                    <img src={image_men} style={{ height: '300px' }} />
                </Box>
                {/* <ChartByMonth /> */}
            </Box>
        </>
    );
}

export default Dashboard;
