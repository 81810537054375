import React from 'react';
import TableCrud from '../../components/TableCrud';

const FarmerFormIndex = () => {
    const columns = [
        {
            label: "Nome",
            name: "name"
        },
        {
            label: "Localidade",
            name: "place"
        },
        {
            label: "Documento",
            name: "documentNumber"
        },
        /*{
            label: "Código externo",
            name: "externalCode"
        },*/
        {
            label: "Identificação",
            name: "identificationNumber"
        },
        {
            label: "Ativo",
            name: "isActive"
        }
    ]

    return (
        <TableCrud title={"Produtor"} table={"farmer"} columns={columns} canDelete={false}/>
    );
}

export default FarmerFormIndex;
