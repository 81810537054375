import React from 'react';
import TableCrud from '../../components/TableCrud';

const TenantIndex = () => {
    const columns = [
        {
            label: "Nome",
            name: "name"
        },
        {
            label: "Ativo",
            name: "isActive"
        },
    ]

    return (
        <TableCrud title={"Empresa"} table={"tenant"} columns={columns}/>
    );
}

export default TenantIndex;
