import React, { useState, forwardRef } from "react";
import { Box } from '@material-ui/core';
import { ImEye  } from "react-icons/im";

import { Container } from './styles';
export default function MenuTable({ id, viewFunction }) {

  return (
    <Box style={{ display: "flex", border:1 }}>
      <Container type="button" onClick={() => viewFunction(id)} title={'Visualizar'}><ImEye size={20}/></Container>
    </Box>
  );
}

